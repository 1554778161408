import {
  STORE_CREDENTIALS,
  STORE_CREDENTIAL,
  SET_IS_DELETING,
  REMOVE_CREDENTIAL,
} from './constants';
import { parseCredential } from '../../helpers/credentials';

const initialState = {
  items: [],
  isDeleting: false,
};

function storeCredentialsHandler(state, action) {
  return {
    ...state,
    items: action.data.map((item) => parseCredential(item)),
  };
}

function storeCredentialHandler(state, action) {
  const credential = state.items.find((item) => item.id === action.data.id);

  return {
    ...state,
    items: !credential ? [...state.items, action.data] : state.items,
  };
}

function removeCredentialHandler(state, action) {
  return {
    ...state,
    items: state.items.filter((credential) => credential.id !== action.credentialId),
  };
}

function setIsDeletingHandler(state, action) {
  return { ...state, isDeleting: action.status };
}

const ACTION_HANDLERS = {
  [STORE_CREDENTIALS]: storeCredentialsHandler,
  [STORE_CREDENTIAL]: storeCredentialHandler,
  [SET_IS_DELETING]: setIsDeletingHandler,
  [REMOVE_CREDENTIAL]: removeCredentialHandler,
};

export default function credentialsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
