import { REQUEST_STARTED, REQUEST_SUCCESS, REQUEST_FAILED } from './constants';
import { getSubUsers as getSubUsersRemote } from '../../services/sub-users';

export function requestStarted() {
  return {
    type: REQUEST_STARTED,
  };
}

export function requestSuccess(json) {
  return {
    type: REQUEST_SUCCESS,
    data: json.subusers.map((item) => ({
      value: item.id,
      label: item.full_name,
      status: item.status,
    })),
  };
}

export function requestFailed(json) {
  return {
    type: REQUEST_FAILED,
    errors: json.errors,
  };
}

export function getSubUsers() {
  return (dispatch) => {
    dispatch(requestStarted());

    return getSubUsersRemote()
      .then((json) => dispatch(requestSuccess(json)))
      .catch((err) => dispatch(requestFailed(err)));
  };
}
