import { createSlice } from '@reduxjs/toolkit';
import { fetchOrderItem, updateOrderItem } from './actions';

const initialState = {
  data: null,
  fetching: false,
};

export const orderItemSlice = createSlice({
  name: 'order-item',
  initialState,
  reducers: {
    resetOrderItemState: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchOrderItem.pending]: (state) => ({ ...state, fetching: true }),
    [fetchOrderItem.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload,
      fetching: false,
    }),
    [fetchOrderItem.rejected]: (state) => ({ ...state, fetching: false }),
    [updateOrderItem.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
});

export const { resetOrderItemState } = orderItemSlice.actions;

export default orderItemSlice.reducer;
