import { createAsyncThunk } from '@reduxjs/toolkit';
import { getViews, updateViews, deleteView } from 'services/views';
import { error } from 'state/notifications/actions';
import { forEachError } from '../../../helpers/errorHelper';

export const fetchSpiroViews = createAsyncThunk('views-get', async (params = {}) => {
  try {
    const res = await getViews(params);

    return Promise.resolve({ ...res });
  } catch (err) {
    return Promise.reject(err);
  }
});

export const updateSpiroViews = createAsyncThunk(
  'views-update',
  async (payload, { dispatch, getState }) => {
    try {
      const userID = getState().user.id;
      const res = await updateViews(userID, payload);
      return Promise.resolve(res);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const deleteSpiroView = createAsyncThunk(
  'views-delete',
  async (resource, { dispatch, getState }) => {
    try {
      const userID = getState().user.id;
      const res = await deleteView(userID, resource);
      return Promise.resolve(res);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
