import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';

const colors = {
  lightGray: '#999999',
  darkGray: '#666666',
  white: '#FFFFFF',
};

const StyledSearchIcon = styled(SearchIcon)({
  color: colors.lightGray,
});

const StyledClearIcon = styled(ClearIcon)({
  color: colors.lightGray,
});

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})(({ fullWidth }) => ({
  width: fullWidth ? '100%' : 'auto',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
}));

const StyledInputAdornment = styled(InputAdornment)({
  margin: 0,
});

function SearchInput({
  onKeyPress,
  onChange,
  value = '',
  onClear,
  fullWidth = false,
  placeholder = '',
  ...props
}) {
  const { t } = useTranslation();

  return (
    <StyledTextField
      {...props}
      value={value}
      placeholder={placeholder || t('localization.search.title')}
      fullWidth={fullWidth}
      InputProps={{
        sx: {
          background: colors.white,
          display: 'flex',
          alignItems: 'center',
          padding: '0px 11px',
          height: 36,
          border: '1px solid rgba(128, 128, 128, 0.24)',
          '& input::-webkit-search-cancel-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button, & input::-webkit-clear-button':
            {
              display: 'none',
            },
          '&.Mui-focused': {
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
          },
          '.MuiInputBase-input': {
            color: colors.lightGray,
            fontSize: 16,
            fontFamily: 'Roboto',
            padding: '6px 0 7px',
            '&::placeholder': {
              opacity: 1,
            },
          },
        },
        onChange,
        onKeyPress,
        startAdornment: (
          <StyledInputAdornment position="start">
            <StyledSearchIcon data-cy="details-tab-search-icon" />
          </StyledInputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton size="small" onClick={onClear}>
              <StyledClearIcon data-cy="details-tab-clear-search-icon" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

SearchInput.propTypes = {
  onKeyPress: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default SearchInput;
