import PropTypes from 'prop-types';
import TypingEffectSequence from 'components/TypingEffectSequence';
import ChatUserInfo from '../ChatUserInfo';
import RegularTextMessage from '../RegularTextMessage';
import LinkMessage from '../LinkMessage';
import styles from './MessageItem.module.scss';

const MessageTypeComponent = {
  regular: RegularTextMessage,
  bold: RegularTextMessage,
  link: LinkMessage,
  external_link: LinkMessage,
};

function MessageRenderer({ value }) {
  const Component = MessageTypeComponent[value.type || 'regular'] || null;
  return Component ? <Component value={value} /> : null;
}

export default function MessageItem({ message }) {
  const sortedMessageValues = message.value.sort((v1, v2) => v1.order - v2.order);

  return (
    <div className={styles.container}>
      <ChatUserInfo message={message} />
      <div className={styles['message-container']}>
        {message.type === 'response' ? (
          <TypingEffectSequence speed={10}>
            {sortedMessageValues.map((value, index) => (
              <MessageRenderer key={index} value={value} />
            ))}
          </TypingEffectSequence>
        ) : (
          sortedMessageValues.map((value, index) => <MessageRenderer key={index} value={value} />)
        )}
      </div>
    </div>
  );
}

MessageRenderer.propTypes = {
  value: PropTypes.object.isRequired,
};

MessageItem.propTypes = {
  message: PropTypes.object.isRequired,
};
