import { combineReducers } from '@reduxjs/toolkit';
import { DESTROY_USER_SESSION } from './app/state/session/constants';

import appReducer from './app/state/app/reducer';
import sessionReducer from './app/state/session/reducers';
import tokensReducer from './app/state/tokens/reducers';
import credentialsReducer from './app/state/credentials/reducers';
import calendarReducer from './app/state/calendar/reducers';
import twilioProfileReducer from './app/state/twilio-profile/reducers';
import organizationReducer from './app/state/organization/reducers';
import subscriptionReducer from './app/state/subscription/reducers';
import userReducer from './app/state/user/reducers';
import users from './app/state/users/reducers';
import subUsersReducer from './app/state/sub-users/reducers';
import currenciesReducer from './app/state/currencies/reducers';
import customFieldsReducer from './app/state/custom-fields/reducers';
import displayFieldsReducer from './app/state/display-fields/reducers';
import notificationsReducer from './app/state/notifications/reducers';
import pipelinesReducer from './app/state/pipelines/reducers';
import salesStagesReducer from './app/state/sales-stages/reducers';

import routesReducers from './app/routes/reducers';
import opportunityContactRolesReducer from './app/state/opportunity-contact-roles/reducer';
import nestedInteractionsReducer from './app/state/nested-interactions/reducer';
import nestedReminders from './app/state/nested-reminders/reducers';
import nestedOpportunities from './app/state/nested-opportunities/reducer';
import nestedContacts from './app/state/nested-contacts/reducer';
import dashboardReducer from './app/routes/Home/state/reducer';
import emailTemplates from './app/routes/Settings/EmailTemplatesSettings/state/reducer';
import smsTemplates from './app/routes/Settings/SmsTemplatesSettings/state/reducer';

import twilioReducer from './app/components/CallDialog/state/reducer';
import conversationReducer from './app/components/Conversation/state/reducer';
import interactionsReducer from './app/activities/Activities/state/reducer';
import interactionReducer from './app/state/interaction/reducer';
import listSelectModalReducer from './app/modals/ListSelectModal/state/reducer';
import campaigns from './app/campaigns/Campaigns/state/reducers';
import campaign from './app/campaigns/Campaign/state/reducers';
import modalDialogs from './app/modals/state/reducer';

import customObjectsReducer from './app/state/custom-objects/reducers';
import countersReducer from './app/state/counters/reducers';
import groupsReducer from './app/state/groups/reducers';
import accountReducer from './app/companies/Account/state/reducers';
import accountsReducer from './app/companies/Accounts/state/reducer';
import accountsSummaryView from './app/companies/AccountsSummaryView/state/reducer';
import contactReducer from './app/contacts/Contact/state/reducer';
import contactsReducer from './app/contacts/Contacts/state/reducer';
import opportunities from './app/opportunities/Opportunities/state/reducer';
import opportunitiesSummaryView from './app/opportunities/OpportunitiesSummaryView/state/reducer';
import opportunity from './app/opportunities/Opportunity/state/reducer';
import engineRules from './app/engine-rules/EngineRules/state/data/reducer';
import engineRule from './app/engine-rules/EngineRule/state/reducer';
import tickets from './app/tickets/Tickets/state/reducers';
import ticket from './app/tickets/Ticket/state/reducer';
import assistant from './app/assistant/Assistant/state/reducer';
import assistantRules from './app/assistant/Assistant/RulesList/state/reducer';
import supportDesks from './app/routes/Settings/SupportDesks/state/reducer';
import reminders from './app/reminders/Reminders/state/reducers';
import addContacts from './app/campaigns/Campaign/AddContacts/state/reducer';
import supportDesk from './app/routes/Settings/SupportDesks/SupportDesk/state/reducer';
import pipelineView from './app/opportunities/OpportunitiesPipelineView/state/reducer';
import usersAdministration from './app/state/user-administration/reducers';
import smartProducts from './app/routes/Settings/SmartProducts/state/reducer';
import quotes from './app/quotes/Quotes/state/reducers';
import quote from './app/quotes/Quote/state/reducer';
import filters from './app/state/filters/reducers';
import modals from './app/state/modals/reducers';
import appNotifications from './app/state/app-notifications/reducer';
import entitiesView from './app/lists/ListsPage/state/reducers';
import pushNotifications from './app/state/push-notifications/reducer';
import spiroViews from './app/state/views/reducers';
import dataCollector from './app/routes/Settings/DataCollector/state/reducer';
import messages from './app/components/CallDialog/MessagesTab/Conversation/state/reducer';
import collaborationNotes from './app/components/CollaborationNotes/state/reducer';
import orders from './app/orders/Orders/state/reducer';
import order from './app/orders/Order/state/reducer';
import orderItems from './app/orders/OrderItems/state/reducer';
import orderItem from './app/orders/OrderItem/state/reducer';
import ringCentral from './app/state/ring-central/reducers';
import opportunityCelebration from './app/state/opportunity-celebration/reducers';

const mainReducer = combineReducers({
  app: appReducer,
  appNotifications,
  account: accountReducer,
  accounts: accountsReducer,
  contact: contactReducer,
  contacts: contactsReducer,
  user: userReducer,
  session: sessionReducer,
  tokens: tokensReducer,
  credentials: credentialsReducer,
  organization: organizationReducer,
  pipelines: pipelinesReducer,
  salesStages: salesStagesReducer,
  subscription: subscriptionReducer,
  calendar: calendarReducer,
  notifications: notificationsReducer,
  opportunityContactRoles: opportunityContactRolesReducer,
  subUsers: subUsersReducer,
  dashboard: dashboardReducer,
  emailTemplates,
  smsTemplates,
  twilioCall: twilioReducer,
  conversation: conversationReducer,
  twilioProfile: twilioProfileReducer,
  customFields: customFieldsReducer,
  displayFields: displayFieldsReducer,
  interactions: interactionsReducer,
  interaction: interactionReducer,
  listSelectModal: listSelectModalReducer,
  users,
  ...routesReducers,
  opportunities,
  opportunity,
  campaigns,
  campaign,
  currencies: currenciesReducer,
  nestedInteractions: nestedInteractionsReducer,
  nestedReminders,
  nestedOpportunities,
  nestedContacts,
  modalDialogs,
  customObjects: customObjectsReducer,
  counters: countersReducer,
  groups: groupsReducer,
  engineRules,
  engineRule,
  tickets,
  ticket,
  assistant,
  reminders,
  supportDesks,
  filters,
  modals,
  entitiesView,
  addContacts,
  supportDesk,
  pipelineView,
  usersAdministration,
  assistantRules,
  smartProducts,
  quotes,
  quote,
  pushNotifications,
  spiroViews,
  dataCollector,
  messages,
  collaborationNotes,
  orders,
  order,
  orderItems,
  orderItem,
  ringCentral,
  opportunityCelebration,
  opportunitiesSummaryView,
  accountsSummaryView,
});

// clear store on logout
const rootReducer = (state, action) => {
  let newState = state;

  if (action.type === DESTROY_USER_SESSION) {
    newState = undefined;
  }
  return mainReducer(newState, action);
};

export default rootReducer;
