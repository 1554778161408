import { fetchFilterProperties, fetchSavedFilters } from 'app/state/filters/nested-filters/actions';
import {
  getOpportunities as getOpportunitiesRemote,
  createOpportunity as createOpportunityRemote,
  updateOpportunity as updateOpportunityRemote,
} from 'services/opportunities';
import { parseMetaFromResponse } from 'helpers/meta';
import { setShowConfetti } from 'state/opportunity-celebration/reducers';
import { forEachError } from '../../../helpers/errorHelper';
import { error as errorAlert } from '../notifications/actions';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  REPLACE_OPPORTUNITY,
  SET_PARAMS,
  RESET,
  ADD_OPPORTUNITY_TO_LIST,
  STORE_OPPORTUNITIES,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  SET_IS_REFRESH_REQUIRED,
} from './constants';

export function requestStarted() {
  return { type: REQUEST_STARTED };
}

export function requestSuccess(data, meta) {
  return {
    type: REQUEST_SUCCESS,
    data,
    meta: parseMetaFromResponse(meta),
  };
}

export function requestFailed(json) {
  return {
    type: REQUEST_FAILED,
    errors: json.errors,
  };
}

export function createInProgress() {
  return {
    type: CREATE_IN_PROGRESS,
  };
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  };
}

export function createFailure() {
  return {
    type: CREATE_FAILURE,
  };
}

export function replaceOpportunity(opportunity) {
  return {
    type: REPLACE_OPPORTUNITY,
    opportunity,
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

export function storeOpportunities(opportunities) {
  return {
    type: STORE_OPPORTUNITIES,
    opportunities,
  };
}

export function addOpportunityToList(opportunity) {
  return {
    type: ADD_OPPORTUNITY_TO_LIST,
    opportunity,
  };
}

export function setIsRefreshRequired(isRefreshRequired) {
  return {
    type: SET_IS_REFRESH_REQUIRED,
    isRefreshRequired,
  };
}

export function getOpportunities(params = {}) {
  return async (dispatch, getState) => {
    dispatch(requestStarted());

    try {
      await dispatch(fetchFilterProperties('Opportunity'));
      await dispatch(fetchSavedFilters('Opportunity'));
      const defaultParams = getState().nestedOpportunities.params;
      const payload = {
        ...defaultParams,
        ...params,
      };
      const json = await getOpportunitiesRemote(payload);
      dispatch(requestSuccess(json.opportunities, json.meta));
      dispatch(setParams(payload));
      dispatch(setIsRefreshRequired(false));
      return json;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  };
}

export function createOpportunity(payload) {
  return (dispatch) => {
    dispatch(createInProgress());

    return createOpportunityRemote(payload)
      .then((json) => {
        dispatch(createSuccess());
        return json.opportunity;
      })
      .catch((err) => {
        dispatch(createFailure());
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}

export function updateOpportunity(opportunityId, payload = {}) {
  return (dispatch) =>
    updateOpportunityRemote(opportunityId, payload)
      .then((json) => {
        dispatch(replaceOpportunity(json.opportunity));
        dispatch(setShowConfetti({ requestPayload: payload, response: json.opportunity }));
        return json;
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}

export default {
  getOpportunities,
  updateOpportunity,
  createOpportunity,
  reset,
};
