import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { Box, Fade, styled } from '@mui/material';
import {
  getDefaultQuestions,
  createAssistantThread,
  createMessageInAssistantThread,
} from 'services/spiro-chat';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserId } from 'app/state/user/selectors';
import { error as errorAlert } from 'state/notifications/actions';
import { PusherContext } from 'app/contexts/PusherContext';
import ChatWelcomeMessage from '../ChatWelcomeMessage';
import QuickActions from '../QuickActions';
import ChatInput from '../ChatInput';
import ChatMessagesList from '../ChatMessagesList';
import { forEachError } from '../../../helpers/errorHelper';

const StyledBox = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
});

export default function ChatHome({ shouldClearState, setShouldClearState }) {
  const [messages, setMessages] = useState([]);
  const [loadingDefaultQuestions, setLoadingDefaultQuestions] = useState(false);
  const [defaultQuestions, setDefaultQuestions] = useState(null);
  const [thread, setThread] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [quickActionsVisible, setQuickActionsVisible] = useState(true);
  const userID = useSelector(selectUserId);
  const { pusher } = useContext(PusherContext);
  const dispatch = useDispatch();

  const handleCreateAssistantThread = async () => {
    try {
      const newThread = await createAssistantThread({
        open_ai_assistant_threads: { user_id: userID },
      });
      setThread(newThread);
      return newThread.id;
    } catch (error) {
      forEachError(error.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(error);
    }
  };

  const handleCreateMessageInThread = async (threadID, message) => {
    try {
      await createMessageInAssistantThread(threadID, {
        open_ai_assistant_thread_messages: { message },
      });
    } catch (error) {
      forEachError(error.data, (e) => dispatch(errorAlert(e)));
    }
  };

  const handleSend = async (message) => {
    setQuickActionsVisible(false);
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: 'request',
        value: [
          {
            text: message,
            type: 'regular',
            order: 1,
          },
        ],
        timestamp: new Date().toString(),
      },
    ]);
    setIsLoading(true);
    if (!thread) {
      const threadID = await handleCreateAssistantThread();
      if (threadID) handleCreateMessageInThread(threadID, message);
    } else {
      handleCreateMessageInThread(thread.id, message);
    }
  };

  const fetchDefaultQuestions = async () => {
    try {
      setLoadingDefaultQuestions(true);
      const defaultQuestionRes = await getDefaultQuestions();
      setDefaultQuestions(defaultQuestionRes);
    } catch (error) {
      forEachError(error.data, (e) => dispatch(errorAlert(e)));
    } finally {
      setLoadingDefaultQuestions(false);
    }
  };

  const onQuickActionClick = (action) => {
    setQuickActionsVisible(false);
    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: 'request',
          value: [
            {
              text: action.question,
              type: 'regular',
              order: -1,
            },
          ],
          timestamp: new Date().toString(),
        },
      ]);
    }, 300);

    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: 'response',
          value: [
            {
              text: action.response,
              type: 'regular',
              order: 0,
              defaultQuestion: action.default_questions,
            },
          ],
          timestamp: new Date().toString(),
        },
      ]);
    }, 800);
  };

  useEffect(() => {
    if (!defaultQuestions) fetchDefaultQuestions();
  }, []);

  useEffect(() => {
    if (userID && thread) {
      const channel = pusher.subscribe(`spiro_channel_${userID}`);

      channel.bind(`open_ai_assistant_new_message`, (data) => {
        if (data.thread_id === thread.id) {
          setMessages((prevMessages) => [...prevMessages, data.message]);
          setIsLoading(false);
        }
      });
    }

    return () => {
      if (userID && thread) {
        pusher.unsubscribe(`spiro_channel_${userID}`);
      }
    };
  }, [userID, thread]);

  useEffect(() => {
    if (shouldClearState) {
      setMessages([]);
      setIsLoading(false);
      setShouldClearState(false);
      setQuickActionsVisible(true);
      setThread(null);
    }
  }, [shouldClearState]);

  if (loadingDefaultQuestions) return null;

  return (
    <Fade in timeout={500}>
      <StyledBox>
        {messages.length === 0 && (
          <>
            <ChatWelcomeMessage isVisible={quickActionsVisible} />
            <QuickActions
              isVisible={quickActionsVisible}
              defaultQuestions={defaultQuestions}
              onQuickActionClick={onQuickActionClick}
            />
          </>
        )}
        <ChatMessagesList messages={messages} isLoading={isLoading} />
        <ChatInput handleSend={handleSend} isLoading={isLoading} />
      </StyledBox>
    </Fade>
  );
}

ChatHome.propTypes = {
  setShouldClearState: PropTypes.func.isRequired,
  shouldClearState: PropTypes.bool.isRequired,
};
