import { SET_SUBSCRIPTION, EXPIRE_SUBSCRIPTION } from './constants';

const initialState = {
  notificationVisible: false,
  customerStatus: '',
  customerDaysLeft: '',
  customerExpiresAt: '',
  customerSince: '',
  trialDaysLeft: '',
  trialExpiresAt: '',
  expired: false,
};

function setSubscriptionHandler(state, action) {
  return { ...state, ...action.data };
}

function expireSubscriptionHandler(state, action) {
  return { ...state, ...action.data, expired: true };
}

const ACTION_HANDLERS = {
  [SET_SUBSCRIPTION]: setSubscriptionHandler,
  [EXPIRE_SUBSCRIPTION]: expireSubscriptionHandler,
};

export default function subscriptionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
