import React, { useReducer, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import useDebounce from 'hooks/useDebounce';
import useHotjar from 'hooks/useHotjar';
import { search } from 'services/search';
import { parseMetaFromResponse } from 'helpers/meta';
import reducer, { initialState } from './reducer';
import ACTIONS from './actions';
import resultFactory from '../resultFactory';

const SearchContext = React.createContext();

export const useSearch = () => useContext(SearchContext);

function SearchProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const debouncedSearchTerm = useDebounce(state.searchTerm, 200);
  const groupedResults = useMemo(() => groupBy(state.results, 'type'), [state.results]);
  const { logCustomEvent } = useHotjar();

  const setVisible = (visible) => {
    dispatch({ type: ACTIONS.SET_VISIBLE, visible });
  };

  const setSearchTerm = (searchTerm) => {
    dispatch({ type: ACTIONS.SET_SEARCH_TERM, searchTerm });
  };

  const setLoading = (loading) => {
    dispatch({ type: ACTIONS.SET_LOADING, loading });
  };

  const setResults = (results) => {
    dispatch({ type: ACTIONS.SET_RESULTS, results });
  };

  const setMeta = (meta) => {
    dispatch({ type: ACTIONS.SET_META, meta });
  };

  const setParams = (params) => {
    dispatch({ type: ACTIONS.SET_PARAMS, params });
  };

  const setCleanState = () => {
    dispatch({ type: ACTIONS.SET_CLEAN_STATE });
  };

  const setInitialState = () => {
    dispatch({ type: ACTIONS.SET_INITIAL_STATE });
  };

  const fetchSearchResults = useCallback(async () => {
    setLoading(true);

    const response = await search({ query: debouncedSearchTerm, ...state.params });
    const results = response.search.map((result) => resultFactory.create(result));
    const meta = parseMetaFromResponse(response.meta);

    setResults(results);
    setMeta(meta);
    setLoading(false);
    logCustomEvent('global_search_results_fetched');
  }, [debouncedSearchTerm, state.params, setResults, setMeta, setLoading]);

  return (
    <SearchContext.Provider
      value={{
        ...state,
        debouncedSearchTerm,
        groupedResults,
        setVisible,
        setSearchTerm,
        setLoading,
        setResults,
        setMeta,
        setParams,
        setCleanState,
        setInitialState,
        fetchSearchResults,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchProvider;
