import { deleteUserToken } from 'services/users';
import { removeCalendars } from '../calendar/actions';
import { STORE_TOKENS, SET_IS_DELETING, REMOVE_TOKEN } from './constants';

export function storeTokens(data) {
  return {
    type: STORE_TOKENS,
    data,
  };
}

export function setIsDeleting(status) {
  return {
    type: SET_IS_DELETING,
    status,
  };
}

export function removeToken(tokenId) {
  return {
    type: REMOVE_TOKEN,
    tokenId,
  };
}

export function deleteToken(userId, tokenId) {
  return (dispatch) => {
    dispatch(setIsDeleting(true));

    return deleteUserToken(userId, tokenId)
      .then(() => {
        dispatch(setIsDeleting(false));
        dispatch(removeToken(tokenId));
        dispatch(removeCalendars());
      })
      .catch((e) => {
        throw e;
      });
  };
}

export default {
  storeTokens,
};
