import {
  FETCHING_CURRENCIES,
  FETCHING_CURRENCIES_SUCCESS,
  FETCHING_CURRENCIES_FAILED,
  STORE_CURRENCIES,
} from './constants';

import { getCurrencies as getCurrenciesRemote } from '../../services/currencies';

export function fetching() {
  return {
    type: FETCHING_CURRENCIES,
  };
}

export function fetchSuccess(data) {
  return {
    type: FETCHING_CURRENCIES_SUCCESS,
    data,
  };
}

export function fetchFailed(data) {
  return {
    type: FETCHING_CURRENCIES_FAILED,
    data,
  };
}

export function storeCurrencies(data) {
  return {
    type: STORE_CURRENCIES,
    data,
  };
}

export function getCurrencies() {
  return async (dispatch) => {
    dispatch(fetching());

    try {
      const response = await getCurrenciesRemote();
      const r = dispatch(storeCurrencies(response.currencies));
      return dispatch(fetchSuccess(r));
    } catch (e) {
      return dispatch(fetchFailed(e));
    }
  };
}

export default {
  fetching,
  fetchSuccess,
  fetchFailed,
  getCurrencies,
};
