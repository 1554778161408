import Opportunity from './SearchResult/opportunity';
import Contact from './SearchResult/contact';
import Account from './SearchResult/account';
import Interaction from './SearchResult/interaction';
import CollaborationNote from './SearchResult/collaborationNote';

const resultFactory = {
  create(result) {
    switch (result.type) {
      case 'Opportunity':
        return new Opportunity(result);
      case 'Contact':
        return new Contact(result);
      case 'Account':
        return new Account(result);
      case 'Interaction':
        return new Interaction(result);
      case 'CollaborationNote':
        return new CollaborationNote(result);
      default:
        return null;
    }
  },
};

export default resultFactory;
