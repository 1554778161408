import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../state/user/selectors';
import ActiveEditor from '../ActiveEditor';
import InactiveEditor from '../InactiveEditor';
import styles from './NotesInput.module.scss';

function NotesInput({ onChange = () => {}, editorValue = '', isEditorOpen = false }) {
  const [isActive, setIsActive] = useState(isEditorOpen);
  const user = useSelector(selectUser);

  const toggleActiveEditor = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={styles.wrapper}>
      <ActiveEditor
        user={user}
        toggleActiveEditor={toggleActiveEditor}
        isActive={isActive}
        hasFooter={false}
        onChange={onChange}
        editorValue={editorValue}
      />
      {!isActive && <InactiveEditor onClick={toggleActiveEditor} user={user} isActive={isActive} />}
    </div>
  );
}

export default NotesInput;
