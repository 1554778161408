import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupMessagesByDate } from 'helpers/twilio';
import { selectTwilioProfile } from '../../../../../state/user/selectors';
import Chat from '../../../../Chat/Chat';
import LoadMoreButton from '../../../../Chat/LoadMoreButton';
import MessageForm from '../../../../Conversation/MessageForm';
import { selectChatId, selectMeta } from '../state/selectors';
import { fetchSingleMessage } from '../state/actions';
import styles from './ChatWindow.module.scss';

function ChatWindow({ data = [], contact = null, phone = '' }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const twilioProfile = useSelector(selectTwilioProfile);
  const meta = useSelector(selectMeta);
  const chatId = useSelector(selectChatId);
  const [scrollTrigger, setScrollTrigger] = useState(false);

  let prevScrollHeight = '';

  function scrollToBottom() {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }

  function setNewScrollTop() {
    ref.current.scrollTop = ref.current.scrollHeight - prevScrollHeight;
  }

  function setPrevScrollHeight() {
    prevScrollHeight = ref.current.scrollHeight;
  }

  const handleLoadMore = () => {
    setPrevScrollHeight();
    dispatch(fetchSingleMessage({ id: chatId, params: { page: meta.nextPage } })).then(() => {
      requestAnimationFrame(() => {
        setNewScrollTop();
      });
    });
  };

  const onSuccess = () => {
    setScrollTrigger(true);
  };

  useEffect(() => {
    if (data.length) {
      scrollToBottom();
    }
  }, []);

  useEffect(() => {
    if (scrollTrigger) {
      scrollToBottom();
      setScrollTrigger(false);
    }
  }, [scrollTrigger]);

  return (
    <div className={styles.container}>
      <div className={styles.chat} ref={ref}>
        <LoadMoreButton visible={!!meta.nextPage} onClick={handleLoadMore} />
        <Chat messages={groupMessagesByDate(data)} />
      </div>
      {twilioProfile && twilioProfile.profile_type !== 'verified_number' && phone && (
        <MessageForm contact={contact} phone={phone} onSuccess={onSuccess} maxRows="12" />
      )}
    </div>
  );
}

export default ChatWindow;
