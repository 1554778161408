import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {
  selectTwilioCaller,
  selectPhoneNumber,
  selectTwilioCallers,
  selectDisplayNotificationForCall,
} from 'components/CallDialog/state/selectors';
import { toggleDisplaySystemNotificationForCall } from 'components/CallDialog/state/actions';
import CallActions from '../CallActions';
import CallerInfo from '../CallerInfo';
import CallMainActions from '../CallMainActions/CallMainActions';
import styles from './IncomingCallModal.module.scss';

function IncomingCallModal() {
  const caller = useSelector(selectTwilioCaller);
  const phoneNumber = useSelector(selectPhoneNumber);
  const callers = useSelector(selectTwilioCallers);
  const displayCallNotification = useSelector(selectDisplayNotificationForCall);
  const dispatch = useDispatch();

  useEffect(() => {
    if (displayCallNotification) {
      const title = `Call from ${
        callers.length !== 1
          ? parsePhoneNumberFromString(phoneNumber)?.formatInternational()
          : caller.name
      }`;
      const notification = new Notification(title, {
        requireInteraction: true,
        body: caller?.account_name || '',
      });
      notification.onclick = () => {
        window.focus();
        notification.close();
      };
    }
    dispatch(toggleDisplaySystemNotificationForCall(false));
  }, [displayCallNotification]);

  return (
    <div className={styles['incoming-call']}>
      <CallerInfo mainTextColor="orange" subTextColor="gray" />
      <div className={styles.actions}>
        {caller && <CallActions />}
        <CallMainActions />
      </div>
    </div>
  );
}

export default IncomingCallModal;
