import React, { useState, useEffect, Children, cloneElement, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './TypingEffectSequence.module.scss';

function TypingEffectSequence({ speed, children }) {
  const [displayedText, setDisplayedText] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [typingUL, setTypingUL] = useState(false);
  const [pendingULChildren, setPendingULChildren] = useState([]);
  const [visibleULIndices, setVisibleULIndices] = useState([]);
  const [liText, setLiText] = useState({});
  const timeoutRef = useRef(null);

  const extractChildren = (child) => {
    if (child.type === React.Fragment) {
      return Children.toArray(child.props.children).flatMap(extractChildren);
    }
    if (typeof child.type === 'function') {
      const renderedChild = child.type(child.props);
      return extractChildren(renderedChild);
    }
    if (child.type === 'ul') {
      const ulChildren = Children.toArray(child.props.children).flatMap(extractChildren);
      return [
        cloneElement(child, {
          children: ulChildren.map((li, index) =>
            cloneElement(li, { style: { display: 'none' }, 'data-index': index })
          ),
        }),
      ];
    }
    return [child];
  };

  const childArray = Children.toArray(children).flatMap(extractChildren);

  useEffect(() => {
    if (childArray.length > 0 && displayedText.length < childArray.length) {
      setDisplayedText(new Array(childArray.length).fill(''));
    }
  }, [childArray, displayedText.length]);

  useEffect(() => {
    if (currentIndex < childArray.length && displayedText.length === childArray.length) {
      const element = childArray[currentIndex];
      if (element.type === 'ul') {
        setTypingUL(true);
        setPendingULChildren(Children.toArray(element.props.children));
        setCurrentIndex(currentIndex + 1);
        setIsTyping(false);
        return;
      }
      const text = typeof element.props.children === 'string' ? element.props.children : '';
      if (text && displayedText[currentIndex]?.length < text.length) {
        setIsTyping(true);
        timeoutRef.current = setTimeout(() => {
          setDisplayedText((prev) => {
            const newDisplayedText = [...prev];
            newDisplayedText[currentIndex] += text.charAt(displayedText[currentIndex].length);
            return newDisplayedText;
          });
        }, speed);
      } else {
        setIsTyping(false);
        if (currentIndex < childArray.length - 1) {
          setTimeout(() => {
            setCurrentIndex(currentIndex + 1);
            setIsTyping(true);
          }, speed);
        }
      }
    }
  }, [currentIndex, displayedText, childArray, speed]);

  useEffect(() => {
    if (typingUL && pendingULChildren.length > 0) {
      const element = pendingULChildren[0];
      const text = typeof element.props.children === 'string' ? element.props.children : '';
      const index = element.props['data-index'];
      if (text) {
        setVisibleULIndices((prev) => [...prev, index]);
        timeoutRef.current = setTimeout(() => {
          setLiText((prev) => ({
            ...prev,
            [index]: (prev[index] || '') + text.charAt((prev[index] || '').length),
          }));
          if ((liText[index]?.length || 0) + 1 === text.length) {
            setPendingULChildren((prev) => prev.slice(1));
          }
        }, speed);
      }
    } else if (typingUL) {
      setTypingUL(false);
      setIsTyping(true);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [typingUL, pendingULChildren, liText, speed]);

  const renderChildWithTypingEffect = (child, index) => {
    if (typeof child.props.children === 'string') {
      return cloneElement(child, {
        children: (
          <>
            {displayedText[index]}
            {index === currentIndex && isTyping && <span className={styles.cursor}>|</span>}
          </>
        ),
      });
    }
    if (child.type === 'ul') {
      const ulChildren = Children.map(child.props.children, (li) => {
        const liIndex = li.props['data-index'];
        const isVisible = visibleULIndices.includes(liIndex);
        const typedText = liText[liIndex] || '';
        const fullText = typeof li.props.children === 'string' ? li.props.children : '';
        const isTypingLI = isVisible && typedText.length < fullText.length;
        return cloneElement(li, {
          style: { display: isVisible ? 'list-item' : 'none' },
          children: (
            <>
              {typedText}
              {isTypingLI && <span className={styles.cursor}>|</span>}
            </>
          ),
        });
      });
      return cloneElement(child, { children: ulChildren });
    }
    return child;
  };

  return (
    <div className={styles['typing-effect-sequence']}>
      {childArray.map((child, index) => (
        <React.Fragment key={index}>{renderChildWithTypingEffect(child, index)}</React.Fragment>
      ))}
    </div>
  );
}

export default TypingEffectSequence;

TypingEffectSequence.defaultProps = {
  speed: 100,
};

TypingEffectSequence.propTypes = {
  speed: PropTypes.number,
  children: PropTypes.array.isRequired,
};
