import EngineService from '../core/engine-service';

const ENDPOINT = '/announcement';

export async function getAnnouncement() {
  return EngineService.get(ENDPOINT).then((json) => json);
}

export function clearAnnouncement(payload) {
  return EngineService.patch(ENDPOINT, {
    announcement: payload,
  }).then((json) => json);
}
