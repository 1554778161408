import PropTypes from 'prop-types';
import classNames from 'classnames';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { getFullName } from 'helpers/contacts';
import { convertNameToInitials } from 'helpers/utils';
import styles from './ContactAvatar.module.scss';

function ContactAvatar({ contact, className = '' }) {
  const fullContactAvatar = contact.fullcontact_info?.avatar;

  if (fullContactAvatar) {
    return (
      <img
        src={fullContactAvatar}
        alt="contact avatar"
        className={classNames(styles.avatar, className)}
      />
    );
  }

  if (!contact.first_name) {
    return (
      <div className={classNames(styles.initials, className)}>
        <PersonOutlineIcon />
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.initials, className)}
      data-cy="contact-details-page-header-icon"
    >
      {convertNameToInitials(getFullName(contact))}
    </div>
  );
}

ContactAvatar.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default ContactAvatar;
