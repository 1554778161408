import {
  SHOW_NOTIFICATIONS_SLIDER,
  HIDE_NOTIFICATIONS_SLIDER,
  STORE_ALERT_NOTIFICATIONS,
  SET_SHOULD_REFETCH_ALERTS,
  ADD_UNREAD_NOTIFICATION,
} from './constants';

const initialState = {
  isOpen: false,
  hasUnreadNotifications: false,
  unreadNotifications: [],
  alerts: [],
  shouldRefetchAlerts: false,
  hasNewNotifications: false,
};

const ACTION_HANDLERS = {
  [SHOW_NOTIFICATIONS_SLIDER]: (state) => ({
    ...state,
    isOpen: true,
    unreadNotifications: [],
  }),
  [HIDE_NOTIFICATIONS_SLIDER]: (state) => ({
    ...state,
    isOpen: false,
  }),
  [STORE_ALERT_NOTIFICATIONS]: (state, action) => ({
    ...state,
    alerts: action.payload.alerts,
    shouldRefetchAlerts: false,
  }),
  [SET_SHOULD_REFETCH_ALERTS]: (state) => ({
    ...state,
    shouldRefetchAlerts: true,
  }),
  [ADD_UNREAD_NOTIFICATION]: (state, action) => ({
    ...state,
    unreadNotifications: [action.payload.notification, ...state.unreadNotifications],
  }),
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
