import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EventIcon from '@mui/icons-material/Event';
import { List, ListItem, Tooltip, IconButton } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SearchProvider from 'components/SearchModal/SearchProvider';
import TwilioOnly from 'components/TwilioOnly';
import PhoneDialogMinimized from 'components/CallDialog/PhoneDialogMinimized';
import { showNotificationsSlider } from 'state/app-notifications/actions';
import { selectUser } from 'state/user/selectors';
import NavSearch from '../NavSearch/NavSearch';
import UserWidget from '../UserWidget';
import NotificationIndicator from './NotificationIndicator';
import EmailIndicator from './EmailIndicator';
import styles from './TopBarActions.module.scss';

export default function TopBarActions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const openNotifications = () => {
    dispatch(showNotificationsSlider());
  };

  const isShown = user.hasUnreadNotifications;

  return (
    <div className={styles.actions}>
      <div className={styles.icons}>
        <List
          component="nav"
          aria-labelledby="top bar actions"
          className={styles['nav-display-flex']}
        >
          <ListItem className={styles['list-items']}>
            <SearchProvider>
              <NavSearch />
            </SearchProvider>
          </ListItem>
          <div className={styles.divider} />
          <ListItem className={styles['phone-wrapper']}>
            <TwilioOnly>
              <PhoneDialogMinimized />
            </TwilioOnly>
          </ListItem>
          <TwilioOnly>
            <div className={styles.divider} />
          </TwilioOnly>
          <ListItem className={styles['list-items']}>
            <EmailIndicator />
          </ListItem>
          <ListItem className={styles['mid-list-item']}>
            <Tooltip
              title={t('localization.navigation.calendar')}
              arrow
              enterDelay={300}
              enterNextDelay={300}
            >
              <IconButton
                aria-label="Calendar"
                color="inherit"
                onClick={() => navigate('/calendar')}
                size="large"
              >
                <EventIcon data-cy="navbar-calendar-icon" />
              </IconButton>
            </Tooltip>
          </ListItem>
          <ListItem className={styles['list-items']}>
            {isShown && <NotificationIndicator />}
            <NotificationsNoneIcon
              onClick={openNotifications}
              data-cy="navbar-notifications-icon"
            />
          </ListItem>
        </List>
      </div>
      <UserWidget />
    </div>
  );
}
