import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  SORT_CONTACTS,
  REPLACE_CONTACT,
  SET_PARAMS,
  RESET_DATA,
  STORE_CONTACTS,
  ADD_CONTACT_TO_LIST,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
} from './constants';
import { nullMeta } from '../../helpers/meta';

const initialState = {
  requestStarted: false,
  data: [],
  errors: [],
  requestFailed: false,
  requestSuccess: false,
  createInProgress: false,

  meta: nullMeta,
  params: {
    page: 1,
    per_page: 10,
    sort: undefined,
    order: undefined,
    account_id: undefined,
    q: undefined,
  },
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
    requestFailed: false,
    requestSuccess: false,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: false,
    requestSuccess: true,
    data: action.data,
    meta: action.meta,
  };
}

function requestFailedHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: true,
    requestSuccess: false,
    errors: action.errors,
  };
}

function sortContactsHandler(state, action) {
  return {
    ...state,
    sortColumn: action.sortColumn,
    sortOrder: action.sortOrder,
  };
}

function replaceContactHandler(state, action) {
  return { ...state, data: action.replaceContact(state.data) };
}

function storeContactsHandler(state, action) {
  return { ...state, data: action.contacts };
}

function addContactToListHandler(state, action) {
  return { ...state, data: [action.contact, ...state.data] };
}

export function setParamsHandler(state, action) {
  return { ...state, params: { ...state.params, ...action.data } };
}

export function resetDataHandler(state) {
  return {
    ...state,
    data: [],
    meta: initialState.meta,
    params: initialState.params,
  };
}

export function createInProgressHandler(state) {
  return { ...state, createInProgress: true };
}

export function createSuccessHandler(state) {
  return { ...state, createInProgress: false };
}

export function createFailureHandler(state) {
  return { ...state, createInProgress: false };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAILED]: requestFailedHandler,
  [SORT_CONTACTS]: sortContactsHandler,
  [REPLACE_CONTACT]: replaceContactHandler,
  [STORE_CONTACTS]: storeContactsHandler,
  [SET_PARAMS]: setParamsHandler,
  [RESET_DATA]: resetDataHandler,
  [CREATE_IN_PROGRESS]: createInProgressHandler,
  [CREATE_SUCCESS]: createSuccessHandler,
  [CREATE_FAILURE]: createFailureHandler,
  [ADD_CONTACT_TO_LIST]: addContactToListHandler,
};

export default function contactsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
