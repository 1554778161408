import {
  GET_CAMPAIGNS_REQUEST_STARTED,
  GET_CAMPAIGNS_REQUEST_SUCCESS,
  POST_CAMPAIGN_REQUEST_STARTED,
  POST_CAMPAIGN_REQUEST_SUCCESS,
  POST_CAMPAIGN_REQUEST_FAILED,
  PATCH_CAMPAIGN_REQUEST_STARTED,
  PATCH_CAMPAIGN_REQUEST_SUCCESS,
  PATCH_CAMPAIGN_REQUEST_FAILED,
  REMOVE_CAMPAIGN,
  SET_PARAMS,
  RESET_STATE,
  SET_RECORDS_COUNT,
} from './constants';
import { immutableInsert, immutableFindAndDelete } from '../../../../../helpers/immutableHelper';
import { nullMeta } from '../../../../helpers/meta';

const initialState = {
  data: [],
  params: {
    page: 1,
    per_page: 25,
    sort: undefined,
    order: undefined,
    query: undefined,
  },
  meta: nullMeta,
  campaignsGetInProgress: false,
  campaignPostInProgress: false,
  campaignPatchInProgress: false,
  recordsCount: 0,
};

function getCampaignsRequestStartedHandler(state) {
  return { ...state, campaignsGetInProgress: true };
}

function getCampaignsRequestSuccessHandler(state, action) {
  return {
    ...state,
    campaignsGetInProgress: false,
    data: action.campaigns,
    meta: action.meta,
  };
}

function postCampaignRequestStartedHandler(state) {
  return { ...state, campaignPostInProgress: true };
}

function postCampaignRequestSuccessHandler(state) {
  return {
    ...state,
    campaignPostInProgress: false,
  };
}

function postCampaignRequestFailedHandler(state) {
  return { ...state, campaignPostInProgress: false };
}

function patchCampaignRequestStartedHandler(state) {
  return { ...state, campaignPatchInProgress: true };
}

function patchCampaignRequestSuccessHandler(state, action) {
  const itemId = state.data.findIndex((c) => c.id === action.campaign.id);
  if (itemId === -1) {
    return state;
  }
  return {
    ...state,
    campaignPatchInProgress: false,
    data: immutableInsert(state.data, action.campaign, itemId),
  };
}

function patchCampaignRequestFailedHandler(state) {
  return { ...state, campaignPatchInProgress: false };
}

function removeCampaignHandler(state, action) {
  return {
    ...state,
    data: immutableFindAndDelete(state.data, (item) => item.id === action.id),
  };
}

export function setParamsHandler(state, action) {
  return { ...state, params: { ...state.params, ...action.data } };
}

export function resetStateHandler() {
  return initialState;
}

function setRecordsCountHandler(state, action) {
  return { ...state, recordsCount: action.recordsCount };
}

const ACTION_HANDLERS = {
  [GET_CAMPAIGNS_REQUEST_STARTED]: getCampaignsRequestStartedHandler,
  [GET_CAMPAIGNS_REQUEST_SUCCESS]: getCampaignsRequestSuccessHandler,
  [POST_CAMPAIGN_REQUEST_STARTED]: postCampaignRequestStartedHandler,
  [POST_CAMPAIGN_REQUEST_SUCCESS]: postCampaignRequestSuccessHandler,
  [POST_CAMPAIGN_REQUEST_FAILED]: postCampaignRequestFailedHandler,
  [PATCH_CAMPAIGN_REQUEST_STARTED]: patchCampaignRequestStartedHandler,
  [PATCH_CAMPAIGN_REQUEST_SUCCESS]: patchCampaignRequestSuccessHandler,
  [PATCH_CAMPAIGN_REQUEST_FAILED]: patchCampaignRequestFailedHandler,
  [REMOVE_CAMPAIGN]: removeCampaignHandler,
  [SET_PARAMS]: setParamsHandler,
  [RESET_STATE]: resetStateHandler,
  [SET_RECORDS_COUNT]: setRecordsCountHandler,
};

export default function campaignsDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
