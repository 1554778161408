import { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MessageAvatar from './MessageAvatar';
import MessageDetails from './MessageDetails';
import { setConversationScreenOpen } from '../../state/actions';
import { setChatId, setContact, setPhone } from '../Conversation/state/reducer';
import styles from './MessageCard.module.scss';

const MessageCard = forwardRef(({ message }, ref) => {
  const dispatch = useDispatch();

  const openConversationScreen = () => {
    dispatch(setConversationScreenOpen(true));
    dispatch(setChatId(message.id));
    dispatch(setPhone(message.phone));
    dispatch(setContact(message.contact));
  };

  return (
    <div
      className={styles.wrapper}
      onClick={openConversationScreen}
      role="button"
      tabIndex="0"
      ref={ref}
    >
      <MessageAvatar message={message} />
      <MessageDetails message={message} />
    </div>
  );
});

MessageCard.propTypes = {
  message: PropTypes.object.isRequired,
};

export default MessageCard;
