import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { destroyUserSession } from 'state/session/actions';
import ConnectedUsers from '../ConnectedUsers';
import styles from './UserDropdown.module.scss';

export default function UserDropdown({ onHide }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToSettings = () => {
    navigate('/settings/user-profile');
    onHide();
  };

  const handleLogoutClick = () => {
    setLoading(true);
    dispatch(destroyUserSession()).then(() => {
      navigate('/login', { state: { from: { pathname: '/' } } });
      setLoading(false);
    });
  };

  return (
    <div className={styles.dropdown}>
      <ConnectedUsers />
      <MenuItem onClick={goToSettings} className={styles['menu-item']}>
        <SettingsIcon data-cy="navbar-settings-icon" />
        <span data-cy="navbar-settings-dropdown-item">{t('localization.navigation.settings')}</span>
      </MenuItem>
      <MenuItem onClick={handleLogoutClick} className={styles['menu-item']}>
        <ExitToAppIcon data-cy="navbar-logout-icon" />
        <span data-cy="navbar-logout-dropdown-item">{t('localization.navigation.logout')}</span>
      </MenuItem>
      {loading && <LinearProgress />}
    </div>
  );
}

UserDropdown.propTypes = {
  onHide: PropTypes.func.isRequired,
};
