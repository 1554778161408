import map from 'lodash/map';
import isArray from 'lodash/isArray';
import { v4 as uuidv4 } from 'uuid';

export const nullFilter = {
  id: null,
  title: '',
  filters: [],
  default: false,
  public: false,
};

const filterKey = (text) =>
  ({
    account: 'Company',
    contact: 'Contact',
    opportunity: 'Opportunity',
    interaction: 'Activity',
  })[text] || text;

export function parseFilterProperties(data = []) {
  return map(data, (p, key) => ({
    name: key,
    label: p.label,
    type: p.type,
    operators: p.operators,
    values: p.values,
  }));
}

export function parseFilterPropertiesForSelect(properties) {
  return Object.entries(properties).map(([key, value]) => ({
    [filterKey(key)]: parseFilterProperties(value).map((property) => ({
      value: property.name,
      label: property.label,
    })),
  }));
}

export function parseFilterObject() {
  return {};
}

export function parseSavedFilter(filter = {}) {
  return {
    id: filter.id || null,
    f: filter.filters || [],
    title: filter.title || '',
    resource: filter.resource || '',
    default: filter.default || false,
    engineDefault: filter.engine_default,
  };
}

export function parseSavedFilters(filters = []) {
  return filters.map(parseSavedFilter);
}

function buildFilter(matchedGroups) {
  return {
    id: uuidv4(),
    type: 'string',
    property: matchedGroups[1],
    value: matchedGroups[4],
    operator: `${matchedGroups[2]}${matchedGroups[3]}`,
  };
}

export function parseStringToFilters(query = '') {
  const filters = [];
  const rgx = /(.+?)(:|!|<=|>=|>|<)(\*?)(|.+?)(\*?),/g;
  let match = rgx.exec(`${query},`);
  while (match !== null) {
    filters.push(buildFilter(match));
    match = rgx.exec(`${query},`);
  }
  return filters;
}

export function serializeFiltersToString(filters = []) {
  if (!isArray(filters)) throw new TypeError('Filters should be array');

  return filters
    .map((filter) => {
      const value = filter.value.value || filter.value;
      const operator = filter.operator.replace('null', '');
      let filterValue = '';

      if (operator === ':*' || operator === '!*') {
        filterValue = value.includes('*') ? `:${value}` : `${operator}${value}*`;
      } else if (operator === 'IN') {
        filterValue = `${operator}${value.toString().split(',').join(';')}`;
      } else {
        filterValue = `${operator}${value}`;
      }

      return `${filter.relation || ''}${filter.property}${filterValue}`;
    })
    .join('~');
}

export function syncDefaultFilter(filters, updatedFilter) {
  return filters.map((f) => ({ ...f, default: updatedFilter.default ? false : f.default }));
}

export default {
  parseFilterProperties,
  parseSavedFilter,
  parseSavedFilters,
  serializeFiltersToString,
  syncDefaultFilter,
};
