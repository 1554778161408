import { create as createReducer } from '../../core/redux-utils';
import {
  STORE_PIPELINES,
  STORE_PIPELINE,
  INSERT_PIPELINE,
  DELETE_PIPELINE,
  REQUEST_START,
  REQUEST_SUCCESS,
  REQUEST_FAIL,
  SELECT_PIPELINE,
} from './constants';
import { immutableInsert, immutableDelete } from '../../../helpers/immutableHelper';

const initialState = {
  items: [],
  selected: undefined,
  requestInProgress: false,
  requestSuccess: false,
  requestFail: false,
};

function requestStartHandler(state) {
  return {
    ...state,
    requestInProgress: true,
    requestSuccess: false,
    requestFail: false,
  };
}

function requestSuccessHandler(state) {
  return {
    ...state,
    requestInProgress: false,
    requestSuccess: true,
    requestFail: false,
  };
}

function requestFailHandler(state) {
  return {
    ...state,
    requestInProgress: false,
    requestSuccess: false,
    requestFail: true,
  };
}

function storePipelinesHandler(state, action) {
  return {
    ...state,
    items: [...action.pipelines],
  };
}

function storePipelineHandler(state, action) {
  const i = state.items.findIndex((pip) => pip.id === action.pipeline.id);
  return { ...state, items: immutableInsert(state.items, action.pipeline, i) };
}

function selectPipelineHandler(state, action) {
  return { ...state, selected: action.id };
}

function insertPipelineHandler(state, action) {
  return {
    ...state,
    items: [...state.items, action.pipeline],
  };
}

function deletePipelineHandler(state, action) {
  const i = state.items.findIndex((pip) => pip.id === action.id);

  return {
    ...state,
    items: immutableDelete(state.items, i),
  };
}

const actionHandlers = {
  [REQUEST_START]: requestStartHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAIL]: requestFailHandler,
  [STORE_PIPELINES]: storePipelinesHandler,
  [STORE_PIPELINE]: storePipelineHandler,
  [INSERT_PIPELINE]: insertPipelineHandler,
  [DELETE_PIPELINE]: deletePipelineHandler,
  [SELECT_PIPELINE]: selectPipelineHandler,
};

export default createReducer(initialState, actionHandlers);
