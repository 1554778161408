import { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import IconButton from '@mui/material/IconButton';
import useCall from 'lib/CallButton/useCall';
import useConversation from 'hooks/useConversation';
import RecentsAvatar from '../RecentsCard/RecentsAvatar';
import VoicemailDetails from './VoicemailDetails';
import DateHelper from '../../../../helpers/DateHelper';
import VoicemailDetailsAndTranscriptions from './VoicemailDetailsAndTranscriptions';
import styles from './VoicemailCard.module.scss';

const VoicemailCard = forwardRef(
  (
    { voicemail, setSelectedVoicemail, contentVisible, setContentVisible, cardShouldBeClosed },
    ref
  ) => {
    const collapseSectionRef = useRef();
    const startCall = useCall();
    const [open, setOpen] = useState(false);
    const { showConversation } = useConversation();

    const onClick = () => {
      if (open) {
        setSelectedVoicemail(null);
        setTimeout(() => {
          setContentVisible(false);
        }, 300);
      } else setSelectedVoicemail(voicemail.id);
      setOpen(!open);
    };

    const getResourceFromCall = (call) => {
      if (call.account) return 'Account';
      if (call.contact) return 'Contact';
      return '';
    };

    useEffect(() => {
      if (cardShouldBeClosed) setOpen(false);
    }, [cardShouldBeClosed]);

    const initiateCall = () => {
      const resource = getResourceFromCall(voicemail.phone_call_log);
      startCall(voicemail.phone_call_log.from_number, resource);
    };

    const startConversation = () => {
      showConversation({ phone_number: voicemail.phone_call_log.from_number });
    };

    const observer = new IntersectionObserver((entires) => {
      if (entires[0].isIntersecting && entires[0].intersectionRatio < 1) {
        collapseSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        observer.unobserve(collapseSectionRef.current);
      }
    });

    useEffect(() => {
      if (open) {
        setContentVisible(true);
        setTimeout(() => {
          observer.observe(collapseSectionRef.current);
        }, 300);
      }
    }, [open]);

    const displayVoicemailDuration = (time) => {
      if (time && !Number.isNaN(time)) {
        const minutes = Math.floor(time / 60);
        const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const seconds = Math.ceil(time % 60);
        const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        if (minutes === 0) return `0:${formatSeconds}`;
        return `${formatMinutes}:${formatSeconds}`;
      }
      return '0:00';
    };

    return (
      <div className={open ? styles['extended-card'] : styles.card} ref={ref}>
        <div ref={collapseSectionRef}>
          <div className={styles.container} onClick={onClick} role="button" tabIndex={0}>
            <RecentsAvatar
              recent={{ ...voicemail.phone_call_log, confirmed: voicemail.confirmed }}
            />
            <VoicemailDetails voicemail={voicemail} extended={open} />
            {!open && (
              <div className={styles.date}>
                <div>{DateHelper.formatDateForVoicemails(voicemail.created_at)}</div>
                <div>{displayVoicemailDuration(voicemail.duration)}</div>
              </div>
            )}
            {open && (
              <div className={styles.actions}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    initiateCall();
                  }}
                >
                  <PhoneOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    startConversation();
                  }}
                >
                  <ChatOutlinedIcon />
                </IconButton>
              </div>
            )}
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {contentVisible && <VoicemailDetailsAndTranscriptions voicemail={voicemail} />}
          </Collapse>
        </div>
      </div>
    );
  }
);

export default VoicemailCard;

VoicemailCard.propTypes = {
  setSelectedVoicemail: PropTypes.func.isRequired,
  voicemail: PropTypes.object.isRequired,
  contentVisible: PropTypes.bool.isRequired,
  setContentVisible: PropTypes.func.isRequired,
  cardShouldBeClosed: PropTypes.bool.isRequired,
};
