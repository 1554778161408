import { REQUEST_STARTED, REQUEST_SUCCESS, REQUEST_FAILED } from './constants';

const initialState = {
  requestStarted: false,
  requestFailed: false,
  requestSuccess: false,
  data: [],
  errors: {},
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
    requestFailed: false,
    requestSuccess: false,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: false,
    requestSuccess: true,
    data: action.data,
  };
}

function requestFailedHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: true,
    requestSuccess: false,
    errors: action.errors,
  };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAILED]: requestFailedHandler,
};

export default function subUsersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
