import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REPLACE_OPPORTUNITY,
  STORE_OPPORTUNITY_TABS_ORDER,
} from './constants';

const initialState = {
  requestStarted: false,
  requestSuccess: false,
  data: null,
  tabsOrder: {},
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    data: action.data,
  };
}

function replaceOpportunityHandler(state, action) {
  return {
    ...state,
    data: action.data,
  };
}

function storeOpportunityTabsOrderHandler(state, action) {
  return { ...state, tabsOrder: action.data };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REPLACE_OPPORTUNITY]: replaceOpportunityHandler,
  [STORE_OPPORTUNITY_TABS_ORDER]: storeOpportunityTabsOrderHandler,
};

export default function opportunityReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
