import { create as createReducer } from '../../../core/redux-utils';
import { SHOW_MODAL, HIDE_MODAL, RESET_MODAL_STATE } from './constants';

const initialState = {
  visible: false,
};

function showModalActionHandler(state) {
  return { ...state, visible: true };
}

function hideModalActionHandler(state) {
  return { ...state, visible: false };
}

function resetModalActionHandler() {
  return { ...initialState };
}

const actionHandlers = {
  [SHOW_MODAL]: showModalActionHandler,
  [HIDE_MODAL]: hideModalActionHandler,
  [RESET_MODAL_STATE]: resetModalActionHandler,
};

export default createReducer(initialState, actionHandlers);
