import Box from '@mui/material/Box';
import styles from './CallMainActions.module.scss';
import AcceptCall from '../../AcceptCall';
import RejectCall from '../../RejectCall';

export default function CallMainActions() {
  return (
    <Box className={styles.container}>
      <AcceptCall />
      <RejectCall redirectToRecents />
    </Box>
  );
}
