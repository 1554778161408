import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { uniqBy } from 'lodash';
import { fetchSingleMessage } from './actions';

const initialState = {
  isFetching: false,
  data: [],
  phone: null,
  contact: null,
  chatId: null,
  drafts: [],
  meta: nullMeta,
  areThreadsFetching: false,
  shouldRefetch: false,
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addNewMessage: (state, { payload }) => {
      const response = { ...payload.text_message, user: payload.user };
      return {
        ...state,
        data: [...state.data, response],
      };
    },
    setChatId: (state, { payload }) => ({ ...state, chatId: payload }),
    setPhone: (state, { payload }) => ({ ...state, phone: payload }),
    setContact: (state, { payload }) => ({ ...state, contact: payload }),
    setDrafts: (state, { payload }) => ({ ...state, drafts: payload }),
    resetStatePartially: (state) => ({ ...initialState, drafts: state.drafts }),
    resetState: () => ({ ...initialState }),
    setThreadsLoading: (state, { payload }) => ({ ...state, areThreadsFetching: payload }),
    setShouldRefetch: (state, { payload }) => ({ ...state, shouldRefetch: payload }),
  },
  extraReducers: {
    [fetchSingleMessage.pending]: (state) => ({ ...state, isFetching: true }),
    [fetchSingleMessage.fulfilled]: (state, { payload }) => {
      const response = [
        ...payload.text_messages
          .map((m) => ({
            name: state.contact?.full_name,
            user: payload.user,
            ...m,
          }))
          .reverse(),
        ...state.data,
      ];
      const uniqueMessages = uniqBy(response, 'id');

      return {
        ...state,
        data: uniqueMessages,
        meta: parseMetaFromResponse(payload.meta),
        isFetching: false,
      };
    },
    [fetchSingleMessage.rejected]: (state) => ({ ...state, isFetching: false }),
  },
});

export const {
  addNewMessage,
  setChatId,
  setPhone,
  setDrafts,
  setContact,
  resetState,
  resetStatePartially,
  setThreadsLoading,
  setShouldRefetch,
} = messagesSlice.actions;

export default messagesSlice.reducer;
