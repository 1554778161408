import { parseFilterProperties, nullFilter, parseFilterPropertiesForSelect } from 'helpers/filters';
import { nullMeta } from 'helpers/meta';
import {
  STORE_FILTER_PROPERTIES,
  STORE_SAVED_FILTERS,
  SET_CURRENT_FILTERS,
  RESET_CURRENT_FILTERS,
  ADD_SAVED_FILTER,
  REMOVE_SAVED_FILTER,
  UPDATE_SAVED_FILTER,
  RESET_FILTERS_STATE,
} from './constants';
import {
  immutablePush,
  immutableInsert,
  immutableDelete,
} from '../../../../helpers/immutableHelper';

const initialState = {
  filterProperties: [],
  savedFilters: [],
  savedFiltersMeta: nullMeta,
  currentFilters: nullFilter,
  filterPropertiesForSelect: [],
};

function storeFilterPropertiesHandler(state, action) {
  const columns = Object.values(action.data).reduce((r, c) => Object.assign(r, c), {});
  return {
    ...state,
    columns,
    filterProperties: parseFilterProperties(columns),
    filterPropertiesForSelect: parseFilterPropertiesForSelect(action.data),
  };
}

function storeSavedFiltersHandler(state, action) {
  return {
    ...state,
    savedFilters: action.data,
    savedFiltersMeta: action.meta,
    currentFilters: nullFilter,
  };
}

function addSavedFilterHandler(state, action) {
  return {
    ...state,
    savedFilters: immutablePush(state.savedFilters, action.data),
  };
}

function removeSavedFilterHandler(state, action) {
  return {
    ...state,
    savedFilters: immutableDelete(
      state.savedFilters,
      state.savedFilters.findIndex((f) => f.id === action.id)
    ),
  };
}

function updateSavedFilterHandler(state, action) {
  return {
    ...state,
    savedFilters: immutableInsert(
      state.savedFilters,
      action.data,
      state.savedFilters.findIndex((f) => f.id === action.data.id)
    ),
  };
}

function setCurrentFiltersHandler(state, action) {
  return {
    ...state,
    currentFilters: action.data,
  };
}

function resetCurrentFiltersHandler(state) {
  return {
    ...state,
    currentFilters: initialState.currentFilters,
  };
}

function resetRemindersFiltersStateHandler() {
  return { ...initialState };
}

const ACTION_HANDLERS = {
  [STORE_FILTER_PROPERTIES]: storeFilterPropertiesHandler,
  [STORE_SAVED_FILTERS]: storeSavedFiltersHandler,
  [ADD_SAVED_FILTER]: addSavedFilterHandler,
  [REMOVE_SAVED_FILTER]: removeSavedFilterHandler,
  [UPDATE_SAVED_FILTER]: updateSavedFilterHandler,
  [SET_CURRENT_FILTERS]: setCurrentFiltersHandler,
  [RESET_CURRENT_FILTERS]: resetCurrentFiltersHandler,
  [RESET_FILTERS_STATE]: resetRemindersFiltersStateHandler,
};

export default function nestedFiltersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
