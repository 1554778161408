import { useSelector, useDispatch } from 'react-redux';
import { selectUserId } from 'state/user/selectors';
import {
  selectExchangeCredential,
  selectImapCredential,
  selectIsDeleting as selectAsDeletingCredential,
} from 'state/credentials/selectors';
import {
  selectGoogleToken,
  selectMicrosoftToken,
  selectIsDeleting as selectIsDeletingToken,
} from 'state/tokens/selectors';
import { deleteToken } from 'state/tokens/actions';
import { deleteCredential } from 'state/credentials/actions';

const useConnectedEmail = () => {
  const dispatch = useDispatch();
  const userID = useSelector(selectUserId);
  const googleToken = useSelector(selectGoogleToken);
  const microsoftToken = useSelector(selectMicrosoftToken);
  const imapCredential = useSelector(selectImapCredential);
  const exchangeCredential = useSelector(selectExchangeCredential);
  const isDeletingToken = useSelector(selectIsDeletingToken);
  const isDeletingCredential = useSelector(selectAsDeletingCredential);

  const deleteImapCredential = () => {
    dispatch(deleteCredential(imapCredential.id));
  };

  const deleteExchangeCredential = () => {
    dispatch(deleteCredential(exchangeCredential.id));
  };

  const deleteGoogleToken = () => {
    dispatch(deleteToken(userID, googleToken.id));
  };

  const deleteMicrosoftToken = () => {
    dispatch(deleteToken(userID, microsoftToken.id));
  };

  if (imapCredential) {
    return [imapCredential.username, deleteImapCredential, isDeletingCredential];
  }
  if (exchangeCredential) {
    return [exchangeCredential.username, deleteExchangeCredential, isDeletingCredential];
  }
  if (googleToken) {
    return [googleToken.email, deleteGoogleToken, isDeletingToken];
  }
  if (microsoftToken) {
    return [microsoftToken.email, deleteMicrosoftToken, isDeletingToken];
  }

  return [null, null, false];
};

export default useConnectedEmail;
