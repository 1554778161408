import PropTypes from 'prop-types';
import FilledInput from '@mui/material/FilledInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)({
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
  },
});

export default function CurrencyField({ label, currency, ...props }) {
  return (
    <StyledFormControl fullWidth variant="filled">
      <InputLabel htmlFor="filled-adornment-amount">{label}</InputLabel>
      <FilledInput
        {...props}
        type="number"
        startAdornment={<InputAdornment position="start">{currency}</InputAdornment>}
      />
    </StyledFormControl>
  );
}

CurrencyField.defaultProps = {
  currency: '$',
};

CurrencyField.propTypes = {
  label: PropTypes.string.isRequired,
  currency: PropTypes.string,
};
