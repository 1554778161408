import { getTicketsFilterProperties } from 'services/ticket';
import {
  getAll as getAllSavedFilters,
  create as createSavedFilter,
  update as updateSavedFilter,
  del as deleteSavedFilter,
} from 'services/saved-filters';
import { parseMetaFromResponse } from 'helpers/meta';
import { success as successAlert } from 'state/notifications/actions';
import {
  STORE_FILTER_PROPERTIES,
  STORE_SAVED_FILTERS,
  ADD_SAVED_FILTER,
  REMOVE_SAVED_FILTER,
  UPDATE_SAVED_FILTER,
  SET_CURRENT_FILTERS,
  RESET_CURRENT_FILTERS,
} from './constants';

function storeFilterProperties(data) {
  return {
    type: STORE_FILTER_PROPERTIES,
    data,
  };
}

export function fetchFilterProperties() {
  return async (dispatch, getState) => {
    const isCached = !!getState().tickets.filters.filterProperties.length;

    if (isCached) return Promise.resolve();

    const json = await getTicketsFilterProperties();
    dispatch(storeFilterProperties(json));
    return Promise.resolve();
  };
}

function storeSavedFilters(json) {
  return {
    type: STORE_SAVED_FILTERS,
    data: json.saved_filters,
    meta: parseMetaFromResponse(json.meta),
  };
}

export function fetchSavedFilters() {
  return async (dispatch, getState) => {
    const isCached = getState().tickets.filters.savedFiltersMeta.currentPage;

    if (isCached) return Promise.resolve();

    const json = await getAllSavedFilters({ per_page: 50, resource: 'ticket' });

    dispatch(storeSavedFilters(json));
    return Promise.resolve();
  };
}

export function addSavedFilter(data) {
  return {
    type: ADD_SAVED_FILTER,
    data,
  };
}

export function updateFilter(data) {
  return {
    type: UPDATE_SAVED_FILTER,
    data,
  };
}

export function setCurrentFilters(data) {
  return {
    type: SET_CURRENT_FILTERS,
    data,
  };
}

export function resetCurrentFilters(data) {
  return {
    type: RESET_CURRENT_FILTERS,
    data,
  };
}

export function saveFilter(data) {
  return (dispatch) =>
    createSavedFilter({
      resource: 'ticket',
      ...data,
    })
      .then((response) => {
        dispatch(addSavedFilter(response));
        dispatch(successAlert('Filter is saved.'));
        return Promise.resolve(response);
      })
      .catch((e) => Promise.reject(e));
}

export function removeSavedFilter(id) {
  return {
    type: REMOVE_SAVED_FILTER,
    id,
  };
}

export function deleteFilter(id) {
  return (dispatch) =>
    deleteSavedFilter(id)
      .then(() => {
        dispatch(removeSavedFilter(id));

        return Promise.resolve();
      })
      .catch((e) => Promise.reject(e));
}

export function editFilter(id, data) {
  return (dispatch) =>
    updateSavedFilter(id, data)
      .then((updatedFilter) => {
        dispatch(updateFilter(updatedFilter));
        return Promise.resolve(updatedFilter);
      })
      .catch((e) => Promise.reject(e));
}
