import Confetti from 'react-confetti';
import { useDispatch, useSelector } from 'react-redux';
import { hideConfetti } from 'state/opportunity-celebration/reducers';
import { selectShowConfetti } from 'state/opportunity-celebration/selectors';

export default function ConfettiCelebration() {
  const showConfetti = useSelector(selectShowConfetti);
  const dispatch = useDispatch();

  const onConfettiComplete = () => dispatch(hideConfetti());

  return (
    <>
      {showConfetti && (
        <Confetti
          style={{ zIndex: 9999 }}
          gravity={1}
          numberOfPieces={500}
          recycle={false}
          onConfettiComplete={onConfettiComplete}
        />
      )}
    </>
  );
}
