import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'lib/Button';
import Modal from 'ui/Modal';
import { resourceTypes } from 'app/constants';
import { AccountLookup, ContactLookup } from 'ui/FormControls';
import styles from './AddExistingResourceModal.module.scss';

export default function AddExistingResourceModal({ onSubmit, onHide, resource, show, contextId }) {
  const [result, setResult] = useState(null);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (result) {
      onSubmit(result.id);
      setResult(null);
    }
  };

  const onCancelClick = (e) => {
    e.preventDefault();
    onHide();
    setResult(null);
  };

  const onSelect = (res) => {
    setResult(res);
  };

  return (
    <Modal maxWidth="lg" open={show} onHide={onHide} PaperProps={{ className: styles.modal }}>
      <form onSubmit={handleOnSubmit}>
        <Modal.Header>
          Add Existing {resource === resourceTypes.CONTACT ? 'Contact' : 'Company'}
        </Modal.Header>
        <Modal.Body>
          {resource === resourceTypes.CONTACT && (
            <ContactLookup
              variant="outlined"
              placeholder="Search"
              onSelect={(item) => onSelect(item)}
              value={result ? result.name : ''}
              compactLookup={false}
              contextType="Opportunity"
              contextId={contextId}
            />
          )}
          {resource === resourceTypes.ACCOUNT && (
            <AccountLookup
              variant="outlined"
              placeholder="Search"
              onSelect={(item) => onSelect(item)}
              value={result ? result.name : ''}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCancelClick}>Cancel</Button>
          <Button type="submit" value="Submit" disabled={!result}>
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

AddExistingResourceModal.defaultProps = {
  resource: resourceTypes.CONTACT,
  contextId: null,
};

AddExistingResourceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resource: PropTypes.string,
  contextId: PropTypes.number,
};
