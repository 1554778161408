import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { ReactComponent as SpiroLogo } from '../../../images/spiro-logo.svg';
import styles from './ChatHeader.module.scss';

export default function ChatHeader({ setShouldClearState }) {
  const handleNewChat = () => setShouldClearState(true);

  return (
    <Box className={styles.container}>
      <Tooltip
        title="New Chat"
        classes={{ tooltip: 'spiro-tooltip', arrow: 'spiro-tooltip-arrow' }}
        placement="top"
        componentsProps={{
          tooltip: {
            sx: { margin: '0 !important' },
          },
        }}
      >
        <AddIcon className={styles.icon} onClick={handleNewChat} />
      </Tooltip>
      <SpiroLogo className={styles.logo} />
      <h6 className={styles.title}>AI chat assistant</h6>
    </Box>
  );
}

ChatHeader.propTypes = {
  setShouldClearState: PropTypes.func.isRequired,
};
