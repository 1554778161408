import { parseFilterProperties, nullFilter, parseFilterPropertiesForSelect } from 'helpers/filters';
import { nullMeta } from 'helpers/meta';
import {
  STORE_FILTER_PROPERTIES,
  STORE_SAVED_FILTERS,
  ADD_SAVED_FILTER,
  REMOVE_SAVED_FILTER,
  UPDATE_SAVED_FILTER,
  SET_CURRENT_FILTERS,
  RESET_CURRENT_FILTERS,
} from './constants';

const initialState = {
  filterProperties: [],
  savedFilters: [],
  savedFiltersMeta: nullMeta,
  currentFilters: nullFilter,
  filterPropertiesForSelect: [],
};

function storeFilterPropertiesHandler(state, action) {
  const columns = Object.values(action.data).reduce((r, c) => Object.assign(r, c), {});
  return {
    ...state,
    columns,
    filterProperties: parseFilterProperties(columns),
    filterPropertiesForSelect: parseFilterPropertiesForSelect(action.data),
  };
}

function storeSavedFiltersHandler(state, action) {
  return {
    ...state,
    savedFilters: action.data,
    savedFiltersMeta: action.meta,
  };
}

function addSavedFilterHandler(state, action) {
  return {
    ...state,
    savedFilters: [...state.savedFilters, action.data],
  };
}

function removeSavedFilterHandler(state, action) {
  return {
    ...state,
    savedFilters: state.savedFilters.filter((filter) => filter.id !== action.id),
  };
}

function updateSavedFilterHandler(state, action) {
  return {
    ...state,
    savedFilters: state.savedFilters.map((filter) => {
      if (filter.id === action.data.id) {
        return action.data;
      }
      return filter;
    }),
  };
}

function setCurrentFiltersHandler(state, action) {
  return {
    ...state,
    currentFilters: action.data,
  };
}

function resetCurrentFiltersHandler(state) {
  return {
    ...state,
    currentFilters: initialState.currentFilters,
  };
}

const ACTION_HANDLERS = {
  [STORE_FILTER_PROPERTIES]: storeFilterPropertiesHandler,
  [STORE_SAVED_FILTERS]: storeSavedFiltersHandler,
  [ADD_SAVED_FILTER]: addSavedFilterHandler,
  [REMOVE_SAVED_FILTER]: removeSavedFilterHandler,
  [UPDATE_SAVED_FILTER]: updateSavedFilterHandler,
  [SET_CURRENT_FILTERS]: setCurrentFiltersHandler,
  [RESET_CURRENT_FILTERS]: resetCurrentFiltersHandler,
};

export default function reducers(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
