import { Device } from 'twilio-client';
import { minimizeEmailModal } from 'modals/state/actions';
import twilioService from '../../../services/twilioService';
import { error as errorNotification } from '../../../state/notifications/actions';
import {
  TWILIO_ENABLE_DEVICE,
  TWILIO_SET_TOKEN,
  TWILIO_START_CALL,
  TWILIO_SETUP_INCOMING_CALL,
  TWILIO_HANG_UP_CALL,
  TWILIO_SET_CALL_INFO,
  TWILIO_ACCEPT_CALL,
  TWILIO_REJECT_INCOMING_CALL,
  TWILIO_SET_FORM_VALUES,
  TWILIO_RESET_STATE,
  TWILIO_INIT_LOG_ACTIVITY_FLOW,
  TWILIO_SET_ACCOUNT_CALL_SHOULD_START,
  TWILIO_STORE_TWILIO_DEVICE,
  TWILIO_DISABLE_DEVICE,
  TWILIO_MUTE_CALL,
  TWILIO_SET_CALLER_INFO,
  TWILIO_SET_CONTACT,
  TWILIO_SET_ACCOUNT,
  TWILIO_MONITOR_CALL_DURATION,
  TWILIO_TOGGLE_ADD_CONTACT_MODAL,
  TWILIO_TOGGLE_ADD_EXISTING_CONTACT_MODAL,
  TWILIO_DISPLAY_DIALPAD,
  TWILIO_TOGGLE_TRANSFER_CALL,
  TWILIO_SET_PHONE_NUMBER,
  TWILIO_RESET_STATE_PARTIALLY,
  TWILIO_SET_RINGING,
  TWILIO_SET_ACTIVE_CALL_MODAL_OPEN,
  TWILIO_SET_TICKET_ID,
  TWILIO_SET_RECORDING,
  TWILIO_SET_PHONE_DIALOG_OPEN,
  TWILIO_SET_NOTE_SCREEN_OPEN,
  TWILIO_SET_ACTIVE_TAB,
  TWILIO_CALL_MODAL_MINIMIZED,
  TWILIO_SET_CONVERSATION_SCREEN_OPEN,
  TWILIO_MODAL_MINIMIZED_BY_OTHER_ACTION,
  TWILIO_DISPLAY_SYSTEM_NOTIFICATION_FOR_CALL,
  TWILIO_SET_UPDATED_VOICEMAILS_IDS,
} from './constants';

const CONTACT_MATCHING_ERROR = 'Error while trying to match phone number.';

export function setTwilioDeviceEnabled(token) {
  return {
    type: TWILIO_ENABLE_DEVICE,
    token,
  };
}

export function setRecording(recording) {
  return {
    type: TWILIO_SET_RECORDING,
    recording,
  };
}

export function setTwilioDeviceDisabled() {
  return {
    type: TWILIO_DISABLE_DEVICE,
  };
}

export function setTwilioToken(token) {
  return {
    type: TWILIO_SET_TOKEN,
    token,
  };
}

export function setTwilioCaller(caller) {
  return {
    type: TWILIO_SET_CALLER_INFO,
    caller,
  };
}

export function setActiveCallModalOpen(isActiveCallModalOpen) {
  return {
    type: TWILIO_SET_ACTIVE_CALL_MODAL_OPEN,
    isActiveCallModalOpen,
  };
}

export function setPhoneDialogOpen(isPhoneDialogOpen) {
  return {
    type: TWILIO_SET_PHONE_DIALOG_OPEN,
    isPhoneDialogOpen,
  };
}

export function setTwilioContact(contact) {
  return {
    type: TWILIO_SET_CONTACT,
    contact,
  };
}

export function setTwilioAccount(account) {
  return {
    type: TWILIO_SET_ACCOUNT,
    account,
  };
}

export function toggleAddContactModal(visible) {
  return {
    type: TWILIO_TOGGLE_ADD_CONTACT_MODAL,
    visible,
  };
}

export function toggleAddExistingContactModal(visible) {
  return {
    type: TWILIO_TOGGLE_ADD_EXISTING_CONTACT_MODAL,
    visible,
  };
}

export function toggleTransferCall(transferCallVisible) {
  return {
    type: TWILIO_TOGGLE_TRANSFER_CALL,
    transferCallVisible,
  };
}

export function setPhoneNumber(phoneNumber) {
  return {
    type: TWILIO_SET_PHONE_NUMBER,
    phoneNumber,
  };
}

export function resetTwilioStatePartially() {
  return {
    type: TWILIO_RESET_STATE_PARTIALLY,
  };
}

// start outgoing call/connection
export function startCall(connection, params) {
  return {
    type: TWILIO_START_CALL,
    connection,
    params,
  };
}

// start incoming call/connection
export function setupIncomingCall(connection) {
  return {
    type: TWILIO_SETUP_INCOMING_CALL,
    connection,
  };
}

export function hangUpCall(connection) {
  return {
    type: TWILIO_HANG_UP_CALL,
    connection,
  };
}

export function setCallInfo(callers, caller = null) {
  return {
    type: TWILIO_SET_CALL_INFO,
    caller: caller || callers[0],
    callers,
  };
}

export function setFormValues(formValues) {
  return {
    type: TWILIO_SET_FORM_VALUES,
    formValues,
  };
}

export function acceptIncomingCall(connection) {
  return {
    type: TWILIO_ACCEPT_CALL,
    connection,
  };
}

export function rejectIncomingCall() {
  return (dispatch) => {
    dispatch({ type: TWILIO_REJECT_INCOMING_CALL });
  };
}

export function handleIncomingCallCancel() {
  return (dispatch) => {
    dispatch(rejectIncomingCall());
  };
}

export function resetTwilioState() {
  return { type: TWILIO_RESET_STATE };
}

export function initLogActivityFlow() {
  return { type: TWILIO_INIT_LOG_ACTIVITY_FLOW };
}

export function setAccountCallShouldStart(params) {
  return {
    type: TWILIO_SET_ACCOUNT_CALL_SHOULD_START,
    params,
  };
}

export function setRinging(ringing) {
  return {
    type: TWILIO_SET_RINGING,
    ringing,
  };
}

export function storeDevice(device) {
  return {
    type: TWILIO_STORE_TWILIO_DEVICE,
    device,
  };
}

export function setTicketId(ticketId) {
  return {
    type: TWILIO_SET_TICKET_ID,
    ticketId,
  };
}

export function minimizeCallModal(isModalMinimized) {
  return {
    type: TWILIO_CALL_MODAL_MINIMIZED,
    isModalMinimized,
  };
}

export function setModalMinimizedByOtherAction(modalMinimizedByOtherAction) {
  return {
    type: TWILIO_MODAL_MINIMIZED_BY_OTHER_ACTION,
    modalMinimizedByOtherAction,
  };
}

export function toggleDisplaySystemNotificationForCall(display) {
  return {
    type: TWILIO_DISPLAY_SYSTEM_NOTIFICATION_FOR_CALL,
    display,
  };
}

export function setUpdatedVoicemailsIDS(updatedVoicemailsIDS) {
  return {
    type: TWILIO_SET_UPDATED_VOICEMAILS_IDS,
    updatedVoicemailsIDS,
  };
}

export function searchForContact(phoneNumber) {
  return (dispatch, getState) =>
    twilioService
      .getCallers({ number: phoneNumber })
      .then((response) => {
        const callers = response.multiple_callers;
        const caller = getState().twilioCall.caller;
        const isEmailModalMinimized = getState().modalDialogs.isEmailModalMinimized;
        dispatch(setActiveCallModalOpen(true));
        if (!isEmailModalMinimized) dispatch(minimizeEmailModal());
        if (callers.length > 0) dispatch(setCallInfo(callers, caller));
      })
      .catch(() => {
        dispatch(errorNotification(CONTACT_MATCHING_ERROR));
      });
}

export function handleIncomingCall(connection) {
  return (dispatch) => {
    // rename to establish connection
    dispatch(setupIncomingCall(connection));

    twilioService
      .getCallers({ number: connection.parameters.From })
      .then((response) => {
        const callers = response.multiple_callers;

        if (callers.length > 0) dispatch(setCallInfo(callers));
        if (
          'Notification' in window &&
          !document.hasFocus() &&
          Notification.permission === 'granted'
        ) {
          dispatch(toggleDisplaySystemNotificationForCall(true));
        }
      })
      .catch(() => {
        dispatch(errorNotification(CONTACT_MATCHING_ERROR));
      });
  };
}

export function setupTwilioDevice() {
  return (dispatch) =>
    twilioService
      .createToken()
      .then((response) => {
        const options = {
          debug: true,
          codecPreferences: ['opus', 'pcmu'],
          fakeLocalDTMF: true,
          enableRingingState: true,
        };

        Device.setup(response.token, options);
        dispatch(setTwilioToken(response.token));
        dispatch(setTwilioDeviceEnabled());
        return Promise.resolve();
      })
      .catch((err) => {
        console.error(err);
      });
}

export function muteCall(isMuted) {
  return {
    type: TWILIO_MUTE_CALL,
    isMuted,
  };
}

export function incrementCallDurationBySecond() {
  return {
    type: TWILIO_MONITOR_CALL_DURATION,
  };
}

export function displayDialpad(isDialpadDisplayed) {
  return {
    type: TWILIO_DISPLAY_DIALPAD,
    isDialpadDisplayed,
  };
}

export function setNoteScreenOpen(isNoteScreenOpen) {
  return {
    type: TWILIO_SET_NOTE_SCREEN_OPEN,
    isNoteScreenOpen,
  };
}

export function setActiveTab(activeTab) {
  localStorage.setItem('phoneActiveTab', activeTab);
  return {
    type: TWILIO_SET_ACTIVE_TAB,
    activeTab,
  };
}

export function setConversationScreenOpen(isConversationScreenOpen) {
  return {
    type: TWILIO_SET_CONVERSATION_SCREEN_OPEN,
    isConversationScreenOpen,
  };
}
