import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchInput from 'lib/SearchInput';
import styles from './TransferCall.module.scss';
import TransferCallItem from './TransferCallItem';
import useTransferCall from './useTransferCall';
import { setNoteScreenOpen } from '../state/actions';

function TransferCall() {
  const dispatch = useDispatch();
  const { handleOnClick, handleSetSearchValue, handleClear, searchValue, filteredProfiles } =
    useTransferCall();

  const handleGoBack = () => {
    dispatch(setNoteScreenOpen(false));
    handleOnClick();
  };

  return (
    <>
      <div className={styles.header}>
        <ArrowBackIcon className={styles['arrow-back']} onClick={handleGoBack} />
        <Typography variant="h5">Transfer Call</Typography>
      </div>
      <Box className={styles.search}>
        <SearchInput
          onKeyPress={() => {}}
          onChange={handleSetSearchValue}
          onClear={handleClear}
          value={searchValue}
          data-cy="call-transfer-search"
        />
      </Box>
      <div className={styles.list}>
        <Box className={styles.subheading}>
          <Typography variant="body1">Available</Typography>
        </Box>
        <TransferCallItem profiles={filteredProfiles.available} />
        {filteredProfiles.available.length === 0 && (
          <Box>
            <Typography variant="body1">No available profiles.</Typography>
          </Box>
        )}
        {filteredProfiles.unavailable.length > 0 && (
          <Box className={styles.subheading}>
            <Typography variant="body1">Unavailable</Typography>
          </Box>
        )}
        <TransferCallItem profiles={filteredProfiles.unavailable} />
      </div>
    </>
  );
}

export default TransferCall;
