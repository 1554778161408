import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getFullName } from 'helpers/contacts';
import DateHelper from '../../../../helpers/DateHelper';
import styles from './VoicemailCard.module.scss';

export default function VoicemailDetails({ voicemail, extended }) {
  const callLog = voicemail.phone_call_log;

  return (
    <div className={styles.details}>
      <div className={styles.title}>
        {callLog.contact && (
          <span
            className={styles.link}
            role="button"
            tabIndex="0"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Link to={`/contacts/${callLog.contact.id}`} className="bold-500">
              {getFullName(callLog.contact)}
            </Link>
          </span>
        )}
        {callLog.account && (
          <span
            className={styles.link}
            role="button"
            tabIndex="0"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Link to={`/companies/${callLog.account.id}`} className="bold-500">
              {callLog.account.name}
            </Link>
          </span>
        )}
        {!callLog.contact && !callLog.account && (
          <span>{parsePhoneNumberFromString(callLog.from_number)?.formatInternational()}</span>
        )}
      </div>
      <div className={styles.subtitle}>
        {!extended && callLog.group_call && (
          <GroupsOutlinedIcon data-cy="voicemail-group-call-icon" fontSize="inherit" />
        )}
        {extended
          ? `${DateHelper.formatDate(
              voicemail.created_at,
              'MMM. D, YYYY'
            )} at ${DateHelper.formatDate(voicemail.created_at, 'hh:mm A')}`
          : parsePhoneNumberFromString(callLog.from_number)?.formatInternational()}
      </div>
    </div>
  );
}

VoicemailDetails.propTypes = {
  voicemail: PropTypes.object.isRequired,
  extended: PropTypes.bool.isRequired,
};
