import Moment from 'moment';
import Interaction from './Interaction';

class SmsMessage extends Interaction {
  constructor(data = {}) {
    super(data);

    this.createdAt = new Moment(data.created_at).format('D MMMM YYYY');
    this.uid = data.uid;
    this.smsType = data.sms_type || '';
    this.phoneType = data.phone_type || '';
    this.isRead = data.is_read || '';
    this.sentToWorkPhone = data.sent_to_work_phone || '';
    this.mediaUrl = data.media_url || [];
    this.confirmed = data.confirmed;
  }
}

export default SmsMessage;
