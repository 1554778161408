import { useSelector, useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from 'lib/Drawer';
import { selectIsOpen } from 'state/app-notifications/selectors';
import { hideNotificationsSlider } from 'state/app-notifications/actions';
import { ReactComponent as Icon } from './notifications-icon.svg';
import Notifications from './Notifications';
import styles from './AppNotifications.module.scss';

function AppNotifications() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);

  const closeSlider = () => {
    dispatch(hideNotificationsSlider());
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeSlider} className={styles.drawer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Icon />
          <Typography variant="h5">Notifications</Typography>
        </div>
        <IconButton aria-label="close" onClick={closeSlider} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.body}>
        <Notifications />
      </div>
    </Drawer>
  );
}

export default AppNotifications;
