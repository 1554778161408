import PropTypes from 'prop-types';
import ImportAllowedOnly from 'components/ImportAllowedOnly';
import UnauthorizedAccess from '../UnauthorizedAccess';

function ImportAllowedRoute({ children }) {
  return <ImportAllowedOnly fallback={<UnauthorizedAccess />}>{children}</ImportAllowedOnly>;
}

ImportAllowedRoute.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ImportAllowedRoute;
