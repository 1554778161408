import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined';
import Tabs from 'lib/Tabs';
import Tab from 'lib/Tab';
import TabPanel from 'lib/TabPanel';
import useTabs from 'hooks/useTabs';
import { error } from 'state/notifications/actions';
import { getRecents } from 'services/spiro-phone';
import { parseMetaFromResponse } from 'helpers/meta';
import useInfiniteScroll from 'app/hooks/useInfiniteScroll';
import { forEachError } from '../../../../helpers/errorHelper';
import RecentsCard from '../RecentsCard';
import { PusherContext } from '../../../contexts/PusherContext';
import styles from './Recents.module.scss';

export default function Recents() {
  const [selectedTab, selectTab] = useTabs();
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const bodyRef = useRef();
  const [createdMissedCall, setCreatedMissedCall] = useState();
  const { channel } = useContext(PusherContext);

  channel.bind('missed_call_created', (missedCall) => {
    setCreatedMissedCall(missedCall.object.phone_call_log);
  });

  channel.bind('missed_call_updated', (missedCall) => {
    const index = data.findIndex((call) => call.id === missedCall.object.phone_call_log.id);
    const newData = [...data];
    newData[index] = missedCall.object.phone_call_log;
    setData(newData);
  });

  useEffect(() => {
    if (createdMissedCall?.id) {
      setData([createdMissedCall, ...data]);
    }
  }, [createdMissedCall?.id]);

  const fetchData = (params) => {
    getRecents({ page: params.page, missed_call: selectedTab === 1 ? true : null })
      .then((res) => {
        const newData =
          res.meta.current_page === 1 ? res.phone_call_logs : [...data, ...res.phone_call_logs];
        const unigCalls = [...new Map(newData.map((item) => [item.id, item])).values()];
        setData(unigCalls);
        setMeta(parseMetaFromResponse(res.meta));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        forEachError(err.data, (e) => dispatch(error(e)));
      });
  };

  const { handleRef } = useInfiniteScroll(loading, meta, fetchData);

  const changeTab = (e, tab) => {
    selectTab(e, tab);
    setLoading(true);
  };

  useEffect(() => {
    setData([]);
    if (bodyRef.current) bodyRef.current.scroll(0, 0);
    fetchData({ page: 1 });
  }, [selectedTab]);

  return (
    <>
      <div className={styles.header}>
        <h5>Recents</h5>
      </div>
      <Tabs value={selectedTab} onChange={changeTab} className={styles.tabs}>
        <Tab label="All" />
        <Tab label="Missed" />
      </Tabs>
      <div className={styles.body} ref={bodyRef}>
        {!loading && !data.length && (
          <div className={styles['no-calls-body']}>
            <div className={styles['no-calls-icon']}>
              <PhoneDisabledOutlinedIcon fontSize="large" />
            </div>
            <p>No recent calls</p>
          </div>
        )}
        <TabPanel value={selectedTab} index={0}>
          <div>
            {data.map((item, index) => (
              <RecentsCard
                ref={data.length - 1 === index ? handleRef : null}
                key={item.id}
                recentCall={item}
              />
            ))}
          </div>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <div>
            {data.map((item, index) => (
              <RecentsCard
                ref={data.length - 1 === index ? handleRef : null}
                key={item.id}
                recentCall={item}
              />
            ))}
          </div>
        </TabPanel>
      </div>
    </>
  );
}
