import { nullMeta } from 'helpers/meta';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  SET_PARAMS,
  RESET_STATE,
  REPLACE_CONTACT,
  SET_SELECTION_MODEL,
  RESET_SELECTION_MODEL,
  SET_ALL_THAT_MATCH_FILTERS,
} from './constants';
import { immutableInsert } from '../../../../../helpers/immutableHelper';

const initialState = {
  getContactsInProgress: false,
  data: [],
  meta: nullMeta,
  params: {
    page: undefined,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
    query: undefined,
  },
  selectionModel: [],
  allContactsSelected: false,
  allThatMatchFiltersSelected: false,
};

function requestStartedHandler(state) {
  return { ...state, getContactsInProgress: true };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    getContactsInProgress: false,
    data: action.data,
    meta: action.meta,
  };
}

export function setParamsHandler(state, action) {
  return { ...state, params: { ...state.params, ...action.data } };
}

export function resetStateHandler(state) {
  return { ...state, ...initialState };
}

function replaceContactHandler(state, action) {
  return {
    ...state,
    data: immutableInsert(
      state.data,
      action.contact,
      state.data.findIndex((c) => c.id === action.contact.id)
    ),
  };
}

function setSelectionModelHandler(state, action) {
  return {
    ...state,
    selectionModel: action.selectionModel,
    allContactsSelected: action.allContactsSelected,
  };
}

function resetSelectionModelHandler(state) {
  return { ...state, selectionModel: [] };
}

function setAllThatMatchFiltersHandler(state, action) {
  return { ...state, allThatMatchFiltersSelected: action.allThatMatchFiltersSelected };
}

const ACTION_HANDLERS = {
  [REQUEST_STARTED]: requestStartedHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [SET_PARAMS]: setParamsHandler,
  [RESET_STATE]: resetStateHandler,
  [REPLACE_CONTACT]: replaceContactHandler,
  [SET_SELECTION_MODEL]: setSelectionModelHandler,
  [RESET_SELECTION_MODEL]: resetSelectionModelHandler,
  [SET_ALL_THAT_MATCH_FILTERS]: setAllThatMatchFiltersHandler,
};

export default function addContactsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
