import { createAsyncThunk } from '@reduxjs/toolkit';
import { error, success as successAlert } from 'state/notifications/actions';
import { setShowConfetti } from 'state/opportunity-celebration/reducers';
import { update as updateCompany } from 'services/accounts';
import { updateContact } from 'services/contacts';
import { updateOpportunity } from 'services/opportunities';
import { updateTicket } from 'services/ticket';
import { getRuleResources } from 'services/assistant';
import { ignoreRule } from 'services/engine-rules';
import { updateQuote } from 'services/qoutes';
import { updateOrder } from 'services/orders';
import { flattenObject } from 'helpers/utils';
import { forEachError } from '../../../../../helpers/errorHelper';

const updateDataFunction = {
  Account: updateCompany,
  Contact: updateContact,
  Opportunity: updateOpportunity,
  Ticket: updateTicket,
  Quote: updateQuote,
  SalesOrder: updateOrder,
};

export const fetchRuleResources = createAsyncThunk(
  'assistant-rules/get',
  async (params = {}, { dispatch, getState }) => {
    try {
      const res = await getRuleResources(params);

      return {
        data:
          res.meta.current_page === 1
            ? res.resources.map((i) => flattenObject(i))
            : [...getState().assistantRules.data, ...res.resources.map((i) => flattenObject(i))],
        meta: res.meta,
      };
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const updateData = createAsyncThunk('assistant-rules/update', async (data, { dispatch }) => {
  const { id, payload, resource } = data;
  try {
    const res = await updateDataFunction[resource](id, payload);
    if (resource === 'Opportunity') {
      dispatch(setShowConfetti({ requestPayload: payload, response: res.opportunity }));
    }

    const response = {
      data: res[resource === 'SalesOrder' ? 'sales_order' : resource.toLowerCase()],
    };

    return response;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const handleSnoozeRule = createAsyncThunk(
  'engine-rules-snooze',
  async (payload, { dispatch }) => {
    const { id, data } = payload;
    try {
      const res = await ignoreRule(id, data);

      dispatch(successAlert('Alert snoozed for 7 days.'));
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
