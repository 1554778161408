import PropTypes from 'prop-types';
import SearchInput from 'lib/SearchInput';
import useListSearch from 'hooks/useListSearch';
import styles from './SearchMessages.module.scss';

function SearchMessages({ fetchData }) {
  const { searchQuery, handleSearchChange, handleSearchEnterPress, handleSearchClear } =
    useListSearch(fetchData);

  return (
    <div className={styles.wrapper}>
      <SearchInput
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyPress={handleSearchEnterPress}
        onClear={handleSearchClear}
        fullWidth
      />
    </div>
  );
}

SearchMessages.propTypes = {
  fetchData: PropTypes.func.isRequired,
};

export default SearchMessages;
