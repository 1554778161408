import { useSelector } from 'react-redux';

function SupportDeskProductOnly({ children = null, fallback = null }) {
  const userHasSupportDeskAsProduct = useSelector(
    (state) => !!state.user.addons.find((p) => p.name === 'Support Desk')
  );

  if (userHasSupportDeskAsProduct) return children;

  return fallback || null;
}

export default SupportDeskProductOnly;
