import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectIsAdmin, selectCanDoImport } from 'state/user/selectors';

function ImportAllowedOnly({ children, fallback = null }) {
  const userIsAdmin = useSelector(selectIsAdmin);
  const userCanDoImport = useSelector(selectCanDoImport);

  if (userIsAdmin || userCanDoImport) return children;

  return fallback || null;
}

ImportAllowedOnly.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ImportAllowedOnly;
