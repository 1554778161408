import { useSelector } from 'react-redux';

function SmartProductsOnly({ children = null, fallback = null }) {
  const userHasSmartProductsAsAddons = useSelector(
    (state) => !!state.user.addons.find((p) => p.name === 'Smart Quotes')
  );

  if (userHasSmartProductsAsAddons) return children;

  return fallback || null;
}

export default SmartProductsOnly;
