import {
  SHOW_NOTIFICATIONS_SLIDER,
  HIDE_NOTIFICATIONS_SLIDER,
  STORE_ALERT_NOTIFICATIONS,
  SET_SHOULD_REFETCH_ALERTS,
  ADD_UNREAD_NOTIFICATION,
} from './constants';

export const showNotificationsSlider = () => ({ type: SHOW_NOTIFICATIONS_SLIDER });
export const hideNotificationsSlider = () => ({ type: HIDE_NOTIFICATIONS_SLIDER });

export const storeAlertNotifications = (alerts) => ({
  type: STORE_ALERT_NOTIFICATIONS,
  payload: { alerts },
});

export const setShouldRefetchAlerts = () => ({
  type: SET_SHOULD_REFETCH_ALERTS,
});

export const addUnreadNotification = (notification) => ({
  type: ADD_UNREAD_NOTIFICATION,
  payload: { notification },
});
