import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { immutableSplice } from '../../../../helpers/immutableHelper';
import { fetchOrders, updateOrder } from './actions';

const initialState = {
  fetching: false,
  data: [],
  meta: nullMeta,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    query: undefined,
  },
  recordsCount: 0,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrdersCount: (state, { payload }) => ({ ...state, recordsCount: payload }),
    replaceOrderInList: (state, { payload }) => {
      const index = state.data.findIndex((o) => o.id === payload.id);
      return { ...state, data: immutableSplice(state.data, index, 1, payload) };
    },
  },
  extraReducers: {
    [fetchOrders.pending]: (state) => ({ ...state, fetching: true }),
    [fetchOrders.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.sales_orders,
      meta: parseMetaFromResponse(payload.meta),
      params: payload.params,
      fetching: false,
    }),
    [fetchOrders.rejected]: (state) => ({ ...state, fetching: false }),
    [updateOrder.fulfilled]: (state, { payload }) => ({
      ...state,
      data: state.data.map((order) => {
        if (order.id !== payload.id) return order;
        return payload;
      }),
    }),
  },
});

export const { setOrdersCount, replaceOrderInList } = ordersSlice.actions;

export default ordersSlice.reducer;
