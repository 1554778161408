import { createSlice } from '@reduxjs/toolkit';
import { fetchSupportDesk, updateSupportDesk } from './actions';

const initialState = {
  data: null,
  fetching: false,
};

export const supportDeskSlice = createSlice({
  name: 'support-desk',
  initialState,
  extraReducers: {
    [fetchSupportDesk.pending]: (state) => ({ ...state, fetching: true }),
    [fetchSupportDesk.fulfilled]: (state, { payload }) => ({
      ...state,
      fetching: false,
      data: payload.support_desk,
    }),
    [fetchSupportDesk.rejected]: (state) => ({ ...state, fetching: false }),
    [updateSupportDesk.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.support_desk,
    }),
  },
});

export default supportDeskSlice.reducer;
