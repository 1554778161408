import PropTypes from 'prop-types';
import styles from './DateHeader.module.scss';

function DateHeader({ date }) {
  return <p className={styles.header}>{date}</p>;
}

DateHeader.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateHeader;
