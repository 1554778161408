import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import TransferCall from 'components/CallDialog/TransferCall';
import LogActivityForm from '../CallDialogModal/LogActivityForm';
import InCallContactDetails from '../InCallContactDetails';
import {
  selectCallInteractedAt,
  selectIsTransferCallVisible,
  selectPhoneNumber,
  selectTwilioCaller,
  selectTwilioCallers,
  selectTwilioConnection,
} from '../state/selectors';
import useCallActions from '../CallDialogModal/CallMainActions/useCallActions';
import AfterCallActions from '../CallDialogModal/AfterCallActions';
import styles from './NoteScreen.module.scss';

const BlackTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',
    marginBottom: '0px !important',
  },
}));

function NoteScreen() {
  const isTransferCallVisible = useSelector(selectIsTransferCallVisible);
  const { toggleNoteScreen, setReminder, sendEmail, contact } = useCallActions();
  const caller = useSelector(selectTwilioCaller);
  const connection = useSelector(selectTwilioConnection);
  const interactedAt = useSelector(selectCallInteractedAt);
  const callers = useSelector(selectTwilioCallers);
  const phoneNumber = useSelector(selectPhoneNumber);

  const getCallerTitle = () => {
    if (caller) return caller.name;
    if (callers.length > 1) return 'Multiple Callers';
    return parsePhoneNumberFromString(phoneNumber)?.formatInternational();
  };

  if (isTransferCallVisible) return <TransferCall />;

  return (
    <div>
      <div className={connection ? styles.header : styles['actions-header']}>
        {connection ? (
          <>
            <IconButton size="large" onClick={toggleNoteScreen}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">{getCallerTitle()}</Typography>
          </>
        ) : (
          <>
            <InCallContactDetails
              title={getCallerTitle()}
              subheader={interactedAt}
              avatarImage={caller?.photo_url}
            />
            <div>
              <BlackTooltip title="Set Reminder" placement="top">
                <IconButton onClick={setReminder} disabled={!caller} className={styles.button}>
                  <AccessAlarmsIcon className={caller && styles.icon} />
                </IconButton>
              </BlackTooltip>
              <BlackTooltip title="Send Email" placement="top-end">
                <IconButton
                  onClick={sendEmail}
                  disabled={!caller || !contact || !contact.email}
                  className={styles.button}
                >
                  <MailOutlineIcon className={caller && contact && contact.email && styles.icon} />
                </IconButton>
              </BlackTooltip>
            </div>
          </>
        )}
      </div>
      {!caller && <AfterCallActions />}
      <LogActivityForm show={!isTransferCallVisible} />
    </div>
  );
}

export default NoteScreen;
