import { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './DragAndDropArea.module.scss';

export default function DragAndDropArea({
  uploadFile,
  fullWidth,
  saveAttachments,
  isDragging,
  setIsDragging,
}) {
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [numberOfSelectedFiles, setNumberOfSelectedFiles] = useState(0);
  return (
    <div
      className={`${isDragging ? styles['dragging-active'] : styles['dragging-inactive']} ${
        fullWidth && isDragging ? styles['with-border'] : styles['without-border']
      }`}
      onDragLeave={() => setIsDragging(false)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        setAttachmentLoading(true);
        const { files } = e.dataTransfer;
        setNumberOfSelectedFiles(Object.keys(files).length);
        Promise.all(Object.keys(files).map((key) => uploadFile(files[key]))).then((res) => {
          let uploadedAttachments = [];
          res.forEach(({ data }) => {
            uploadedAttachments = [...uploadedAttachments, data];
            const overflowBox = document.querySelector('.tox-toolbar__overflow');

            if (overflowBox) {
              const top = parseInt(overflowBox.style.top, 10) - 55;
              overflowBox.style.top = `${top}px`;
            }
          });
          saveAttachments(uploadedAttachments);
          setAttachmentLoading(false);
          setIsDragging(false);
        });
      }}
    >
      {isDragging && !attachmentLoading ? 'Drop files here' : ''}
      {attachmentLoading && (
        <div>
          <p>Uploading attachment{numberOfSelectedFiles > 1 ? 's' : ''}</p>
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
}

DragAndDropArea.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  saveAttachments: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  setIsDragging: PropTypes.func.isRequired,
};
