export const selectCallMuted = (state) => state.twilioCall.isMuted;
export const selectCallDuration = (state) => state.twilioCall.callDuration;
export const selectCallTimer = (state) => state.twilioCall.isTimerStarted;
export const selectAddContactModalVisible = (state) => state.twilioCall.addContactModalVisible;
export const selectDisplayDialpad = (state) => state.twilioCall.isDialpadDisplayed;
export const selectShowToastMessage = (state) => state.twilioCall.isAlertDisplayed;
export const selectToastMessage = (state) => state.twilioCall.alertMessage;
export const selectIsTransferCallVisible = (state) => state.twilioCall.transferCallVisible;
export const selectLogActivityAfterCall = (state) => state.twilioCall.logInteraction;
export const selectIsIncomingCall = (state) => state.twilioCall.incomingCall;
export const selectIsOutgoingCall = (state) => state.twilioCall.outgoingCall;
export const selectPreviousCall = (state) => state.twilioCall.previousCall;
export const selectTwilioEnabled = (state) => state.twilioCall?.twilioEnabled;
export const selectTwilioConnection = (state) => state.twilioCall.connection;
export const selectTwilioCaller = (state) => state.twilioCall?.caller;
export const selectTwilioCallers = (state) => state.twilioCall?.callers;
export const selectPhoneNumber = (state) => state.twilioCall?.phoneNumber;
export const selectFormValues = (state) => state.twilioCall?.formValues;
export const selectActiveCallModalOpen = (state) => state.twilioCall?.isActiveCallModalOpen;
export const selectTwilioContact = (state) => state.twilioCall?.contact;
export const selectTwilioAccount = (state) => state.twilioCall?.account;
export const selectIsRinging = (state) => state.twilioCall.ringing;
export const selectTicketId = (state) => state.twilioCall.ticketId;
export const selectIsRecordingEnabled = (state) => state.twilioCall.recording;
export const selectIsPhoneDialogOpen = (state) => state.twilioCall.isPhoneDialogOpen;
export const selectAddExistingContactModalVisible = (state) =>
  state.twilioCall.addExistingContactModalVisible;
export const selectIsNoteScreenVisible = (state) => state.twilioCall.isNoteScreenOpen;
export const selectIsCallLogged = (state) => state.twilioCall.isCallLogged;
export const selectCallInteractedAt = (state) => state.twilioCall.interactedAt;
export const selectActiveTab = (state) => state.twilioCall.activeTab;
export const selectCallModalMinimized = (state) => state.twilioCall.isModalMinimized;
export const selectIsConversationScreenVisible = (state) =>
  state.twilioCall.isConversationScreenOpen;
export const selectIsModalMinimizedByOtherAction = (state) =>
  state.twilioCall.modalMinimizedByOtherAction;
export const selectDisplayNotificationForCall = (state) =>
  state.twilioCall.displaySystemNotificationForCall;
export const selectUpdatedVoicemailsIDS = (state) => state.twilioCall.updatedVoicemailsIDS;
