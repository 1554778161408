import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  selectHasSalesOrdersEnabled,
  selectHasSmartProductsEnabled,
  selectHasSupportDeskEnabled,
} from 'state/user/selectors';
import {
  closeListsMenu,
  toggleSidebarMenu,
  toggleSidebarMenuWithoutLocalStorage,
} from 'state/app/reducer';
import { selectIsListMenuVisible, selectIsSidebarMenuCollapsed } from 'state/app/selectors';
import SidebarItem from './SidebarItem';
import ListsMenu from '../ListsMenu';
import ListsMenuItems from '../ListsMenuItems';
import styles from './SideBar.module.scss';

export default function SideBar() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathnameMatchesSettings = pathname.startsWith('/settings/');
  const quotesEnabled = useSelector(selectHasSmartProductsEnabled);
  const ordersEnabled = useSelector(selectHasSalesOrdersEnabled);
  const ticketsEnabled = useSelector(selectHasSupportDeskEnabled);
  const isSidebarCollapsed = useSelector(selectIsSidebarMenuCollapsed);
  const listsMenuVisible = useSelector(selectIsListMenuVisible);
  const isSidebarCollapsedLocalStorage = localStorage.getItem('sidebarMenuCollapsed');
  const fields = [
    { name: 'assistant', divider: false, visible: true },
    { name: 'reminders', divider: false, visible: true },
    { name: 'activities', divider: false, visible: true },
    { name: 'companies', divider: true, visible: true },
    { name: 'contacts', divider: false, visible: true },
    { name: 'opportunities', divider: false, visible: true },
    { name: 'quotes', divider: false, visible: quotesEnabled },
    { name: 'orders', divider: false, visible: ordersEnabled },
    { name: 'campaigns', divider: true, visible: true },
    { name: 'tickets', divider: false, visible: ticketsEnabled },
    { name: 'reports', divider: false, visible: true },
  ];

  const toggleSidebar = () => {
    dispatch(toggleSidebarMenu());
    if (listsMenuVisible) dispatch(closeListsMenu());
  };

  useEffect(() => {
    if (pathnameMatchesSettings && !isSidebarCollapsed) {
      dispatch(toggleSidebarMenuWithoutLocalStorage());
    }
    if (
      !pathnameMatchesSettings &&
      isSidebarCollapsed.toString() !== isSidebarCollapsedLocalStorage
    ) {
      dispatch(toggleSidebarMenuWithoutLocalStorage());
    }
  }, [pathnameMatchesSettings]);

  useEffect(() => {
    if (
      !pathnameMatchesSettings &&
      !listsMenuVisible &&
      isSidebarCollapsed.toString() !== isSidebarCollapsedLocalStorage
    ) {
      dispatch(toggleSidebarMenuWithoutLocalStorage());
    }
    if (!pathnameMatchesSettings && !isSidebarCollapsed && listsMenuVisible) {
      dispatch(toggleSidebarMenuWithoutLocalStorage());
    }
  }, [listsMenuVisible]);

  return (
    <div className={styles.container}>
      <div className={isSidebarCollapsed ? styles['side-bar-small'] : styles['side-bar']}>
        {fields
          .filter((field) => field.visible)
          .map((field) => (
            <SidebarItem key={field.name} field={field} />
          ))}
        <ListsMenu />
        {!pathnameMatchesSettings && (
          <div role="button" tabIndex={0} className={styles.toggle} onClick={toggleSidebar}>
            {isSidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </div>
        )}
      </div>
      {listsMenuVisible && (
        <div className={styles['lists-menu']}>
          <ListsMenuItems />
        </div>
      )}
    </div>
  );
}
