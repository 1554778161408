import Draggable from 'react-draggable';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import PhoneDialogSidebar from '../PhoneDialogSidebar';
import PhoneDialogContent from '../PhoneDialogContent';
import { selectActiveTab } from '../state/selectors';
import PhoneDialogHeader from '../PhoneDialogHeader';
import styles from './PhoneDialog.module.scss';

export default function PhoneDialog() {
  const activeTab = useSelector(selectActiveTab);

  return (
    <Draggable zIndex={100} enableUserSelectHack={false} handle="section" bounds="parent">
      <Box className={styles['make-call-dialog']}>
        <PhoneDialogHeader />
        <PhoneDialogSidebar activeItem={activeTab} />
        <PhoneDialogContent activeItem={activeTab} />
      </Box>
    </Draggable>
  );
}
