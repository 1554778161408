import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import BarChartIcon from '@mui/icons-material/BarChart';
import Typography from '@mui/material/Typography';
import { selectZohoEmail, selectReportsLink, selectZohoZuid } from 'state/user/selectors';
import { selectIsSidebarMenuCollapsed } from 'state/app/selectors';
import SpiroTooltip from 'components/SpiroTooltip';
import { openReportsUrl } from '../helpers';
import styles from './ReportsLink.module.scss';

function ReportsLink({ name, label }) {
  const zohoZuid = useSelector(selectZohoZuid);
  const zohoEmail = useSelector(selectZohoEmail);
  const reportsLink = useSelector(selectReportsLink);
  const isSidebarCollapsed = useSelector(selectIsSidebarMenuCollapsed);

  function handleClick() {
    openReportsUrl(zohoZuid, { zohoEmail, reportsLink });
  }

  return (
    <div
      role="button"
      tabIndex="0"
      key={name}
      onClick={handleClick}
      className={styles['sidebar-item']}
      data-cy="sidebar-menu-reports-button"
    >
      <SpiroTooltip title={isSidebarCollapsed ? label : ''} placement="top" disableInteractive>
        <BarChartIcon className={styles.icon} data-cy="sidebar-reports-icon" />
      </SpiroTooltip>
      <Typography className={styles.subheading} data-cy="sidebar-menu-reports-label">
        {label}
      </Typography>
    </div>
  );
}

ReportsLink.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default ReportsLink;
