import { useDispatch } from 'react-redux';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import Button from 'lib/Button';
import {
  minimizeCallModal,
  setModalMinimizedByOtherAction,
  setPhoneDialogOpen,
  toggleAddContactModal,
  toggleAddExistingContactModal,
} from '../../state/actions';
import styles from './AfterCallActions.module.scss';

function AfterCallActions() {
  const dispatch = useDispatch();

  const handleMinimizeCallModal = () => {
    dispatch(minimizeCallModal(true));
    dispatch(setPhoneDialogOpen(false));
    dispatch(setModalMinimizedByOtherAction(true));
  };

  const createContact = () => {
    dispatch(toggleAddContactModal(true));
    handleMinimizeCallModal();
  };

  const selectExistingContact = () => {
    dispatch(toggleAddExistingContactModal(true));
    handleMinimizeCallModal();
  };

  return (
    <div className={styles.wrapper}>
      <Button
        variant="outlined"
        color="primary"
        onClick={createContact}
        startIcon={<PersonAddOutlinedIcon />}
      >
        Create Contact
      </Button>
      <Button
        variant="outlined"
        color="primary"
        onClick={selectExistingContact}
        startIcon={<HowToRegOutlinedIcon />}
      >
        Select Existing Contact
      </Button>
    </div>
  );
}

export default AfterCallActions;
