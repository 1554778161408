import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { removeByUid } from '../../state/notifications/actions';
import Notifications from './Notifications';

function mapStateToProps(state) {
  return {
    notifications: state.notifications.items,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeByUid,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
