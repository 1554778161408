import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import {
  fetchSmartProducts,
  handleCreateSmartProduct,
  updateSmartProduct,
  fetchConfigurations,
} from './actions';

const initialState = {
  fetching: false,
  data: [],
  isCreateInProgress: false,
  createModalOpen: false,
  configurations: {
    billing_frequencies: [],
    categories: [],
    unit_types: [],
  },
  meta: nullMeta,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    query: undefined,
  },
};

export const smartProductsSlice = createSlice({
  name: 'smart-products',
  initialState,
  reducers: {
    clearState: () => ({ ...initialState }),
    toogleNewProductModal: (state) => ({ ...state, createModalOpen: !state.createModalOpen }),
  },
  extraReducers: {
    [fetchSmartProducts.pending]: (state) => ({ ...state, fetching: true }),
    [fetchSmartProducts.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.products,
      meta: parseMetaFromResponse(payload.meta),
      params: payload.params,
      fetching: false,
    }),
    [fetchSmartProducts.rejected]: (state) => ({ ...state, fetching: false }),
    [handleCreateSmartProduct.pending]: (state) => ({ ...state, isCreateInProgress: true }),
    [handleCreateSmartProduct.fulfilled]: (state) => ({
      ...state,
      isCreateInProgress: false,
      createModalOpen: false,
    }),
    [fetchConfigurations.fulfilled]: (state, { payload }) => ({
      ...state,
      configurations: payload,
    }),
    [handleCreateSmartProduct.rejected]: (state) => ({ ...state, isCreateInProgress: false }),
    [updateSmartProduct.fulfilled]: (state, { payload }) => ({
      ...state,
      data: state.data.map((product) => {
        if (product.id === payload.product.id) return payload.product;
        return product;
      }),
    }),
  },
});

export const { clearState, toogleNewProductModal } = smartProductsSlice.actions;

export default smartProductsSlice.reducer;
