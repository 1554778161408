import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getContactsWithPhoneNumbers, getMessages } from 'services/spiro-phone';
import { error } from 'state/notifications/actions';
import useDebounce from 'hooks/useDebounce';
import { removePhoneNumberFormatting } from 'helpers/twilio';
import { setConversationScreenOpen } from '../../../state/actions';
import { parseMetaFromResponse } from '../../../../../helpers/meta';
import {
  resetStatePartially,
  setChatId,
  setContact,
  setPhone,
  setThreadsLoading,
} from '../state/reducer';
import { forEachError } from '../../../../../../helpers/errorHelper';

const useNewConversation = () => {
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [meta, setMeta] = useState(null);
  const [reqParams, setReqParams] = useState({
    page: 0,
    per_page: 20,
    query: '',
  });

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const getContacts = (params) => {
    setLoading(true);
    getContactsWithPhoneNumbers(params)
      .then((res) => {
        setContacts(params.page === 1 ? res.contacts : [...contacts, ...res.contacts]);
        setMeta(parseMetaFromResponse(res.meta));
      })
      .catch((err) => forEachError(err.data, (e) => dispatch(error(e))))
      .finally(() => setLoading(false));
  };

  const handleGetContacts = (nextPage) => {
    getContacts({ ...reqParams, query: searchTerm, page: nextPage.page });
  };

  const getData = (params, contact = null) => {
    setLoading(true);
    dispatch(setThreadsLoading(true));
    getMessages(params)
      .then((res) => {
        dispatch(setContact(res.text_message_threads[0]?.contact || contact));
        dispatch(setPhone(params.phone_number || res.text_message_threads[0]?.phone));
        dispatch(setConversationScreenOpen(true));
        dispatch(setChatId(res.text_message_threads[0]?.id || 'unknown'));
      })
      .catch((err) => forEachError(err.data, (e) => dispatch(error(e))))
      .finally(() => {
        setLoading(false);
        dispatch(setThreadsLoading(false));
      });
  };

  const handleSearch = (query, page = 1) => {
    setSearchTerm(query);
    setReqParams({ ...reqParams, query, page });
  };

  useEffect(() => {
    if (reqParams.page) getContacts(reqParams);
  }, [debouncedSearchTerm]);

  const onSelect = (payload, contact) => {
    getData(payload, contact);
  };

  const toggleConversationScreen = () => {
    dispatch(setConversationScreenOpen(false));
    dispatch(setChatId(null));
  };

  const checkIfPhoneNumber = (num) => {
    const pattern = /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;
    return pattern.test(num);
  };

  const handleOnBlur = () => {
    if (!contacts.length && checkIfPhoneNumber(reqParams.query)) {
      const { query } = reqParams;
      dispatch(resetStatePartially());
      const phoneNumber = query[0] === '+' ? query : `+1${query}`;
      getData({ phone_number: removePhoneNumberFormatting(phoneNumber) });
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter') handleOnBlur();
  };

  return {
    loading,
    contacts,
    meta,
    searchTerm,
    handleSearch,
    onSelect,
    handleOnBlur,
    handleGetContacts,
    toggleConversationScreen,
    onKeyPress,
    getData,
  };
};

export default useNewConversation;
