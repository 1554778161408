import { useState } from 'react';
import PropTypes from 'prop-types';
import AttachmentContext from './AttachmentContext';

export default function AttachmentContextProvider({ children }) {
  const [label, setLabel] = useState('');
  const [fileUrls, setFileUrls] = useState('');
  const [uploading, setUploading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  return (
    <AttachmentContext.Provider
      value={{
        label,
        setLabel,
        fileUrls,
        setFileUrls,
        uploading,
        setUploading,
        editMode,
        setEditMode,
      }}
    >
      {children}
    </AttachmentContext.Provider>
  );
}

AttachmentContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
