import { create as createReducer } from '../../../core/redux-utils';
import { SHOW_MODAL, HIDE_MODAL } from './constants';

const initialState = {
  visible: false,
  customFieldName: null,
};

function showModal(state, action) {
  return { ...state, customFieldName: action.customFieldName, visible: true };
}

function hideModal(state) {
  return { ...state, visible: false };
}

const actionHandlers = {
  [SHOW_MODAL]: showModal,
  [HIDE_MODAL]: hideModal,
};

export default createReducer(initialState, actionHandlers);
