import { createSlice } from '@reduxjs/toolkit';
import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { fetchRuleResources, updateData, handleSnoozeRule } from './actions';

const initialState = {
  data: [],
  meta: nullMeta,
  fetching: false,
  isSnoozeInProgress: false,
};

export const assistantRulesSlice = createSlice({
  name: 'assistant-rules',
  initialState,
  reducers: {
    resetState: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchRuleResources.pending]: (state) => ({ ...state, fetching: true }),
    [fetchRuleResources.fulfilled]: (state, { payload }) => ({
      ...state,
      fetching: false,
      data: payload.data,
      meta: parseMetaFromResponse(payload.meta),
    }),
    [fetchRuleResources.rejected]: (state) => ({ ...state, fetching: false }),
    [updateData.fulfilled]: (state, { payload }) => {
      const newData = state.data.map((item) => {
        if (item.id === payload.data.id) {
          return { ...item, ...payload.data };
        }
        return item;
      });

      return {
        ...state,
        data: newData,
      };
    },
    [handleSnoozeRule.pending]: (state) => ({ ...state, isSnoozeInProgress: true }),
    [handleSnoozeRule.fulfilled]: (state, { payload }) => {
      const newData = state.data.filter((i) => i.id !== payload.resource_id);

      return {
        ...state,
        data: newData,
        isSnoozeInProgress: false,
      };
    },
  },
});

export const { resetState } = assistantRulesSlice.actions;

export default assistantRulesSlice.reducer;
