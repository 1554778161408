import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, memo } from 'react';
import MessageItem from './MessageItem';
import styles from './ChatMessagesList.module.scss';

const loadingMessages = [
  'Spiro is gathering info... Hold up!',
  'Spiro is on a quick search for your info...',
  'Spiro is on it...',
];

const ChatMessagesList = memo(({ messages, isLoading }) => {
  const scrollableDivRef = useRef(null);

  const handleScrollToBottom = useCallback(() => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTo({
        top: scrollableDiv.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    const mutationObserver = new MutationObserver(handleScrollToBottom);

    if (scrollableDiv) {
      mutationObserver.observe(scrollableDiv, { childList: true, subtree: true });
    }

    return () => {
      if (scrollableDiv) {
        mutationObserver.disconnect();
      }
    };
  }, [handleScrollToBottom, messages]);

  useEffect(() => {
    handleScrollToBottom();
  }, [messages, handleScrollToBottom]);

  return (
    <div className={styles['grid-container']} style={{ overflow: 'auto' }} ref={scrollableDivRef}>
      <div className={styles['grid-item']}>
        {messages.map((message) => (
          <MessageItem key={message.timestamp} message={message} />
        ))}
        {isLoading && (
          <div className={styles.loading}>{loadingMessages[Math.floor(Math.random() * 3)]}</div>
        )}
      </div>
    </div>
  );
});

export default ChatMessagesList;

ChatMessagesList.propTypes = {
  messages: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
