import each from 'lodash/each';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { htmlParser } from 'helpers/html';

function blank() {
  return {
    userId: undefined,
    title: '',
    description: '',
    subject: '',
    body: '',
    htmlBody: '',
    actionGroup: '',
    order: null,
    createdAt: null,
    updatedAt: null,
    public: false,
    userIds: [],
    attachments: [],
  };
}

function parse(data = {}) {
  return {
    id: data.id || undefined,
    userId: data.user_id || undefined,
    title: data.title || '',
    description: data.description || '',
    subject: data.subject || '',
    body: data.body ? data.body : htmlParser(data.html_body, true),
    htmlBody: data.html_body ? data.html_body : htmlParser(data.body),
    assignedUsers: data.assigned_users ? data.assigned_users : [],
    actionGroup: data.action_group || '',
    order: data.order || null,
    public: data.public || false,
    attachments: data?.attachments,
  };
}

/**
 * Remove keys with empty values and return new object
 * @param object
 */
function clean(object) {
  const invalidKeys = [];
  each(object, (val, key) => {
    if (val === undefined) {
      invalidKeys.push(key);
    }
  });
  return omit(object, invalidKeys);
}

function serialize(template, keys) {
  const s = {
    id: template.id,
    title: template.title,
    description: template.description,
    subject: template.subject,
    body: template.body,
    html_body: template.htmlBody,
    action_group: template.actionGroup,
    user_ids: template.userIds.map((id) => parseInt(id, 10)),
    attachments: template.attachments || [],
    public: template.isPublic,
  };
  if (keys) {
    return pick(s, keys);
  }
  return clean(s);
}

function getMergeAttributesTooltip(title) {
  return {
    'Email Templates':
      "Merge attributes allow you to send highly personalized emails at ease. When you insert a merge attribute into an email, Spiro will automatically populate that field's content for whichever contact you are emailing.",
    'Email Campaign Templates':
      "Merge attributes allow you to send highly personalized emails at ease. When you insert a merge attribute into an email, Spiro will automatically populate that field's content for every contact in the campaign.",
  }[title];
}

export default {
  blank,
  parse,
  serialize,
  getMergeAttributesTooltip,
};
