import { create as createReducer } from '../../../core/redux-utils';
import { SHOW_MODAL, HIDE_MODAL, RESET_MODAL_STATE, SET_ENTITY } from './constants';

const initialState = {
  visible: false,
  selectedEntity: null,
};

function showModal(state, action) {
  return { ...state, selectedEntity: action.selectedEntity, visible: true };
}

function hideModal(state) {
  return { ...state, visible: false };
}

function resetModal() {
  return initialState;
}

function setEntity(state, action) {
  return {
    ...state,
    selectedEntity: action.entity,
  };
}

const actionHandlers = {
  [SHOW_MODAL]: showModal,
  [HIDE_MODAL]: hideModal,
  [RESET_MODAL_STATE]: resetModal,
  [SET_ENTITY]: setEntity,
};

export default createReducer(initialState, actionHandlers);
