import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { removePhoneNumberFormatting } from 'helpers/twilio';
import { getPhoneType } from './display-fields';

export function detailsRoute(id) {
  return `contact/${id}`;
}

export function detailsPath(id) {
  return `/contacts/${id}`;
}

function getSocialProfile(data, typeId) {
  return data.social_profiles[typeId] || null;
}

function getAddresses(data) {
  return data.addresses.length > 0
    ? data.addresses.map((address) => ({
        value: address.formatted,
        label: `${address.label || ''} address`,
      }))
    : [];
}

function getIndustries(data) {
  return data.industries.length > 0
    ? data.industries.map((industry) => industry.name).join(',')
    : '';
}

function getAvatar(data) {
  return data.avatar ? { url: data.avatar } : '';
}

export function parseCompanyFullContactData(data) {
  if (!data) {
    return null;
  }

  return {
    logo: data.logo,
    name: data.name,
    website: data.website || '',
    overview: data.overview || '',
    numberOfEmployees: data.number_of_employees || '',
    phoneNumbers: data.phone_numbers || [],
    linkedinProfile: getSocialProfile(data, 'linkedincompany'),
    twitterProfile: getSocialProfile(data, 'twitter'),
    facebookProfile: getSocialProfile(data, 'facebook'),
    addresses: getAddresses(data),
    industries: getIndustries(data),
  };
}

export function parsePersonFullContactData(data) {
  if (isEmpty(data)) {
    return null;
  }

  return {
    city: data.city,
    country: data.country,
    primaryPhoto: getAvatar(data),
    linkedinProfile: getSocialProfile(data, 'linkedin'),
    twitterProfile: getSocialProfile(data, 'twitter'),
    facebookProfile: getSocialProfile(data, 'facebook'),
  };
}

export function getCallMenuItems(displayFields, contact) {
  const phoneFields = displayFields.filter((df) => df.type === 'Phone');
  const phones = [];

  phoneFields.forEach((field) => {
    if (contact && contact[field.name]) {
      phones.push({
        name: field.name,
        type: getPhoneType(field.name),
        label: field.label,
        value: contact[field.name],
      });
    }
  });

  return phones;
}

export function getPhoneTypeFromNumber(phoneNumber, contact) {
  const phone = removePhoneNumberFormatting(phoneNumber);
  let phoneType = '';

  if (contact.mobile_phone && phone === removePhoneNumberFormatting(contact.mobile_phone)) {
    phoneType = 'mobile';
  } else if (contact.phone && phone === removePhoneNumberFormatting(contact.phone)) {
    phoneType = 'work';
  } else if (contact.accountPhone && phone === removePhoneNumberFormatting(contact.accountPhone)) {
    phoneType = 'company';
  } else {
    phoneType = 'home';
  }

  return phoneType;
}

export function forEachTwilioErrorMessage(twilioErrors, cb) {
  Object.keys(twilioErrors).forEach((key) => {
    twilioErrors[key].forEach((err) => {
      cb(`${capitalize(key).split('_').join(' ')}: ${err}`);
    });
  });
}

export const optionsForEmailOptOutDropdown = [
  { value: 'false', label: 'No' },
  { value: 'true', label: 'Yes' },
];

export function isEmailOptOutEditable(isAdmin, value) {
  if (!isAdmin && value) return false;
  return true;
}

export function getFullName(contact) {
  return `${contact?.first_name || ''} ${contact?.last_name || ''}`;
}

export function getAccountLookup(contact) {
  return { label: contact.account_name, value: contact.account_id };
}

export function getNextStep(contact) {
  return contact.missed_step || contact.next_step;
}

export function getEmailOptOut(contact) {
  return contact.email_opt_out ? 'Yes' : 'No';
}

export function getOwner(contact) {
  return contact.user?.full_name;
}

export function getContactLabel(df, contact) {
  const label = `${df.label} (Spiro found in email)`;

  if (
    (df.name === 'phone' && contact.phone === contact?.email_signature_data?.phone) ||
    (df.name === 'title' && contact.title === contact?.email_signature_data?.title) ||
    (df.name === 'mobile_phone' &&
      contact.mobile_phone === contact?.email_signature_data?.mobile_phone)
  ) {
    return label;
  }
  return df.label;
}

export default {
  detailsRoute,
  parsePersonFullContactData,
  parseCompanyFullContactData,
  forEachTwilioErrorMessage,
  getContactLabel,
};
