import PropTypes from 'prop-types';
import Recents from '../Recents';
import PhoneTab from '../PhoneTab';
import Voicemail from '../Voicemail';
import MessagesTab from '../MessagesTab';
import styles from './PhoneDialogContent.module.scss';

function getActiveScreen(activeItem) {
  return {
    Messages: <MessagesTab />,
    Recents: <Recents />,
    Phone: <PhoneTab />,
    Voicemail: <Voicemail />,
  }[activeItem];
}

export default function PhoneDialogContent({ activeItem }) {
  return <div className={styles.container}>{getActiveScreen(activeItem)}</div>;
}

PhoneDialogContent.propTypes = {
  activeItem: PropTypes.string.isRequired,
};
