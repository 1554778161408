import { createSlice } from '@reduxjs/toolkit';
import { fetchRingCentralConfig, updateRingCentralConfig } from './actions';

const initialState = {
  data: null,
  isLoading: false,
};

export const ringCentralConfiguration = createSlice({
  name: 'ringCentralConfiguration',
  initialState,
  reducers: {
    clearRingCentralData: (state) => ({ ...state, data: null }),
  },
  extraReducers: {
    [fetchRingCentralConfig.pending]: (state) => ({ ...state, isLoading: true }),
    [fetchRingCentralConfig.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload ? payload.ringcentral_configuration : null,
      isLoading: false,
    }),
    [fetchRingCentralConfig.rejected]: (state) => ({ ...state, isLoading: false }),
    [updateRingCentralConfig.pending]: (state) => ({ ...state, isLoading: true }),
    [updateRingCentralConfig.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.ringcentral_configuration,
      isLoading: false,
    }),
    [updateRingCentralConfig.rejected]: (state) => ({ ...state, isLoading: false }),
  },
});

export const { clearRingCentralData } = ringCentralConfiguration.actions;

export default ringCentralConfiguration.reducer;
