import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import UrlParser from 'url-parse';

const ROOT_URL = process.env.APP_ROOT_URL;

export function goToRoot() {
  if (window) {
    window.location = ROOT_URL;
  }
}

export function goTo(url, { absolute = false } = {}) {
  if (!url) {
    goToRoot();
  }
  let u = '';
  if (absolute) {
    u = ROOT_URL;
  }
  if (window) {
    window.location = u + encodeURI(url);
  }
}

export function open(url, { target, absolute = false } = {}) {
  let u = '';
  if (absolute) {
    u = ROOT_URL;
  }
  if (window) {
    window.open(u + url, target);
  }
}

export function parseUrl(url) {
  if (!url) {
    throw new Error("Can't parse blank url!");
  }
  return new UrlParser(decodeURI(url), true);
}

export function parametrizeUrl(url, params = {}) {
  if (!url) {
    throw new Error("Can't create blank url!");
  }

  let encodedURI = encodeURI(url);
  const cleanParams = cleanUp(params);

  if (cleanParams !== undefined && !isEmpty(cleanParams)) {
    // check if already has params
    if (encodedURI.indexOf('?') === -1) {
      encodedURI += '?';
    } else {
      encodedURI += '&';
    }

    Object.keys(cleanParams).forEach((key) => {
      if (cleanParams[key]) {
        const paramName = encodeURIComponent(key.toString());
        const paramInString = `:${paramName}`;
        const paramValue = encodeURIComponent(cleanParams[key]);

        if (encodedURI.indexOf(paramInString) !== -1) {
          // replace param if in url
          encodedURI = encodedURI.replace(paramInString, paramValue);
        } else {
          // append param in query
          encodedURI += `${paramName}=${paramValue}&`;
        }
      }
    });

    // remove last &
    encodedURI = encodedURI.slice(0, -1);
  }

  encodedURI = encodedURI.replace(/#/g, '%23');
  return encodedURI;
}

function cleanUp(params) {
  return omit(params, (val) => !val);
}

export default {
  goToRoot,
  goTo,
  open,
  parametrizeUrl,
  parseUrl,
};
