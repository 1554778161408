import PropTypes from 'prop-types';
import { ReactComponent as InboundCallIcon } from './inbound-call.svg';
import { ReactComponent as OutboundCallIcon } from './outbound-call.svg';
import { ReactComponent as InboundEmailIcon } from './inbound-email.svg';
import { ReactComponent as OutboundEmailIcon } from './outbound-email.svg';
import { ReactComponent as MissedCallIcon } from './missed-call.svg';
import { ReactComponent as ReceivedVoicemailIcon } from './received-voicemail.svg';
import { ReactComponent as MetInPersonIcon } from './met-in-person.svg';
import { ReactComponent as AttachmentIcon } from './attachment.svg';
import { ReactComponent as CampaignIcon } from './campaign.svg';
import { ReactComponent as ReminderIcon } from './reminder.svg';
import { ReactComponent as CompletedReminderIcon } from './completed-reminder.svg';
import { ReactComponent as DismissedReminderIcon } from './dismissed-reminder.svg';
import { ReactComponent as ForwardCallIcon } from './forward-call.svg';
import { ReactComponent as CallRecordingIcon } from './call-recording.svg';
import { ReactComponent as CallDidntConnectIcon } from './call-didnt-connect.svg';
import { ReactComponent as LeftVoicemailIcon } from './left-voicemail.svg';
import { ReactComponent as InboundTextIcon } from './inbound-text.svg';
import { ReactComponent as OutboundTextIcon } from './outbound-text.svg';
import { ReactComponent as NoteIcon } from './note.svg';
import { ReactComponent as MeetingIcon } from './meeting.svg';
import { ReactComponent as ScheduledEmail } from './scheduled-email.svg';
import { ReactComponent as VideoCall } from './video-call.svg';
import { ReactComponent as DefaultIcon } from './default.svg';

export default function ActivityIcon({ kind }) {
  const iconsLookup = {
    inbound_call: <InboundCallIcon data-cy="activities-list-inbound-call-icon" />,
    outbound_call: <OutboundCallIcon data-cy="activities-list-outbound-call-icon" />,
    inbound_email: <InboundEmailIcon data-cy="activities-list-inbound-email-icon" />,
    outbound_email: <OutboundEmailIcon data-cy="activities-list-outbound-email-icon" />,
    missed_call: <MissedCallIcon data-cy="activities-list-outbound-email-icon" />,
    received_voicemail: <ReceivedVoicemailIcon data-cy="activities-list-recived-voicemail-icon" />,
    attachment: <AttachmentIcon data-cy="activities-list-attachment-icon" />,
    campaign: <CampaignIcon data-cy="activities-list-campaign-icon" />,
    reminder: <ReminderIcon data-cy="activities-list-reminder-icon" />,
    completed_reminder: <CompletedReminderIcon data-cy="activities-list-completed-reminder-icon" />,
    dismissed_reminder: <DismissedReminderIcon data-cy="activities-list-dismissed-reminder-icon" />,
    call_didnt_connect: <CallDidntConnectIcon data-cy="activities-list-call-didnt-connect-icon" />,
    left_voicemail: <LeftVoicemailIcon data-cy="activities-list-left-voicemail-icon" />,
    forward_call: <ForwardCallIcon data-cy="activities-list-forward-call-icon" />,
    call_recording: <CallRecordingIcon data-cy="activities-list-call-recording-icon" />,
    inbound_text: <InboundTextIcon data-cy="activities-list-inbound-text-icon" />,
    outbound_text: <OutboundTextIcon data-cy="activities-list-outbound-text-icon" />,
    sent_text: <OutboundTextIcon data-cy="activities-list-sent-text-icon" />,
    note: <NoteIcon data-cy="activities-list-note-icon" />,
    met_in_person: <MetInPersonIcon data-cy="activities-list-met-in-person-icon" />,
    meeting: <MeetingIcon data-cy="activities-list-meeting-icon" />,
    scheduled_email: <ScheduledEmail data-cy="activities-list-scheduled-email-icon" />,
    video_meeting: <VideoCall data-cy="activities-list-video-meeting-icon" />,
    other: <DefaultIcon />,
  };

  return iconsLookup[kind] || <DefaultIcon />;
}

ActivityIcon.propTypes = {
  kind: PropTypes.string.isRequired,
};
