import DialogContentText from '@mui/material/DialogContentText';
import Modal from 'ui/Modal';
import Button from 'lib/Button';
import TextField from 'lib/TextField';
import { useState } from 'react';

function AddMentionModal({ onClose = () => {} }) {
  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);

  const checkIfEmailIsInvalid = (emailValue) => {
    const isInvalid =
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        emailValue.toLowerCase()
      );

    return isInvalid;
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (checkIfEmailIsInvalid(value)) {
      setIsError(true);
    } else {
      tinymce.activeEditor.execCommand(
        'mceInsertContent',
        false,
        `<span class="spiro-mention" data-mention-id="${value}" data-mention-type="email" style="color: #5d43cf; display: inline-bloc; height: 20px; padding: 0 8px; line-height: 20px; border-radius: 30px; background-color: rgba(174, 161, 231, 0.24);">&#8205;${value}</span>&nbsp;`
      );
      onClose();
    }
  };

  return (
    <Modal open onClose={onClose} maxWidth="md">
      <Modal.Header>Mention via Email</Modal.Header>
      <Modal.Body>
        <DialogContentText
          sx={{
            fontSize: 16,
            paddingBottom: 2,
            paddingLeft: '4px',
          }}
        >
          Mention people in your notes by simply adding their email address.
        </DialogContentText>
        <TextField
          placeholder="Enter email address"
          value={value}
          onChange={handleChange}
          autoFocus
          error={isError}
          helperText={isError ? 'Please provide a valid email address.' : ''}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={value.length <= 0}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMentionModal;
