import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getFullName } from 'helpers/contacts';
import styles from './RecentsCard.module.scss';

export default function RecentDetails({ recentCall }) {
  return (
    <div className={styles.name} data-cy="phone-recents-contact-name">
      {recentCall.contact && (
        <Link to={`/contacts/${recentCall.contact.id}`} className="spiro-link bold-500">
          {getFullName(recentCall.contact)}
        </Link>
      )}
      {recentCall.account && (
        <Link to={`/companies/${recentCall.account.id}`} className="spiro-link bold-500">
          {recentCall.account.name}
        </Link>
      )}
      {!recentCall.contact && !recentCall.account && (
        <span data-cy="phone-recents-phone-number">
          {recentCall.kind === 'inbound_call'
            ? parsePhoneNumberFromString(recentCall.from_number)?.formatInternational()
            : parsePhoneNumberFromString(recentCall.to_number)?.formatInternational() ||
              'no number'}
        </span>
      )}
      {recentCall.phone_type && (
        <span className={styles['phone-type']} data-cy="phone-recents-phone-type">
          ({recentCall.phone_type})
        </span>
      )}
    </div>
  );
}

RecentDetails.propTypes = {
  recentCall: PropTypes.object.isRequired,
};
