import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewContactModalContainer from 'modals/NewContactModal';
import AddExistingResourceModal from 'modals/AddExistingResourceModal';
import { selectNameOfOpenModal } from 'modals/state/selectors';
import useHotjar from 'hooks/useHotjar';
import CallStatus from '../CallStatus';
import RejectCall from '../RejectCall';
import MuteCallButton from '../MuteCallButton';
import usePhoneDialogMinimized from './usePhoneDialogMinimized';
import {
  minimizeCallModal,
  setModalMinimizedByOtherAction,
  setPhoneDialogOpen,
} from '../state/actions';
import {
  selectIsCallLogged,
  selectTwilioConnection,
  selectTwilioEnabled,
  selectIsModalMinimizedByOtherAction,
  selectCallModalMinimized,
} from '../state/selectors';
import CallAvailabilityToggle from '../CallAvailabilityToggle';
import CallDurationTimer from '../CallDurationTimer';

import styles from './PhoneDialogMinimized.module.scss';

export default function PhoneDialogMinimized() {
  const isTwilioEnabled = useSelector(selectTwilioEnabled);
  const isCallActive = !!useSelector(selectTwilioConnection);
  const isCallLogged = useSelector(selectIsCallLogged);
  const isLoggingActivityScreenActive = !isCallActive && isCallLogged;
  const isModalMinimizedByOtherAction = useSelector(selectIsModalMinimizedByOtherAction);
  const isModalMinimized = useSelector(selectCallModalMinimized);
  const { logCustomEvent } = useHotjar();

  const modalName = useSelector(selectNameOfOpenModal);

  const {
    selectExistingContact,
    hideAddExistingContactModal,
    addContact,
    hideAddContactModal,
    newContactModalVisible,
    addExistingContactModalVisible,
    phoneNumber,
  } = usePhoneDialogMinimized();
  const dispatch = useDispatch();

  const maximizePhoneDialog = () => {
    dispatch(setPhoneDialogOpen(true));
    dispatch(minimizeCallModal(false));
    logCustomEvent('phone_modal_opened');
  };

  useEffect(() => {
    if (isModalMinimizedByOtherAction && modalName === '') {
      maximizePhoneDialog();
      dispatch(setModalMinimizedByOtherAction(false));
    }
  }, [modalName]);

  return (
    <div className={styles.container}>
      {!isCallActive && !isLoggingActivityScreenActive && (
        <>
          <CallStatus onClick={maximizePhoneDialog} />
          <CallAvailabilityToggle />
          <p className={styles.title}>Calls {isTwilioEnabled ? 'Available' : 'Unavailable'}</p>
        </>
      )}
      {isCallActive && (
        <>
          <CallStatus onClick={maximizePhoneDialog} isInCall />
          <p className={styles.title}>Ongoing Call</p>
          <div className={styles.duration}>
            (<CallDurationTimer />)
          </div>
          <MuteCallButton smallSize />
          <RejectCall iconSize="small" />
        </>
      )}
      {isLoggingActivityScreenActive && (
        <>
          <CallStatus onClick={maximizePhoneDialog} isLoggingActivity />
          <p className={styles.message}>Call Ended</p>
          <div className={styles.caption}>
            (<CallDurationTimer />)
          </div>
        </>
      )}
      {isModalMinimized && (
        <NewContactModalContainer
          show={newContactModalVisible}
          onHide={hideAddContactModal}
          onSubmit={addContact}
          mobilePhone={phoneNumber}
        />
      )}
      <AddExistingResourceModal
        resource="Contact"
        show={addExistingContactModalVisible}
        onHide={hideAddExistingContactModal}
        onSubmit={selectExistingContact}
      />
    </div>
  );
}
