import { STORE_CALENDAR, REMOVE_CALENDARS, STORE_CALENDARS } from './constants';
import { parseCalendarFromEngine } from '../../helpers/calendars';
import { REMOVE_TOKEN } from '../tokens/constants';

const initialState = {
  calendar: { ...parseCalendarFromEngine() },
  calendars: [],
};

function storeCalendarHandler(state, action) {
  return {
    ...state,
    calendar: { ...parseCalendarFromEngine(action.data) },
  };
}

function storeCalendarsHandler(state, action) {
  return {
    ...state,
    calendars: action.data,
  };
}

function removeCalendarsHandler() {
  return {
    ...initialState,
  };
}

function clearCalendarHandler() {
  return {
    uid: '',
    name: '',
    provider: '',
  };
}

const ACTION_HANDLERS = {
  [STORE_CALENDAR]: storeCalendarHandler,
  [REMOVE_TOKEN]: clearCalendarHandler,
  [STORE_CALENDARS]: storeCalendarsHandler,
  [REMOVE_CALENDARS]: removeCalendarsHandler,
};

export default function calendarReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
