import { useDispatch, useSelector } from 'react-redux';
import { success as successAlert, error as errorAlert } from 'state/notifications/actions';
import {
  minimizeCallModal,
  setCallInfo,
  setPhoneDialogOpen,
  setTwilioCaller,
  toggleAddContactModal,
  toggleAddExistingContactModal,
} from 'components/CallDialog/state/actions';
import { updateContact } from 'services/contacts';
import {
  selectAddContactModalVisible,
  selectAddExistingContactModalVisible,
  selectPhoneNumber,
  selectTwilioCallers,
} from 'components/CallDialog/state/selectors';
import { forEachError } from '../../../../helpers/errorHelper';
import { createContact } from '../../../contacts/Contacts/state/actions';

export default function usePhoneDialogMinimized() {
  const dispatch = useDispatch();
  const callers = useSelector(selectTwilioCallers);
  const phoneNumber = useSelector(selectPhoneNumber);
  const newContactModalVisible = useSelector(selectAddContactModalVisible);
  const addExistingContactModalVisible = useSelector(selectAddExistingContactModalVisible);

  const hideAddContactModal = () => {
    dispatch(toggleAddContactModal(false));
    dispatch(minimizeCallModal(false));
    dispatch(setPhoneDialogOpen(true));
  };

  const addContact = (payload) =>
    dispatch(createContact(payload)).then((res) => {
      const caller = {
        account_name: res.account_name,
        name: `${res.first_name} ${res.last_name}`,
        resource_id: res.id,
        resource_type: 'Contact',
      };
      dispatch(setCallInfo([...callers, caller]));
      if (callers.length > 1) {
        dispatch(setTwilioCaller(caller));
      }

      hideAddContactModal();
      dispatch(successAlert('New contact created.'));
    });

  const hideAddExistingContactModal = () => {
    dispatch(toggleAddExistingContactModal(false));
    dispatch(minimizeCallModal(false));
    dispatch(setPhoneDialogOpen(true));
  };

  const selectExistingContact = async (contact) => {
    try {
      const res = await updateContact(contact, { mobile_phone: phoneNumber });
      const caller = {
        account_name: res.contact.account_name,
        name: `${res.contact.first_name} ${res.contact.last_name}`,
        resource_id: res.contact.id,
        resource_type: 'Contact',
      };
      dispatch(setCallInfo([...callers, caller], caller));

      hideAddExistingContactModal();
      dispatch(successAlert('Contact updated.'));
    } catch (error) {
      forEachError(error.data, (e) => dispatch(errorAlert(e)));
    }
  };

  return {
    selectExistingContact,
    hideAddExistingContactModal,
    addContact,
    hideAddContactModal,
    newContactModalVisible,
    addExistingContactModalVisible,
    phoneNumber,
  };
}
