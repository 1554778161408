import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTickets,
  createTicket,
  getTicketsCount as getTicketsCountRemote,
} from 'services/ticket';
import { error, success as successAlert } from 'state/notifications/actions';
import {
  fetchFilterProperties as fetchNestedFilterProperties,
  fetchSavedFilters as fetchNestedSavedFilters,
} from 'app/state/filters/nested-filters/actions';
import { forEachError } from '../../../../../helpers/errorHelper';
import { fetchFilterProperties, fetchSavedFilters } from '../filters/actions';
import { serializeFiltersToString } from '../../../../helpers/filters';
import { fetchSupportDesks } from '../../../../routes/Settings/SupportDesks/state/actions';

function getParamsFromState(state) {
  const params = state.tickets.data.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.tickets.filters.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Ticket;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

const fetchNestedTickets = async (dispatch, areNestedTickets = false) => {
  if (areNestedTickets) {
    await dispatch(fetchNestedFilterProperties('Ticket'));
    await dispatch(fetchNestedSavedFilters('Ticket'));
  } else {
    await dispatch(fetchFilterProperties());
    await dispatch(fetchSavedFilters());
  }
};

const getSupportDesks = async (dispatch, getState) => {
  const isCached = getState().supportDesks.data.length;

  if (isCached) return Promise.resolve();

  return dispatch(fetchSupportDesks());
};

export const fetchTickets = createAsyncThunk(
  'tickets-get',
  async (params = {}, { dispatch, getState }) => {
    try {
      await fetchNestedTickets(dispatch, params.areNestedTickets);
      const stateParams = await getParamsFromState(getState());
      await getSupportDesks(dispatch, getState);
      const payload = { ...stateParams, ...params };
      if (payload.areNestedTickets) delete payload.areNestedTickets;
      const res = await getTickets(payload);
      return Promise.resolve({ ...res, params: payload });
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export const getTicketsCount = createAsyncThunk(
  'tickets-count',
  async (params = {}, { getState }) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };
    return getTicketsCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  }
);

export const handleCreateTicket = createAsyncThunk(
  'tickets-create',
  async (payload, { dispatch }) => {
    try {
      const res = await createTicket(payload);
      dispatch(successAlert('Ticket created!'));
      return res.tickets;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
