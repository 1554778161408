import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PublicRoute({ userLoggedIn, redirectPath = '/', children }) {
  if (userLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}

PublicRoute.propTypes = {
  children: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userLoggedIn: state.session.loggedIn,
  };
}

export default connect(mapStateToProps)(PublicRoute);
