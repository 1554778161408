import { configureStore } from '@reduxjs/toolkit';
import { logger as loggerMiddleware, session as sessionMiddleware } from './app/redux/middlewares';
import reducer from './reducers';

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
      .concat(loggerMiddleware)
      .concat(sessionMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});
