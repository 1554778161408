import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { convertNameToInitials } from 'helpers/utils';
import styles from './UserAvatar.module.scss';

const getClassNameByAvatarSize = (isSmall, isMini) => {
  if (isSmall) return 'small-avatar';
  if (isMini) return 'mini-avatar';
  return 'avatar';
};

export default function UserAvatar({ user, isSmall, isMini, displayInitials }) {
  return (
    <Avatar
      className={styles[getClassNameByAvatarSize(isSmall, isMini)]}
      src={user.photoUrl || ''}
      data-cy="user-avatar"
      sx={{ backgroundColor: isMini ? '#80808014' : '' }}
    >
      {displayInitials && <>{convertNameToInitials(user.fullName)}</>}
      {!displayInitials && (
        <PermIdentityOutlinedIcon sx={{ color: '#4D4D4D', width: '16px', height: '16px' }} />
      )}
    </Avatar>
  );
}

UserAvatar.defaultProps = {
  displayInitials: true,
  isMini: false,
  isSmall: false,
  user: null,
};

UserAvatar.propTypes = {
  user: PropTypes.object,
  isMini: PropTypes.bool,
  isSmall: PropTypes.bool,
  displayInitials: PropTypes.bool,
};
