import mapValues from 'lodash/mapValues';
import { createSlice } from '@reduxjs/toolkit';
import { fetchSpiroViews, updateSpiroViews, deleteSpiroView } from './actions';

function parse(field) {
  return {
    ...field,
    edit: field.edit === '1',
    hidden: field.hidden === '1',
    pinned: field.pinned === '1',
    label: field.label,
    order: parseInt(field.order, 10),
    readonly: field.readonly === '1',
    sortable: field.sortable === '1',
    disabled: field.disabled === '1',
    removable: field.removable === '1',
  };
}

const initialState = {
  columns: {},
  rawColumns: {},
  defaultOrder: {},
  fetching: true,
  recordsPerPage: Number(localStorage.getItem('recordsPerPage')) || 10,
  activeTab: {
    account: '',
    contact: '',
    opportunity: '',
    order: '1',
    quote: '1',
    ticket: '',
  },
};

export const spiroViewsSlice = createSlice({
  name: 'spiroViews',
  initialState,
  reducers: {
    clearState: () => initialState,
    setRecordsPerPage: (state, { payload }) => ({ ...state, recordsPerPage: payload }),
    setActiveTab: (state, { payload }) => ({
      ...state,
      activeTab: { ...state.activeTab, ...payload },
    }),
  },
  extraReducers: {
    [fetchSpiroViews.pending]: (state) => ({ ...state, fetching: true }),
    [fetchSpiroViews.fulfilled]: (state, { payload }) => ({
      ...state,
      rawColumns: payload,
      columns: mapValues(payload, (resource) =>
        mapValues(resource.columns, (field) => parse(field))
      ),
      defaultOrder: mapValues(payload, (resource) => ({
        order_by: resource.order_by,
        order_direction: resource.order_direction,
        pinned_left: resource.pinned_left,
        pinned_right: resource.pinned_right,
        resource: resource.resource,
      })),
      fetching: false,
    }),
    [fetchSpiroViews.rejected]: (state) => ({ ...state, fetching: false }),
    [updateSpiroViews.pending]: (state, action) => {
      const columns = {
        ...state.columns,
        [action.meta.arg.resource]: mapValues(action.meta.arg.columns, (field) => parse(field)),
      };
      return { ...state, columns };
    },
    [updateSpiroViews.fulfilled]: (state, { payload }) => {
      const newColumns = mapValues(payload, (resource) =>
        mapValues(resource.columns, (field) => parse(field))
      );
      const resource = Object.keys(payload)[0];
      return {
        ...state,
        columns: { ...state.columns, ...newColumns },
        rawColumns: { ...state.rawColumns, ...payload },
        defaultOrder: {
          ...state.defaultOrder,
          [resource]: {
            ...state.defaultOrder[resource],
            order_by: payload[resource].order_by,
            order_direction: payload[resource].order_direction,
            pinned_left: payload[resource].pinned_left,
            pinned_right: payload[resource].pinned_right,
          },
        },
      };
    },
    [deleteSpiroView.fulfilled]: (state, { payload }) => {
      const newColumns = mapValues(payload, (resource) =>
        mapValues(resource.columns, (field) => parse(field))
      );
      const resource = Object.keys(payload)[0];
      return {
        ...state,
        columns: { ...state.columns, ...newColumns },
        rawColumns: { ...state.rawColumns, ...payload },
        defaultOrder: {
          ...state.defaultOrder,
          [resource]: {
            ...state.defaultOrder[resource],
            pinned_left: payload[resource].pinned_left,
            pinned_right: payload[resource].pinned_right,
          },
        },
      };
    },
  },
});

export const { clearState, setRecordsPerPage, setActiveTab } = spiroViewsSlice.actions;

export default spiroViewsSlice.reducer;
