import isFunction from 'lodash/isFunction';
import credentialService from 'services/credentials';
import { parseCredential } from 'helpers/credentials';
import {
  STORE_CREDENTIALS,
  STORE_CREDENTIAL,
  SET_IS_DELETING,
  REMOVE_CREDENTIAL,
} from './constants';
import { removeCalendars } from '../calendar/actions';

export function storeCredentials(data) {
  return {
    type: STORE_CREDENTIALS,
    data,
  };
}

export function storeCredential(data) {
  return {
    type: STORE_CREDENTIAL,
    data,
  };
}

export function sendCredentialToEngine(data, successCallback, errorCallback) {
  return (dispatch) =>
    credentialService
      .sendToEngine(data)
      .then((r) => {
        dispatch(storeCredential(parseCredential(r)));
        if (isFunction(successCallback)) {
          successCallback(r);
        }
      })
      .catch((e) => {
        if (isFunction(errorCallback)) {
          errorCallback(e);
        }
      });
}

export function setIsDeleting(status) {
  return {
    type: SET_IS_DELETING,
    status,
  };
}

export function removeCredential(credentialId) {
  return {
    type: REMOVE_CREDENTIAL,
    credentialId,
  };
}

export function deleteCredential(credentialId) {
  return (dispatch) => {
    dispatch(setIsDeleting(true));

    return credentialService
      .deleteCredential(credentialId)
      .then(() => {
        dispatch(setIsDeleting(false));
        dispatch(removeCredential(credentialId));
        dispatch(removeCalendars());
      })
      .catch((e) => {
        throw e;
      });
  };
}

export default {
  storeCredentials,
  storeCredential,
  sendCredentialToEngine,
};
