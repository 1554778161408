import PropTypes from 'prop-types';
import UserAvatar from 'components/UserAvatar';
import { ReactComponent as SpiritoIcon } from 'images/icons/spirito-outlined-icon.svg';
import styles from './NotificationsAvatar.module.scss';

const notificationsWithoutAvatar = [
  'assistant_rule_single_resource_added',
  'external_notification',
  'assistant_rule_multiple_resources_added',
  'sms_delivery_failed',
];

export default function NotificationsAvatar({ type, sender }) {
  if (notificationsWithoutAvatar.includes(type)) {
    return (
      <div className={styles['icon-wrapper']}>
        <SpiritoIcon />
      </div>
    );
  }
  return <UserAvatar user={{ fullName: sender.name, photoUrl: sender.photo_url }} isSmall />;
}

NotificationsAvatar.propTypes = {
  type: PropTypes.string.isRequired,
  sender: PropTypes.object.isRequired,
};
