import PropTypes from 'prop-types';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import NotificationIndicator from 'app/layouts/Main/TopBar/TopBarActions/NotificationIndicator';
import { convertNameToInitials } from 'helpers/utils';
import { getFullName } from 'helpers/contacts';
import styles from './RecentsCard.module.scss';

export default function RecentsAvatar({ recent }) {
  return (
    <div className={styles.avatar}>
      {!recent.confirmed && <NotificationIndicator />}
      {recent.contact && !recent.contact.photo_url && (
        <span>{convertNameToInitials(getFullName(recent.contact))}</span>
      )}
      {!recent.contact && !recent.account && <PersonOutlineIcon />}
      {recent.contact && recent.contact.photo_url && (
        <img src={recent.contact.photo_url} alt="contact avatar" />
      )}
      {recent.account && !recent.account.photo_url && <MapsHomeWorkOutlinedIcon />}
      {recent.account && recent.account.photo_url && (
        <img src={recent.account.photo_url} alt="account logo" />
      )}
    </div>
  );
}

RecentsAvatar.propTypes = {
  recent: PropTypes.object.isRequired,
};
