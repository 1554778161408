import get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { customFieldsToArray } from 'helpers/custom-fields';

const labelsMapping = {
  Account: 'Company',
  Interaction: 'Activity',
  SalesOrder: 'Sales Order',
  SalesOrderItem: 'Sales Order Item',
};

const belongsToAccount = (field) => field.resource_for === 'Account';
const belongsToContact = (field) => field.resource_for === 'Contact';
const belongsToOpportunity = (field) => field.resource_for === 'Opportunity';
const belongsToTickets = (field) => field.resource_for === 'Ticket';
const belongsToQuote = (field) => field.resource_for === 'Quote';
const belongsToOrder = (field) => field.resource_for === 'SalesOrder';
const belongsToOrderItem = (field) => field.resource_for === 'SalesOrderItem';
const isIndependent = (field) => field.independent;
const byOrder = (a, b) => a.order - b.order;
const toCustomizationPageFormat = (fields, currentField) => {
  if (['Notification', 'Activity', '.Team'].includes(currentField.resource)) {
    return fields;
  }

  fields.push({
    ...currentField,
    resource: labelsMapping[currentField.resource] || currentField.resource,
  });
  return fields;
};
const toRelatedToOptions = (fields, currentField) => {
  if (
    [
      'Notification',
      'Activity',
      '.Team',
      'Interaction',
      'User',
      'Reminder',
      'List',
      'Product',
    ].includes(currentField.resource) ||
    currentField.schema_type === 'section'
  ) {
    return fields;
  }

  fields.push({
    value: currentField.resource,
    label: labelsMapping[currentField.resource] || currentField.resource,
  });
  return fields;
};

export const selectDisposition = (state) => get(state.data, 'Activity.fields.disposition', {});

export const selectDispositionValues = createSelector([selectDisposition], (disposition) => {
  if (!disposition || !disposition.value) {
    return [];
  }
  return disposition.value;
});

export const getCustomFields = (resourceName) => (state) => {
  const { fetched, data } = state.customFields;
  const resource = data[resourceName];
  return fetched && resource ? resource.fields : {};
};

export const getNextStepValues = (state) => {
  const { fetched, data } = state.customFields;
  const notification = data.Notification;
  return fetched && notification ? notification.fields.next_step.value : [];
};

export const getActivityDispositionValues = (state) => {
  const { fetched, data } = state.customFields;
  const activity = data.Activity;
  return fetched && activity ? activity.fields.disposition.value : [];
};

export const getInteractionCustomFields = (state) => {
  const { fetched, data } = state.customFields;
  const interactionEntity = data.Interaction;
  return fetched && interactionEntity ? interactionEntity.fields : {};
};

export const getReminderCustomFields = (state) => {
  const { fetched, data } = state.customFields;
  const reminderEntity = data.Reminder;
  return fetched && reminderEntity ? reminderEntity.fields : {};
};

export const getFieldsForResource = (state, resourceKey) => {
  const { fetched, data } = state.customFields;
  const resource = data[resourceKey];
  return fetched && resource ? resource.fields : {};
};

export const selectIsFetched = (state) => state.customFields.fetched;

export const selectCustomFields = (state) => state.customFields.data;
export const selectCustomFields2 = (state) => state.customFields.reduced;

export const selectCustomFieldsArray = createSelector([selectCustomFields2], (customFields) =>
  customFieldsToArray(customFields).reduce(toCustomizationPageFormat, []).sort(byOrder)
);

export const selectCustomFieldsOptions = createSelector([selectCustomFields2], (customFields) =>
  customFieldsToArray(customFields).reduce(toRelatedToOptions, []).sort(byOrder)
);

export const selectInteractionCustomFields = (state) => {
  const isFetched = selectIsFetched(state);
  return isFetched && state.customFields.data.Interaction.fields;
};

export const selectInteractionCustomFieldsAsArray = createSelector(
  [selectInteractionCustomFields],
  (customFields) => customFieldsToArray(customFields)
);

export const selectCustomFieldsAsArray = createSelector([selectCustomFields], (customFields) =>
  customFieldsToArray(customFields)
);

export const selectAccountCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(belongsToAccount).sort(byOrder)
);

export const selectContactCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(belongsToContact).sort(byOrder)
);

export const selectOpportunityCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(belongsToOpportunity).sort(byOrder)
);

export const selectIndependentCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(isIndependent).sort(byOrder)
);

export const selectTicketsCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(belongsToTickets).sort(byOrder)
);

export const selectQuoteCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(belongsToQuote).sort(byOrder)
);

export const selectOrderCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(belongsToOrder).sort(byOrder)
);

export const selectOrderItemCustomEntities = createSelector(
  [selectCustomFieldsAsArray],
  (customFields) => customFields.filter(belongsToOrderItem).sort(byOrder)
);

export const selectCustomEntitiesForRules = createSelector(
  [
    selectAccountCustomEntities,
    selectContactCustomEntities,
    selectIndependentCustomEntities,
    selectOpportunityCustomEntities,
  ],
  (
    accountCustomFields,
    contactCustomFields,
    independentCustomEntities,
    opportunityCustomEntities
  ) => [
    ...contactCustomFields,
    ...accountCustomFields,
    ...opportunityCustomEntities,
    ...independentCustomEntities,
  ]
);

export default {
  getCustomFields,
};
