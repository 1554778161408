import { createAsyncThunk } from '@reduxjs/toolkit';
import { error } from 'state/notifications/actions';
import {
  getQuote,
  updateQuote as updateQuoteRemote,
  deleteQuote as deleteQuoteRemote,
} from 'services/qoutes';
import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';

export const fetchQuote = createAsyncThunk('quote-get', async (quoteID, { dispatch }) => {
  try {
    const res = await getQuote(quoteID);
    return res.quote;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const updateQuote = createAsyncThunk('quote-update', async (payload, { dispatch }) => {
  try {
    const res = await updateQuoteRemote(payload.id, serializeForm(payload.value));
    return res.quote;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const deleteQuote = createAsyncThunk('quote-delete', async (quoteID, { dispatch }) => {
  try {
    return await deleteQuoteRemote(quoteID);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});
