import { createAsyncThunk } from '@reduxjs/toolkit';
import { error as errorAlert } from 'state/notifications/actions';
import { forEachError } from '../../../helpers/errorHelper';
import {
  deleteConfiguration,
  getConfiguration,
  updateConfiguration,
} from '../../services/ring-central';

export const fetchRingCentralConfig = createAsyncThunk(
  'ringcentral-configuration-get',
  async (_, { dispatch }) => {
    try {
      const res = await getConfiguration();
      return Promise.resolve(res);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const updateRingCentralConfig = createAsyncThunk(
  'ringcentral-configuration-update',
  async (payload, { dispatch }) => {
    try {
      const res = await updateConfiguration(payload);
      return Promise.resolve(res);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);

export const deleteRingCentralConfig = createAsyncThunk(
  'ringcentral-configuration-delete',
  async (_, { dispatch }) => {
    try {
      return await deleteConfiguration();
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  }
);
