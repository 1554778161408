import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'lib/Button';
import NotesInput from 'components/CollaborationNotes/NotesInput';
import InputRenderer from 'components/InputRenderer';
import useHotjar from 'app/hooks/useHotjar';

export default function NewResourceModal({
  title,
  show,
  fields,
  onSubmit,
  onCancel,
  loading,
  formName,
  subtitle,
  hasNotesInput,
}) {
  const { t } = useTranslation();
  const { logCustomEvent } = useHotjar();

  const handleOnSave = (e) => {
    onSubmit(e);
    logCustomEvent('new_record_created');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={show}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      data-cy={formName}
    >
      <form name={formName} onSubmit={handleOnSave}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {subtitle && (
            <DialogContentText
              sx={{
                fontSize: 12,
                paddingBottom: 2,
              }}
            >
              {subtitle}
            </DialogContentText>
          )}
          <Grid container spacing={2}>
            {fields.map((field) => {
              if (field.name === 'description') {
                if (!hasNotesInput) {
                  return (
                    <Grid item key={field.name} xs={12}>
                      <InputRenderer field={field} />
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={12}>
                    <NotesInput onChange={field.handleNoteChange} editorValue={field.value} />
                  </Grid>
                );
              }
              return (
                <Grid item key={field.name} xs={12} sm={6} md={4}>
                  <InputRenderer field={field} />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={loading}>
            {t('localization.actions.cancel')}
          </Button>
          <Button type="submit" value="Submit" disabled={loading}>
            {t('localization.actions.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
NewResourceModal.defaultProps = {
  subtitle: '',
  hasNotesInput: false,
};

NewResourceModal.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  formName: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  hasNotesInput: PropTypes.bool,
};
