import { createAsyncThunk } from '@reduxjs/toolkit';
import { getOrderItems, updateOrderItem as updateOrderItemRemote } from 'services/orders';
import { error } from 'state/notifications/actions';
import { forEachError } from '../../../../helpers/errorHelper';

export const fetchOrderItems = createAsyncThunk(
  'orders/fetchOrderItems',
  async (payload, { dispatch, getState }) => {
    const { orderID, params = {} } = payload;
    try {
      const paramsPayload = { ...getState().orderItems.params, ...params };
      const recordsPerPage = getState().spiroViews.recordsPerPage;
      const defaultParams = { ...paramsPayload, per_page: recordsPerPage };
      const json = await getOrderItems(orderID, defaultParams);
      return Promise.resolve({ ...json, params: defaultParams });
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const updateOrderItem = createAsyncThunk(
  'orders/updateOrderItem',
  async (data, { dispatch }) => {
    try {
      const res = await updateOrderItemRemote(data.orderID, data.orderItemID, data.payload);
      return res.sales_order_item;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
