import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/HighlightOff';
import styles from './UploadFilesPreview.module.scss';

function UploadFilesPreview({ files, onRemove }) {
  return (
    <div className={styles.container}>
      {files.map((f, i) => (
        <span key={f.file.name} className={styles.item}>
          <DeleteIcon onClick={() => onRemove(f.file, i)} />
          <img src={f.preview} className={styles.image} alt="upload file preview" />
        </span>
      ))}
    </div>
  );
}

UploadFilesPreview.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      signedUrl: PropTypes.string,
      publicUrl: PropTypes.string,
    })
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default UploadFilesPreview;
