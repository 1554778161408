import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialpadIcon from '@mui/icons-material/Dialpad';
import CallEndOutlinedIcon from '@mui/icons-material/CallEndOutlined';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import AcceptCall from 'components/CallDialog/AcceptCall';
import RejectCall from 'components/CallDialog/RejectCall';
import IconButtonWithLabel from 'lib/IconButtonWithLabel';
import {
  displayDialpad,
  setNoteScreenOpen,
  toggleTransferCall,
} from 'components/CallDialog/state/actions';
import {
  selectIsOutgoingCall,
  selectTwilioConnection,
} from 'components/CallDialog/state/selectors';
import MuteCallButton from '../../../MuteCallButton';
import DisableRecordingButton from '../../../DisableRecordingButton';
import CallActions from '../../CallActions';
import styles from './ActiveCallActions.module.scss';

export default function ActiveCallActions() {
  const dispatch = useDispatch();
  const connection = useSelector(selectTwilioConnection);
  const isOutgoingCall = useSelector(selectIsOutgoingCall);

  const toggleStartTransferCall = () => {
    dispatch(setNoteScreenOpen(true));
    dispatch(toggleTransferCall(true));
  };

  return (
    <Box>
      {!connection ? (
        <Box className={styles.container}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={styles['message-wrapper']}
          >
            <Grid item className={styles.icon}>
              <CallEndOutlinedIcon />
            </Grid>
            <Typography variant="subtitle1">Call ended</Typography>
          </Grid>
        </Box>
      ) : (
        <Box className={styles['container-actions']}>
          <MuteCallButton />
          <IconButtonWithLabel
            icon={<DialpadIcon />}
            classes={styles.button}
            color="purple"
            handleOnClick={() => {
              dispatch(displayDialpad(true));
            }}
            label="Dialpad"
          />
          <DisableRecordingButton />
        </Box>
      )}

      <CallActions color="purple" />
      <Box
        className={`${styles['call-actions']} ${
          isOutgoingCall ? '' : styles['call-actions-inbound']
        }`}
      >
        {connection ? (
          <>
            {!isOutgoingCall && (
              <IconButtonWithLabel
                icon={<PhoneForwardedOutlinedIcon />}
                classes={styles.button}
                color="purple"
                handleOnClick={toggleStartTransferCall}
                label="Transfer call"
              />
            )}
            <div className={styles.reject}>
              <RejectCall />
              {!isOutgoingCall && <span>End Call</span>}
            </div>
          </>
        ) : (
          <AcceptCall />
        )}
      </Box>
    </Box>
  );
}
