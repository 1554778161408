import PropTypes from 'prop-types';
import styles from './LinkMessage.module.scss';

const pathNames = {
  Account: 'companies',
  Opportunity: 'opportunities',
  Contact: 'contacts',
  SalesOrder: 'orders',
  Ticket: 'tickets',
  Quote: 'quotes',
};

const ensureProtocol = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

const getHref = (value) => {
  if (value.type === 'external_link') return ensureProtocol(value.external_link_value);

  const resourceType = value.resource_type;
  const resourceID = value.resource_id;

  if (resourceType === 'Reminder') {
    return `${process.env.APP_ROOT_URL}/reminders?reminderID=${resourceID}`;
  }
  if (resourceType === 'Interaction') {
    return `${process.env.APP_ROOT_URL}/activities?activityID=${resourceID}&kind=${value.interaction_kind}`;
  }

  return `${process.env.APP_ROOT_URL}/${pathNames[resourceType]}/${resourceID}`;
};

export default function LinkMessage({ value }) {
  return (
    <a href={getHref(value)} target="_blank" rel="noreferrer" className={styles.link}>
      {value.text}
    </a>
  );
}

LinkMessage.propTypes = {
  value: PropTypes.object.isRequired,
};
