import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getSupportDesk,
  updateSupportDesk as updateSupportDeskRemote,
  disconnectMailFromSupportDesk as disconnectMail,
} from 'services/support-desks';
import { error } from 'state/notifications/actions';
import { forEachError } from '../../../../../../helpers/errorHelper';
import { setSupportDesks } from '../../state/reducer';

export const fetchSupportDesk = createAsyncThunk(
  'support-desk-get',
  async (deskId, { dispatch }) => {
    try {
      const res = await getSupportDesk(deskId);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const updateSupportDesk = createAsyncThunk(
  'support-desk-update',
  async (data, { dispatch, getState }) => {
    try {
      const res = await updateSupportDeskRemote(data.deskId, data.payload);
      const desks = getState().supportDesks.data.map((desk) => {
        if (desk.id === data.deskId) {
          return res.support_desk;
        }
        return desk;
      });
      dispatch(setSupportDesks(desks));
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleDisconnectMail = createAsyncThunk(
  'support-desks-disconnect-mail',
  async (deskId, { dispatch }) => {
    try {
      await disconnectMail(deskId);
      dispatch(fetchSupportDesk(deskId));
      return Promise.resolve();
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
