import ClassNames from 'classnames';
import './RingLoader.scss';

function RingLoader({ variant = 'blue' }) {
  return (
    <div
      className={ClassNames('lds-ring', {
        'lds-ring--green': variant === 'green',
        'lds-ring--purple': variant === 'purple',
        'lds-ring--blue': variant === 'blue',
      })}
    >
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default RingLoader;
