import { useContext, useState } from 'react';
import { FORBIDDEN_FILE_TYPES } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { error as errorAlert } from 'state/notifications/actions';
import attachmentService from 'services/attachments';
import AttachmentContext from './AttachmentContext/AttachmentContext';

export default function useAttachmentUpload(ref) {
  const { setLabel, setFileUrls, setUploading, uploading } = useContext(AttachmentContext);
  const [progress, setProgress] = useState(null);
  const [uploader, setUploader] = useState(null);

  const dispatch = useDispatch();

  const clearState = () => {
    setLabel('');
    setFileUrls('');
  };

  const onUploadProgress = (percent) => {
    setProgress(percent);
  };

  const onUploadError = (status) => {
    console.error('Upload error, ', status);
  };

  const onUploadStart = (file, next) => {
    const fileExtension = file.name.split('.').pop();
    if (FORBIDDEN_FILE_TYPES.includes(`.${fileExtension}`)) {
      dispatch(errorAlert(`File with .${fileExtension} extension is not supported!`));
      clearState();
      if (uploader) uploader.clear();
      return;
    }
    setLabel(file.name);
    setUploading(true);
    next(file);
  };

  const onUploadFinish = () => {
    setUploading(false);
  };

  const handleRemoveAttachment = (e) => {
    if (progress !== 0) {
      const fileInput = ref;
      e.stopPropagation();
      setProgress(null);
      setUploading(false);
      if (uploader && uploading) uploader.myUploader.abortUpload();
      fileInput.current.firstChild.value = null;
      clearState();
    }
  };

  const getSignedUrl = (file, callback) => {
    const params = {
      objectName: file.name,
      contentType: file.type,
    };

    attachmentService
      .presignUrl(params)
      .then((data) => {
        setFileUrls(data.publicUrl);
        callback(data);
      })
      .catch(() => {
        dispatch(errorAlert('Upload error!'));
        clearState();
        if (uploader) uploader.clear();
      });
  };

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  return {
    progress,
    onUploadFinish,
    onUploadStart,
    handleRemoveAttachment,
    getSignedUrl,
    handleLabelChange,
    onUploadError,
    onUploadProgress,
    setUploader,
  };
}
