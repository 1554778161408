import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createSmartProduct,
  getSmartProducts,
  updateSmartProduct as updateSmartProductRemote,
  getSmartProductsConfigurations,
} from 'services/products';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/products/actions';
import { error, success as successAlert } from 'state/notifications/actions';
import { serializeFiltersToString } from 'helpers/filters';
import { forEachError } from '../../../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.smartProducts.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.products.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Product;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchConfigurations = createAsyncThunk(
  'smart-products-get-configurations',
  async (_, { getState }) => {
    if (getState().smartProducts.configurations.categories.length === 0) {
      const res = getSmartProductsConfigurations();
      return res;
    }
    return Promise.reject();
  }
);

export const fetchSmartProducts = createAsyncThunk(
  'smart-products-get',
  async (params = {}, { dispatch, getState }) => {
    dispatch(fetchConfigurations());
    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getSmartProducts(payload)
        .then((json) => Promise.resolve({ ...json, params: { ...stateParams, ...params } }))
        .catch((err) => Promise.reject(err));
    });
  }
);

export const updateSmartProduct = createAsyncThunk(
  'smart-product-update',
  async (data, { dispatch }) => {
    try {
      const res = await updateSmartProductRemote(data.productID, data.payload);
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleCreateSmartProduct = createAsyncThunk(
  'smart-product-create',
  async (payload, { dispatch }) => {
    try {
      const res = await createSmartProduct(payload);
      dispatch(fetchSmartProducts());
      dispatch(successAlert('Product created!'));
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
