import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import SettingsPhoneOutlinedIcon from '@mui/icons-material/SettingsPhoneOutlined';
import PhoneDisabledOutlinedIcon from '@mui/icons-material/PhoneDisabledOutlined';
import { styled } from '@mui/material/styles';
import { selectTwilioEnabled } from '../../state/selectors';
import { setTwilioDeviceDisabled, setupTwilioDevice } from '../../state/actions';
import styles from './AvailabilityIndicator.module.scss';

const StyledMenuItem = styled(MenuItem)({
  width: 256,
  height: 63,
  boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.08)',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: 'rgba(146, 88, 231, 0.08)',
    '& .MuiListItemIcon-root path': {
      fill: '#5D43CF',
    },
  },
});

export default function AvailabilityIndicator() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isTwilioEnabled = useSelector(selectTwilioEnabled);
  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleItemClick = () => {
    if (isTwilioEnabled) dispatch(setTwilioDeviceDisabled());
    else dispatch(setupTwilioDevice());
  };

  return (
    <div className={styles['availability-indicator']}>
      <IconButton
        aria-label="availability indicator"
        onClick={handleOpenMenu}
        size="small"
        style={{ color: isTwilioEnabled ? '#A4D131' : '#F44336' }}
      >
        {isTwilioEnabled ? <SettingsPhoneOutlinedIcon /> : <PhoneDisabledOutlinedIcon />}
      </IconButton>
      <p className={styles.label}>{isTwilioEnabled ? 'Available' : 'Unavailable'}</p>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        MenuListProps={{ disablePadding: true }}
      >
        <StyledMenuItem onClick={handleItemClick}>
          <ListItemIcon sx={{ color: '#999999' }}>
            {isTwilioEnabled ? <PhoneDisabledOutlinedIcon /> : <SettingsPhoneOutlinedIcon />}
          </ListItemIcon>
          <Typography variant="body1">
            {isTwilioEnabled ? 'Disable Calls' : 'Enable Calls'}
          </Typography>
        </StyledMenuItem>
      </Menu>
    </div>
  );
}
