import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SanitizedText from 'ui/SanitizedText';
import { ReactComponent as OpportunityIcon } from './opportunity.svg';
import { useSearch } from '../SearchProvider';
import styles from './SearchResult.module.scss';

export default function OpportunityResult({ opportunity }) {
  const { setVisible } = useSearch();

  return (
    <Link
      to={`/opportunities/${opportunity.id}`}
      onClick={() => setVisible(false)}
      className={styles['search-result']}
    >
      <div className={styles['primary-col']}>
        <OpportunityIcon className={styles.icon} />
        <div>
          <SanitizedText as="p" text={opportunity.getTitle()} />
          <div>
            {opportunity.externalID && (
              <div className={styles['secondary-field']}>
                <strong>External ID: </strong>
                {opportunity.externalID.map((matchedFragment) => (
                  <SanitizedText as="span" text={matchedFragment} />
                ))}
              </div>
            )}
          </div>
          {opportunity.description && (
            <div className={styles['secondary-field']}>
              <strong>Description: </strong>
              {opportunity.description.map((matchedFragment) => (
                <SanitizedText
                  as="span"
                  className={styles['description-fragment']}
                  text={matchedFragment}
                />
              ))}
            </div>
          )}
          {opportunity.customText && (
            <div className="secondary-field">
              <strong>Custom: </strong>
              {opportunity.customText.map((matchedFragment) => (
                <SanitizedText
                  as="span"
                  className={styles['description-fragment']}
                  text={matchedFragment}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles['secondary-col']}>{opportunity.opportunityStage}</div>
    </Link>
  );
}

OpportunityResult.propTypes = {
  opportunity: PropTypes.object.isRequired,
};
