import { createAsyncThunk } from '@reduxjs/toolkit';
import { error } from 'state/notifications/actions';
import { getOrderItem, updateOrderItem as updateOrderItemRemote } from 'services/orders';
import { forEachError } from '../../../../helpers/errorHelper';

export const fetchOrderItem = createAsyncThunk('order-item-get', async (data, { dispatch }) => {
  const { orderId, id } = data;
  try {
    const res = await getOrderItem(orderId, id);
    return res.sales_order_item;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const updateOrderItem = createAsyncThunk(
  'orders-items/update',
  async (data, { dispatch }) => {
    try {
      const res = await updateOrderItemRemote(data.orderID, data.orderItemID, data.payload);
      return res.sales_order_item;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
