export const TWILIO_ENABLE_DEVICE = 'TWILIO_ENABLE_DEVICE';
export const TWILIO_DISABLE_DEVICE = 'TWILIO_DISABLE_DEVICE';
export const TWILIO_SET_TOKEN = 'TWILIO_SET_TOKEN';
export const TWILIO_START_CALL = 'TWILIO_START_CALL';
export const TWILIO_HANG_UP_CALL = 'TWILIO_HANG_UP_CALL';
export const TWILIO_SETUP_INCOMING_CALL = 'TWILIO_SETUP_INCOMING_CALL';
export const TWILIO_REJECT_INCOMING_CALL = 'TWILIO_REJECT_INCOMING_CALL';
export const TWILIO_SET_CALL_INFO = 'TWILIO_SET_CALL_INFO';
export const TWILIO_ACCEPT_CALL = 'TWILIO_ACCEPT_CALL';
export const TWILIO_DROP_CALL = 'TWILIO_DROP_CALL';
export const TWILIO_SET_FORM_VALUES = 'TWILIO_SET_FORM_VALUES';
export const TWILIO_FINISH_LOG_INTERACTION = 'TWILIO_FINISH_LOG_INTERACTION';
export const TWILIO_RESET_STATE = 'TWILIO_RESET_STATE';
export const TWILIO_INIT_LOG_ACTIVITY_FLOW = 'TWILIO_INIT_LOG_ACTIVITY_FLOW';
export const TWILIO_SET_ACCOUNT_CALL_SHOULD_START = 'TWILIO_SET_ACCOUNT_CALL_SHOULD_START';
export const TWILIO_STORE_TWILIO_DEVICE = 'TWILIO_STORE_TWILIO_DEVICE';
export const TWILIO_MUTE_CALL = 'TWILIO_MUTE_CALL';
export const TWILIO_SET_CALLER_INFO = 'TWILIO_SET_CALLER_INFO';
export const TWILIO_SET_ACTIVE_CALL_MODAL_OPEN = 'TWILIO_SET_ACTIVE_CALL_MODAL_OPEN';
export const TWILIO_SET_CONTACT = 'TWILIO_SET_CONTACT';
export const TWILIO_SET_ACCOUNT = 'TWILIO_SET_ACCOUNT';
export const TWILIO_MONITOR_CALL_DURATION = 'TWILIO_MONITOR_CALL_DURATION';
export const TWILIO_CALL_MODAL_MINIMIZED = 'TWILIO_CALL_MODAL_MINIMIZED';
export const TWILIO_TOGGLE_ADD_CONTACT_MODAL = 'TWILIO_TOGGLE_ADD_CONTACT_MODAL';
export const TWILIO_TOGGLE_ADD_EXISTING_CONTACT_MODAL = 'TWILIO_TOGGLE_ADD_EXISTING_CONTACT_MODAL';
export const TWILIO_SET_MODAL_VERTICAL_ALIGNMENT_TO_TOP =
  'TWILIO_SET_MODAL_VERTICAL_ALIGNMENT_TO_TOP';
export const TWILIO_DISPLAY_DIALPAD = 'TWILIO_DISPLAY_DIALPAD';
export const TWILIO_TOGGLE_TRANSFER_CALL = 'TWILIO_TOGGLE_TRANSFER_CALL';
export const TWILIO_SET_PHONE_NUMBER = 'TWILIO_SET_PHONE_NUMBER';
export const TWILIO_RESET_STATE_PARTIALLY = 'TWILIO_RESET_STATE_PARTIALLY';
export const TWILIO_SET_RINGING = 'TWILIO_SET_RINGING';
export const TWILIO_SET_TICKET_ID = 'TWILIO_SET_TICKET_ID';
export const TWILIO_SET_RECORDING = 'TWILIO_SET_RECORDING';
export const TWILIO_SET_PHONE_DIALOG_OPEN = 'TWILIO_SET_PHONE_DIALOG_OPEN';
export const TWILIO_SET_NOTE_SCREEN_OPEN = 'TWILIO_SET_NOTE_SCREEN_OPEN';
export const TWILIO_SET_ACTIVE_TAB = 'TWILIO_SET_ACTIVE_TAB';
export const TWILIO_SET_CONVERSATION_SCREEN_OPEN = 'TWILIO_SET_CONVERSATION_SCREEN_OPEN';
export const TWILIO_MODAL_MINIMIZED_BY_OTHER_ACTION = 'TWILIO_MODAL_MINIMIZED_BY_OTHER_ACTION';
export const TWILIO_DISPLAY_SYSTEM_NOTIFICATION_FOR_CALL =
  'TWILIO_DISPLAY_SYSTEM_NOTIFICATION_FOR_CALL';
export const TWILIO_SET_UPDATED_VOICEMAILS_IDS = 'TWILIO_SET_UPDATED_VOICEMAILS_IDS';
