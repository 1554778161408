import NullContact from '../../../models/NullContact';
import {
  CONTACT_REQUEST_STARTED,
  CONTACT_REQUEST_SUCCESS,
  CONTACT_REPLACE_CONTACT,
  CONTACT_UPDATE_REQUEST_STARTED,
  CONTACT_UPDATE_REQUEST_SUCCESS,
  CONTACT_RESET_STATE,
  STORE_CONTACT_TABS_ORDER,
} from './constants';

const initialState = {
  requestStarted: false,
  data: new NullContact(),
  requestFailed: false,
  requestSuccess: false,
  updateInProgress: false,
  customObjectFields: {},
  customObjects: [],
  editing: null,
  tabsOrder: {},
};

function requestStartedHandler(state) {
  return {
    ...state,
    data: new NullContact(),
    requestStarted: true,
    requestFailed: false,
    requestSuccess: false,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    requestFailed: false,
    requestSuccess: true,
    data: action.data,
    meta: action.meta,
  };
}

function updateRequestStartedHandler(state) {
  return { ...state, updateInProgress: true };
}

function updateRequestSuccessHandler(state) {
  return {
    ...state,
    updateInProgress: false,
    editing: null,
  };
}

function replaceContactHandler(state, action) {
  return { ...state, data: action.data };
}

function resetStateHandler(state) {
  return { ...state, ...initialState };
}

function storeContactTabsOrderHandler(state, action) {
  return { ...state, tabsOrder: action.data };
}

const ACTION_HANDLERS = {
  [CONTACT_REQUEST_STARTED]: requestStartedHandler,
  [CONTACT_REQUEST_SUCCESS]: requestSuccessHandler,
  [CONTACT_REPLACE_CONTACT]: replaceContactHandler,
  [CONTACT_UPDATE_REQUEST_STARTED]: updateRequestStartedHandler,
  [CONTACT_UPDATE_REQUEST_SUCCESS]: updateRequestSuccessHandler,
  [CONTACT_RESET_STATE]: resetStateHandler,
  [STORE_CONTACT_TABS_ORDER]: storeContactTabsOrderHandler,
};

export default function contactReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
