import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Popper, ClickAwayListener } from '@mui/material';
import { selectUser } from 'state/user/selectors';
import UserInfo from './UserInfo';
import UserDropdown from './UserDropdown';
import styles from './UserWidget.module.scss';

export default function UserWidget() {
  const user = useSelector(selectUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const hideDropdown = () => setAnchorEl(null);

  return (
    <ClickAwayListener onClickAway={hideDropdown}>
      <div>
        <UserInfo handleClick={handleClick} open={open} user={user} />
        <div>
          <Popper
            id="popup-popper"
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            className={styles.popper}
          >
            <div className={styles.paper} data-testid="user-popup">
              <UserDropdown onHide={hideDropdown} />
            </div>
          </Popper>
        </div>
      </div>
    </ClickAwayListener>
  );
}
