import { createSlice } from '@reduxjs/toolkit';
import { fetchQuote, updateQuote } from './actions';

const initialState = {
  data: null,
  fetching: true,
};

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    resetQuoteState: () => ({ ...initialState }),
    replaceQuote: (state, { payload }) => ({
      ...state,
      data: payload,
      fetching: false,
    }),
  },
  extraReducers: {
    [fetchQuote.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload,
      fetching: false,
    }),
    [fetchQuote.rejected]: (state) => ({ ...state, fetching: false }),
    [updateQuote.fulfilled]: (state, { payload }) => ({ ...state, data: payload }),
  },
});

export const { resetQuoteState, replaceQuote } = quoteSlice.actions;

export default quoteSlice.reducer;
