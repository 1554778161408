import { ReactComponent as SpiritoIcon } from '../../../../images/icons/v2/spirito-icon-orange.svg';
import BouncingDots from '../BouncingDots';
import styles from './PageLoader.module.scss';

function PageLoader() {
  return (
    <div className={styles['new-page-loader']}>
      <SpiritoIcon className={styles['spirito-icon']} />
      <br />
      <br />
      <BouncingDots sm grey />
    </div>
  );
}

export default PageLoader;
