import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dialog from '@mui/material/Dialog';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import styles from './Modal.module.scss';

function Modal({ className = '', ...props }) {
  return (
    <Dialog {...props} className={classNames([styles.modal, className])}>
      {props.children}
    </Dialog>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
