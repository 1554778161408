import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/opportunities/actions';
import { getPipelines } from 'state/pipelines/actions';
import { serializeFiltersToString } from 'helpers/filters';
import { error as errorAlert } from 'state/notifications/actions';
import { setShowConfetti } from 'state/opportunity-celebration/reducers';
import {
  getOpportunities as getOpportunitiesRemote,
  createOpportunity as createOpportunityRemote,
  updateOpportunity as updateOpportunityRemote,
  getOpportunitiesCount as getOpportunitiesCountRemote,
} from 'services/opportunities';
import { parseMetaFromResponse } from 'helpers/meta';
import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';
import {
  REQUEST_STARTED,
  REQUEST_SUCCESS,
  REQUEST_FAILED,
  REPLACE_OPPORTUNITY,
  SET_PARAMS,
  RESET_DATA,
  ADD_OPPORTUNITY_TO_LIST,
  STORE_OPPORTUNITIES,
  STORE_NEW_DEALS,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  SET_RECORDS_COUNT,
} from './constants';

export function requestStarted() {
  return { type: REQUEST_STARTED };
}

export function requestSuccess(data, meta) {
  return {
    type: REQUEST_SUCCESS,
    data,
    meta: parseMetaFromResponse(meta),
  };
}

export function requestFailed(json) {
  return {
    type: REQUEST_FAILED,
    errors: json.errors,
  };
}

export function createInProgress() {
  return {
    type: CREATE_IN_PROGRESS,
  };
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  };
}

export function createFailure() {
  return {
    type: CREATE_FAILURE,
  };
}

export function replaceOpportunity(opportunity) {
  return {
    type: REPLACE_OPPORTUNITY,
    opportunity,
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function resetData() {
  return {
    type: RESET_DATA,
  };
}

export function storeNewDeals(opportunities) {
  return {
    type: STORE_NEW_DEALS,
    opportunities,
    // opportunities: opportunities.map((o) => new Opportunity(o)),
  };
}

export function storeOpportunities(opportunities) {
  return {
    type: STORE_OPPORTUNITIES,
    opportunities,
    // opportunities: opportunities.map((o) => new Opportunity(o)),
  };
}

export function addOpportunityToList(opportunity) {
  return {
    type: ADD_OPPORTUNITY_TO_LIST,
    opportunity,
    // opportunity: new Opportunity(opportunity),
  };
}

export function setOpportunitiesCount(recordsCount) {
  return {
    type: SET_RECORDS_COUNT,
    recordsCount,
  };
}

function getParamsFromState(state) {
  const params = state.opportunities.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.opportunities.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Opportunity;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function getOpportunities(params = {}) {
  return async (dispatch, getState) => {
    dispatch(requestStarted());

    dispatch(getPipelines());
    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getOpportunitiesRemote(payload)
        .then((json) => {
          dispatch(requestSuccess(json.opportunities, json.meta));
          dispatch(setParams(payload));
          return Promise.resolve(json);
        })
        .catch((err) => Promise.reject(err));
    });
  };
}

export function getOpportunitiesCount(params = {}) {
  return async (_, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };

    return getOpportunitiesCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  };
}

export function createOpportunity(payload) {
  return async (dispatch) => {
    dispatch(createInProgress());

    try {
      const json = await createOpportunityRemote(payload);
      dispatch(createSuccess());
      return json.opportunity;
    } catch (err) {
      dispatch(createFailure());
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  };
}

export function updateOpportunity(opportunityId, payload = {}) {
  return (dispatch) =>
    updateOpportunityRemote(opportunityId, serializeForm(payload))
      .then((json) => {
        // const opportunity = new Opportunity(json.opportunity);
        dispatch(replaceOpportunity(json.opportunity));
        dispatch(
          setShowConfetti({ requestPayload: serializeForm(payload), response: json.opportunity })
        );
        return json;
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}
