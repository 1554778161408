import { useDispatch, useSelector } from 'react-redux';
import BlockIcon from '@mui/icons-material/Block';
import IconButtonWithLabel from 'lib/IconButtonWithLabel';
import { selectIsRecordingEnabled } from '../state/selectors';
import { setRecording } from '../state/actions';
import styles from './DisableRecordingButton.module.scss';

export default function DisableRecordingButton() {
  const dispatch = useDispatch();
  const isRecordingEnabled = useSelector(selectIsRecordingEnabled);

  const disableCallRecording = () => {
    if (isRecordingEnabled) dispatch(setRecording(false));
  };

  const getLabel = () => (isRecordingEnabled ? 'Disable Recording' : 'Recording Disabled');

  return (
    <div className={styles.container}>
      <IconButtonWithLabel
        icon={<BlockIcon />}
        color="purple"
        handleOnClick={disableCallRecording}
        label={getLabel()}
        iconSize="large"
        disabled={!isRecordingEnabled}
      />
    </div>
  );
}
