import { useState } from 'react';
import { getAnnouncement, clearAnnouncement } from 'services/announcement';

const useAnnouncement = () => {
  const [announcement, setAnnouncement] = useState(null);

  const fetchAnnouncement = async () => {
    try {
      const response = await getAnnouncement();
      setAnnouncement(response?.announcement?.content);
    } catch (error) {
      console.error(error);
    }
  };

  const dismissAnnouncement = async () => {
    try {
      const response = await clearAnnouncement();
      setAnnouncement(response.announcement);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    announcement,
    fetchAnnouncement,
    dismissAnnouncement,
  };
};

export default useAnnouncement;
