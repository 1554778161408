import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { selectUser } from 'app/state/user/selectors';
import { ReactComponent as CustomLogo } from 'images/ceramic-pro.svg';
import useAnnouncement from 'hooks/useAnnouncement';
import { ReactComponent as SpiroLogo } from './icons/spiro-logo-white.svg';
import TopBarActions from './TopBarActions';
import styles from './TopBar.module.scss';

export default function TopBar() {
  const user = useSelector(selectUser);
  const { announcement, fetchAnnouncement, dismissAnnouncement } = useAnnouncement();

  useEffect(() => {
    setTimeout(() => {
      fetchAnnouncement();
    }, 2000);
  }, []);

  if (!user.id) return null;

  return (
    <header
      className={`${styles['top-bar']} ${
        user.logoType !== 'default' ? styles['black-background'] : ''
      }`}
    >
      <Link to="/">
        {user.logoType === 'default' ? (
          <SpiroLogo data-cy="navbar-spiro-logo" />
        ) : (
          <CustomLogo className={styles['custom-logo']} data-cy="navbar-spiro-logo" />
        )}
      </Link>
      <Slide direction="right" in={announcement} appear={false} timeout={500} unmountOnExit>
        <Alert
          onClose={dismissAnnouncement}
          icon={false}
          className={styles.alert}
          classes={{ action: styles.action, message: styles.message }}
        >
          <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(announcement) }} />
        </Alert>
      </Slide>
      <TopBarActions />
    </header>
  );
}
