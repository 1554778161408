import PropTypes from 'prop-types';
import classNames from 'classnames';
import Divider from '@mui/material/Divider';
import DialogTitle from '@mui/material/DialogTitle';

function ModalHeader({ children, divider = false, className = '', ...props }) {
  return (
    <DialogTitle {...props} className={classNames([className])}>
      {children}
      {divider && <Divider sx={{ marginBottom: '10px', marginTop: '15px' }} />}
    </DialogTitle>
  );
}

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalHeader;
