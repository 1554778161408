import { createSelector } from '@reduxjs/toolkit';
import { filterGoogleToken, filterMicrosoftToken, filterSalesforceToken } from 'helpers/tokens';

export const selectTokens = (state) => state.tokens.items;

export const userHasSalesforce = createSelector(
  [selectTokens],
  (tokens) => !!filterSalesforceToken(tokens)
);

export const selectGoogleToken = createSelector([selectTokens], (tokens) =>
  filterGoogleToken(tokens)
);

export const selectMicrosoftToken = createSelector([selectTokens], (tokens) =>
  filterMicrosoftToken(tokens)
);

export const userHasGoogle = createSelector(
  [selectTokens],
  (tokens) => !!filterGoogleToken(tokens)
);

export const selectIsDeleting = (state) => state.tokens.isDeleting;
