import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cs from 'classnames';
import SpiroAudioPlayer from 'components/SpiroAudioPlayer';
import { getVoicemail } from 'services/spiro-phone';
import { error } from 'state/notifications/actions';
import { forEachError } from '../../../../helpers/errorHelper';
import styles from './VoicemailCard.module.scss';

export default function VoicemailDetailsAndTranscriptions({ voicemail }) {
  const [transcriptions, setTranscriptions] = useState([]);
  const [transcriptionLoading, setTranscriptionLoading] = useState(true);
  const dispatch = useDispatch();

  const fetchVoicemailTranscription = async () => {
    try {
      const res = await getVoicemail(voicemail.id);
      const { transcription } = res.missed_call_voicemail.phone_call_log;
      setTranscriptions(transcription.transcriptions || []);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
    } finally {
      setTranscriptionLoading(false);
    }
  };

  useEffect(() => {
    fetchVoicemailTranscription();
  }, []);

  return (
    <div className={styles['extended-section']}>
      <SpiroAudioPlayer url={voicemail.url} />
      <div className={styles['transcription-container']}>
        <p className={styles['transcription-title']}>Transcription</p>
        <p
          className={cs(styles.transcription, {
            [styles['transcription-full-height']]: !transcriptionLoading && transcriptions.length,
          })}
        >
          {transcriptionLoading
            ? 'Loading...'
            : transcriptions[0]?.text || 'No transcription for this voicemail.'}
        </p>
      </div>
    </div>
  );
}

VoicemailDetailsAndTranscriptions.propTypes = {
  voicemail: PropTypes.object.isRequired,
};
