import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import HistoryIcon from '@mui/icons-material/History';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import { Box, Fade } from '@mui/material';
import styles from './QuickActions.module.scss';

const actions = [
  'Contact/Company Details',
  'Interactions History',
  'Order Information',
  'Opportunities and Deals',
  'Activity Tracking',
  'Reminders',
];

const icons = {
  'Contact/Company Details': <SearchIcon className={styles.icon} />,
  'Interactions History': <HistoryIcon className={styles.icon} />,
  'Order Information': <AttachMoneyIcon className={styles.icon} />,
  'Opportunities and Deals': <HandshakeOutlinedIcon className={styles.icon} />,
  'Activity Tracking': <TrendingUpOutlinedIcon className={styles.icon} />,
  Reminders: <AccessAlarmsOutlinedIcon className={styles.icon} />,
};

export default function QuickActions({ isVisible, defaultQuestions, onQuickActionClick }) {
  const handleQuickActionClick = (action) => () => {
    onQuickActionClick(defaultQuestions[action]);
  };

  return (
    <Fade in={isVisible} timeout={300}>
      <Box className={styles['grid-container']}>
        {actions.map((action, index) => (
          <Box
            className={`${styles['grid-item']} ${styles[`action${index + 1}`]}`}
            onClick={handleQuickActionClick(action)}
            key={action}
          >
            {icons[action]}
            <span>{action}</span>
          </Box>
        ))}
      </Box>
    </Fade>
  );
}

QuickActions.defaultProps = {
  defaultQuestions: {},
};

QuickActions.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  defaultQuestions: PropTypes.object,
  onQuickActionClick: PropTypes.func.isRequired,
};
