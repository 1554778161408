import { serializeFiltersToString } from 'helpers/filters';
import { parseMetaFromResponse } from 'helpers/meta';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/interactions/actions';
import { error } from 'state/notifications/actions';
import {
  FETCH_INTERACTIONS_STARTED,
  STORE_INTERACTIONS,
  SET_PARAMS,
  SET_RECORDS_COUNT,
} from './constants';
import http from '../../../core/engine-service';
import { forEachError } from '../../../../helpers/errorHelper';

export function storeInteractions(interactions, meta) {
  return {
    type: STORE_INTERACTIONS,
    data: interactions,
    meta: parseMetaFromResponse(meta),
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function fetchInteractionsStarted() {
  return {
    type: FETCH_INTERACTIONS_STARTED,
  };
}

export function setInteractionsCount(recordsCount) {
  return {
    type: SET_RECORDS_COUNT,
    recordsCount,
  };
}

function getParamsFromState(state) {
  const params = state.interactions.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.interactions.currentFilters;

  return {
    ...defaultParams,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function getInteractions(params = {}) {
  return async (dispatch, getState) => {
    dispatch(fetchInteractionsStarted());
    dispatch(fetchFilterProperties());
    dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return http
        .get('/interactions', payload)
        .then((json) => {
          dispatch(storeInteractions(json.interactions, json.meta));
          dispatch(setParams(payload));
          return Promise.resolve(json);
        })
        .catch((err) => Promise.reject(err));
    });
  };
}

export function getInteractionsCount(params = {}) {
  return async (_, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };
    return http
      .get('/interactions/count', payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  };
}

export function deleteInteraction(interactionID, successFunction) {
  return (dispatch) => {
    const handleSuccess = () => {
      dispatch(getInteractions());
      if (successFunction) successFunction();
    };
    const handleError = (err) => forEachError(err.data, (e) => dispatch(error(e)));

    return http.delete(`/interactions/${interactionID}`).then(handleSuccess).catch(handleError);
  };
}
