import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import IconButton from '@mui/material/IconButton';
import CallReceivedOutlinedIcon from '@mui/icons-material/CallReceivedOutlined';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import CallMissedOutlinedIcon from '@mui/icons-material/CallMissedOutlined';
import AddIcon from '@mui/icons-material/Add';
import useCall from 'app/lib/CallButton/useCall';
import RecentsAvatar from './RecentsAvatar';
import RecentDetails from './RecentDetails';
import DateHelper from '../../../../helpers/DateHelper';
import {
  minimizeCallModal,
  resetTwilioState,
  setModalMinimizedByOtherAction,
  setPhoneDialogOpen,
  setPhoneNumber,
  toggleAddContactModal,
} from '../state/actions';
import styles from './RecentsCard.module.scss';

const displayIconBasedOnCallType = (callType, callStatus) => {
  if (callStatus === 'no-answer' && callType === 'inbound_call')
    return <CallMissedOutlinedIcon fontSize="inherit" />;
  return {
    inbound_call: <CallReceivedOutlinedIcon fontSize="inherit" />,
    outbound_call: <CallMadeOutlinedIcon fontSize="inherit" />,
  }[callType];
};

const getResourceFromCall = (call) => {
  if (call.account) return { type: 'Account', value: call.account };
  if (call.contact) return { type: 'Contact', value: call.contact };
  return {};
};

const getPhoneNumberFromCall = (call) => {
  if (call.kind === 'inbound_call') return call.from_number;
  return call.to_number;
};

const RecentsCard = forwardRef(({ recentCall }, ref) => {
  const startCall = useCall();
  const dispatch = useDispatch();

  function initiateCall() {
    const phoneNumber = getPhoneNumberFromCall(recentCall);
    const resource = getResourceFromCall(recentCall);

    dispatch(resetTwilioState());

    startCall(phoneNumber, resource);
  }

  const handleMinimizeCallModal = () => {
    dispatch(minimizeCallModal(true));
    dispatch(setPhoneDialogOpen(false));
    dispatch(setModalMinimizedByOtherAction(true));
  };

  const openNewContactModal = () => {
    const phoneNumber =
      recentCall.kind === 'inbound_call' ? recentCall.from_number : recentCall.to_number;
    dispatch(setPhoneNumber(phoneNumber));
    dispatch(toggleAddContactModal(true));
    handleMinimizeCallModal();
  };

  return (
    <div className={styles.container} ref={ref}>
      <RecentsAvatar recent={recentCall} />
      <div className={styles.info}>
        <RecentDetails recentCall={recentCall} />
        <div
          className={
            recentCall.status === 'no-answer' && recentCall.kind === 'inbound_call'
              ? styles['details-red']
              : styles['details-regular']
          }
        >
          {displayIconBasedOnCallType(recentCall.kind, recentCall.status)}
          {recentCall.group_call && (
            <GroupsOutlinedIcon fontSize="inherit" data-cy="recents-group-call-icon" />
          )}
          <span>{DateHelper.formatDateForPhoneApp(recentCall.interacted_at)}</span>
        </div>
      </div>
      <div className={styles.actions}>
        {!recentCall.contact && !recentCall.account && (
          <IconButton
            className={styles.icon}
            onClick={openNewContactModal}
            sx={{ marginRight: '8px' }}
          >
            <AddIcon className={styles['add-icon']} />
          </IconButton>
        )}
        <IconButton className={styles.icon} onClick={initiateCall}>
          <LocalPhoneOutlinedIcon className={styles['phone-icon']} />
        </IconButton>
      </div>
    </div>
  );
});

RecentsCard.propTypes = {
  recentCall: PropTypes.object.isRequired,
};

export default RecentsCard;
