import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useSearch } from '../SearchProvider';
import styles from './SearchInput.module.scss';

export default function SearchInput() {
  const { searchTerm, debouncedSearchTerm, setSearchTerm } = useSearch();
  const { t } = useTranslation();

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClear = () => {
    setSearchTerm('');
  };

  return (
    <div className={styles['search-input-container']}>
      <SearchIcon className={styles['search-icon']} />
      <input
        autoFocus
        type="text"
        value={searchTerm}
        className={styles['search-input']}
        placeholder={t('localization.search.title')}
        onChange={handleChange}
      />
      {debouncedSearchTerm && (
        <ClearIcon className={styles['clear-search-button']} onClick={handleClear} />
      )}
    </div>
  );
}
