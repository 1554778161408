import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { error as errorAlert } from 'state/notifications/actions';
import { createUserSession } from 'state/session/actions';
import { loginWithConnectedUser } from 'services/connected-users';
import { parseTokenResponseData } from 'helpers/session';
import { selectConnectedUsers } from 'state/user/selectors';
import styles from './ConnectedUsers.module.scss';

export default function ConnectedUsers() {
  const dispatch = useDispatch();
  const connectedUsers = useSelector(selectConnectedUsers);
  const { t } = useTranslation();

  if (!connectedUsers.length) return null;

  function handleClick(user) {
    loginWithConnectedUser(user.id)
      .then((data) => {
        dispatch(createUserSession(parseTokenResponseData(data)));
        window.location.replace('/');
      })
      .catch((err) => dispatch(errorAlert(err.data.message)));
  }

  return (
    <div className={styles.container}>
      {connectedUsers.map((user) => (
        <MenuItem key={user.id} onClick={() => handleClick(user)} className={styles['menu-item']}>
          <PeopleOutlinedIcon />
          <span data-cy="navbar-connected-users-dropdown-item">
            {t('localization.navigation.swithch_to_account', {
              account_name: user.organization,
              interpolation: { prefix: '%{', suffix: '}' },
            })}
          </span>
        </MenuItem>
      ))}
    </div>
  );
}
