import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import styles from './ChatInput.module.scss';

export default function ChatInput({ handleSend, isLoading }) {
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {
    if (message) {
      handleSend(message);
      setMessage('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleOnChange = (e) => setMessage(e.target.value);

  return (
    <div className={styles['grid-container']}>
      <TextField
        autoFocus
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            padding: '16px',
            lineHeight: '24px',
            color: '#000000',
            '& fieldset': {
              borderColor: '#E6E6E6',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#AEA1E7',
            },
          },
          '& .MuiOutlinedInput-input::placeholder': {
            color: '#999999',
          },
        }}
        multiline
        disabled={isLoading}
        className={styles['grid-item']}
        maxRows={4}
        value={message}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        variant="outlined"
        placeholder="Type your question"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="send message"
                onClick={handleSendMessage}
                edge="end"
                className={styles['icon-btn']}
                style={{ visibility: message.length > 0 ? 'visible' : 'hidden' }}
              >
                <SendIcon className={styles.icon} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

ChatInput.propTypes = {
  handleSend: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
