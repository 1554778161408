import {
  STORE_CUSTOM_FIELDS,
  STORE_CUSTOM_FIELD,
  FETCHING_CUSTOM_FIELDS,
  FETCHING_CUSTOM_FIELDS_FAILED,
  SELECT_CUSTOM_FIELDS_RESOURCE,
  REPLACE_CUSTOM_ENTITY,
  REMOVE_CUSTOM_FIELD,
  REMOVE_CUSTOM_ENTITY,
  STORE_NEW_CUSTOM_FIELDS_RESOURCE,
} from './contants';

const initialState = {
  data: {},
  reduced: {},
  selectedResource: 'Opportunity',
  selectedEntity: undefined,
  fetching: false,
  fetched: false,
  failed: false,
};

function getOrder(customField) {
  const matchedOrder = {
    Opportunity: 0,
    Contact: 1,
    Account: 2,
    Interaction: 3,
    Reminder: 4,
    User: 5,
  }[customField.resource];

  if (matchedOrder === 0) return matchedOrder;
  return matchedOrder || customField.order;
}

function getParentId(customField, customFields) {
  if (customField.resource_for) {
    return customFields[customField.resource_for].id.toString();
  }
  if (customField.custom_field_id) {
    return customField.custom_field_id.toString();
  }
  return null;
}

function normalizeCustomFieldsResponse(customFields) {
  // to be deleted once Sales Invoices are incorporated
  const filteredObject = customFields;
  delete filteredObject.SalesInvoice;
  delete filteredObject.SalesInvoiceItem;

  return Object.keys(filteredObject)
    .map((key) => customFields[key])
    .reduce((reducedObject, field) => {
      const newObj = reducedObject;

      newObj[field.id] = field;
      newObj[field.id].order = getOrder(field);
      newObj[field.id].parentId = getParentId(field, customFields);

      return reducedObject;
    }, {});
}

function storeCustomFields(state, action) {
  return {
    ...state,
    data: action.data,
    reduced: normalizeCustomFieldsResponse(action.data),
    fetching: false,
    fetched: true,
  };
}

function storeCustomField(state, action) {
  return {
    ...state,
    [action.resource.resource.toLowerCase()]: {
      ...state[action.resource.resource.toLowerCase()],
      fields: action.resource.fields,
    },
    data: {
      ...state.data,
      [action.resource.resource]: action.resource,
    },
  };
}

function fetchingCustomFields(state) {
  return {
    ...state,
    fetching: true,
    fetched: false,
  };
}

function fetchingCustomFieldsFailded(state) {
  return {
    ...state,
    fetching: false,
    failed: true,
  };
}

function selectCustomFieldsResource(state, action) {
  return {
    ...state,
    selectedResource: action.resource,
    selectedEntity: state.data[action.resource],
  };
}

function replaceCustomEntityHandler(state, action) {
  const newState = { ...state };
  const resource = Number.isInteger(action.resourceId)
    ? state.data.find((value) => value.id === action.resourceId)
    : action.oldResourceNameOrId;

  delete newState.data[resource.resource];
  newState.data[action.resource.resource] = {
    ...resource,
    ...action.resource,
  };
  return newState;
}

function removeCustomFieldHandler(state, action) {
  const newState = { ...state };
  delete newState.data[state.selectedResource].fields[action.name];
  return newState;
}

function removeCustomEntityHandler(state, action) {
  const newState = {
    ...state,
    selectedResource: 'Opportunity',
    selectedEntity: state.data.Opportunity,
  };
  delete newState.data[action.resource];
  return newState;
}

function storeNewCustomFieldsResourceHandler(state, action) {
  return {
    ...state,
    data: {
      ...state.data,
      [action.resource.resource]: { ...action.resource },
    },
  };
}

const ACTION_HANDLERS = {
  [STORE_CUSTOM_FIELDS]: storeCustomFields,
  [STORE_CUSTOM_FIELD]: storeCustomField,
  [FETCHING_CUSTOM_FIELDS]: fetchingCustomFields,
  [FETCHING_CUSTOM_FIELDS_FAILED]: fetchingCustomFieldsFailded,
  [SELECT_CUSTOM_FIELDS_RESOURCE]: selectCustomFieldsResource,
  [REPLACE_CUSTOM_ENTITY]: replaceCustomEntityHandler,
  [REMOVE_CUSTOM_FIELD]: removeCustomFieldHandler,
  [REMOVE_CUSTOM_ENTITY]: removeCustomEntityHandler,
  [STORE_NEW_CUSTOM_FIELDS_RESOURCE]: storeNewCustomFieldsResourceHandler,
};

export default function customFieldsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
