import {
  STORE_SALES_STAGES,
  STORE_ALL_SALES_STAGES,
  STORE_SALES_STAGE,
  CREATE_SALES_STAGE,
  DELETE_SALES_STAGE,
  SET_CLEAN,
  SET_DIRTY,
  CREATE,
  UPDATE,
  REQUEST_START,
  REQUEST_SUCCESS,
  REQUEST_FAIL,
  RESET,
} from './constants';
import {
  create as createOpportunityStageRemote,
  update as updateOpportunityStageRemote,
  batchUpdate as updateOpportunityStagesRemote,
} from '../../services/opportunity-stages';
import { parse, serialize } from '../../helpers/opportunity-stages';

export function requestStart() {
  return {
    type: REQUEST_START,
  };
}

export function requestSuccess() {
  return {
    type: REQUEST_SUCCESS,
  };
}

export function requestFail() {
  return {
    type: REQUEST_FAIL,
  };
}

export function storeSalesStages(data) {
  return {
    type: STORE_SALES_STAGES,
    data,
  };
}

export function storeAllSalesStages(data) {
  return {
    type: STORE_ALL_SALES_STAGES,
    data,
  };
}

export function storeSalesStage(data, key) {
  return {
    type: STORE_SALES_STAGE,
    data,
    key,
  };
}

export function createSalesStage() {
  return {
    type: CREATE_SALES_STAGE,
  };
}

export function deleteSalesStage(key) {
  return {
    type: DELETE_SALES_STAGE,
    key,
  };
}

export function setDirty() {
  return {
    type: SET_DIRTY,
  };
}

export function setClean() {
  return {
    type: SET_CLEAN,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}

export function createOpportunityStage(data, params) {
  return (dispatch) => {
    dispatch(requestStart());
    return createOpportunityStageRemote(data, params).then((r) => {
      dispatch({
        type: CREATE,
        data: parse(r),
      });
      dispatch(requestSuccess());
    });
  };
}

export function updateOpportunityStage(id, data, params) {
  return (dispatch) => {
    dispatch(requestStart());
    return updateOpportunityStageRemote(id, serialize(data), params).then((r) => {
      dispatch({
        type: UPDATE,
        id,
        data: parse(r),
      });
      dispatch(requestSuccess());
    });
  };
}

export function updateOpportunityStages(data, params) {
  return (dispatch) => {
    dispatch(requestStart());
    return updateOpportunityStagesRemote(data, params).then(() => {
      dispatch(requestSuccess());
    });
  };
}
